<template>
  <b-modal id="add-account-modal" size="md" centered hide-header hide-footer>
    <b-row>
      <b-col>
        <img
          src="../../../../assets/images/close.png"
          alt="close"
          class="close-icon"
          @click.stop="closeModal"
        />
      </b-col>
    </b-row>
    <div style="padding: 0rem 1rem 1.5rem">
      <b-row class="mb-2">
        <b-col>
          <div class="title">
            <span v-if="mode === 'add'">Add</span>
            <span v-if="mode === 'edit'">Edit</span> Account
          </div>
        </b-col>
      </b-row>
      <validation-observer ref="observer">
        <b-alert :show="error && error.length ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <b-row>
          <b-col md="12">
            <validation-provider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <base-input
                label="Account Name"
                placeholder="Enter name"
                type="text"
                field="name"
                :value="name"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              >
              </base-input>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <validation-provider
              name="access key id"
              rules="required"
              v-slot="{ errors }"
            >
              <base-input
                label="API Key"
                placeholder="Enter key"
                type="password"
                field="access_key_id"
                :value="access_key_id"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              >
              </base-input>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <validation-provider
              name="secret access key"
              rules="required"
              v-slot="{ errors }"
            >
              <base-input
                label="API Secret"
                placeholder="Enter secret"
                type="password"
                field="secret_access_key"
                :value="secret_access_key"
                @change-value="setValue"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
              >
              </base-input>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row class="mt-2 align-items-center">
        <b-col md="6">
          <!-- <div
            class="delete-link"
            v-if="mode === 'edit'"
            @click.stop="DeleteAccount"
          >
            Delete Account
          </div> -->
          <base-button
            v-if="mode === 'edit'"
            style_variant="teritary"
            cus_style="color: #ce1a36;padding: 0px;width: auto;"
            @onClick="DeleteAccount"
            :disabled="loading"
          >
            Delete Account
          </base-button>
        </b-col>
        <b-col md="6" class="d-flex align-items-center justify-content-end">
          <base-button
            style_variant="teritary"
            cus_style="margin-right:1rem"
            @onClick="closeModal"
            :disabled="loading"
          >
            Cancel
          </base-button>
          <base-button
            style_variant="primary"
            @onClick="submitForm"
            :disabled="loading"
          >
            Save
          </base-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full";
import CloudConnectService from "../../../../services/cloud.connect.service";

export default {
  props: ["mode", "edit_data", "updatedTime"],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      name: "",
      access_key_id: "",
      secret_access_key: "",
      loading: false,
      error: null,
      data: null,
    };
  },
  methods: {
    setValue(val, field) {
      this[field] = val;
    },
    closeModal() {
      this.$bvModal.hide("add-account-modal");
      this.reset();
    },
    reset() {
      this.name = "";
      this.access_key_id = "";
      this.secret_access_key = "";
      this.loading = false;
      this.error = null;
      this.data = null;
    },
    CreateAccount() {
      this.loading = true;
      this.error = null;
      const payload = {
        name: this.name,
        access_key_id: this.access_key_id,
        secret_access_key: this.secret_access_key,
      };

      CloudConnectService.CreateAccount("AWS", payload)
        .then(({ data }) => {
          this.data = data;
          this.closeModal();
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error in creating account.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    UpdateAccount() {
      this.loading = true;
      this.error = null;
      const payload = {
          name: this.name,
          access_key_id: this.access_key_id,
          secret_access_key: this.secret_access_key,
        },
        id = this.edit_data._id;

      CloudConnectService.UpdateAccount(id, payload)
        .then(({ data }) => {
          this.data = data;
          this.closeModal();
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error in updating account.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    DeleteAccount() {
      this.loading = true;
      this.error = null;
      const id = this.edit_data._id;

      CloudConnectService.DeleteAccount(id)
        .then(({ data }) => {
          this.data = data;
          this.closeModal();
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error in deleting account.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submitForm() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;

      if (this.mode === "add") this.CreateAccount();
      if (this.mode === "edit") this.UpdateAccount();
    },
    set(data) {
      if (data && data.name) {
        this.name = data.name;
      }
      if (data && data.client_creds && data.client_creds.access_key_id) {
        this.access_key_id = data.client_creds.access_key_id;
      }
      if (data && data.client_creds && data.client_creds.secret_access_key) {
        this.secret_access_key = data.client_creds.secret_access_key;
      }
    },
  },
  watch: {
    updatedTime() {
      if (this.mode === "edit") this.set(this.edit_data);
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 3rem 1rem;
}
.close-icon {
  float: right;
  cursor: pointer;
}
.title {
  font-size: 22px;

  color: #112849;
  margin-bottom: 1rem;
}
.delete-link {
  color: #ce1a36;
  font-size: 16px;

  text-decoration: underline;
  cursor: pointer;
}
</style>
