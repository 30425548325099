<template>
  <div class="w-100">
    <PageHeader
        parent-label="Admin Settings"
        :title="$route.params.id==='new'? 'Invite' : getFullname()"
        backAllowed="true"
    />


    <div class="margin-under-header">
      <common-loading v-if="loading"/>

      <div v-if="!loading" class="d-flex align-items-end">
        <div class="fs-4 fw-bold mx-5 pe-5 mt-0 flex-grow-1">User Settings</div>
      </div>
      <!--  Cards -->
      <b-card
          v-if="!loading"
          class="ms-5 mt-3 p-2 mb-5"
          :style="'width: 94%; border: solid 1px #EBE9E1; border-radius: 0;'"
      >
        <div class="row">
          <div class="col-10">


            <div class="upper-form w-50 mt-5">
              <div class="row">
                <div class="col-6">
                  <b-form-group
                      v-if="$route.params.id!=='new'"
                      label="First Name"
                      label-for="input-1"
                      class="mb-3"
                  >
                    <b-form-input class="form-input-font" id="user-firstname" v-model="user.firstName"
                                  trim></b-form-input>
                  </b-form-group>
                  <b-form-group
                      label="Email Address"
                      label-for="input-1"
                      valid-feedback="Thank you!"
                      :invalid-feedback="'asd'"
                      class="mb-3"
                  >
                    <b-form-input class="form-input-font" id="input-1" v-model="user.email" trim></b-form-input>
                  </b-form-group>

                </div>
                <div class="col-6">
                  <b-form-group
                      v-if="$route.params.id!=='new'"

                      label="Last Name"
                      label-for="input-1"
                      valid-feedback="Thank you!"
                      :invalid-feedback="'asd'"
                      class="mb-3"
                  >
                    <b-form-input class="form-input-font" id="user-lastname" v-model="user.lastName"
                                  trim></b-form-input>
                  </b-form-group>


                  <base-select
                      label="Role"
                      :options="roleOptions"
                      :value="roleSelected"
                      @change-value="setRoleValue"
                      cus_style="background-color:transparent;font-weight: 500 !important;font-size: 16px !important;"
                      class="form-input-font"
                  ></base-select>
                </div>
              </div>


            </div>

          </div>
          <div class="col">
            <button class="user-detail-button save-button" v-on:click="updateUser()">
              {{ $route.params.id !== 'new' ? 'Save Changes' : 'Send Invitation' }}
            </button>
          </div>
        </div>


        <hr
            v-if="$route.params.id!=='new'"

            style="background: #ECEAE2"/>
        <div class="row">
          <div class="col-10">
            <div class="fs-4 fw-bold mt-5 mb-2">Access & Permissions</div>
          </div>
          <div class="col-2">
            <span class="p-input-icon-left mt-5">
              <i class="pi pi-search"/>
              <InputText
                  class="w-100"
                  type="text"
                  v-model="searchInput"
                  placeholder="Search..."
              />
            </span>
          </div>
        </div>

        <section v-for="module of Object.keys(permissions)"
                 :class="{'limited-height': openSections.includes(module), 'd-none': checkSearch(module)}"
                 style="overflow: hidden; transition: 0.5s;" :key="module">
          <hr style="background: #ECEAE2"/>

          <div class="d-flex align-items-center" style="margin-bottom: 25px;">
            <img class='collapse-arrow me-3' style="cursor:pointer;"
                 v-on:click="toggleCollapse(module)"
                 :src="getCollapseIconPath(module)">
            <img class='checkbox-icon me-4 checkbox-half'
                 :class="{'checkbox-checked':getLargeCheckbox(module)==='full', 'bg-gray':isRoleAdmin()}"
                 v-on:click="toggleLargeCheckbox(module)"
                 :src="getLargeCheckboxIconURL(module)">
            <span v-on:click="toggleCollapse(module)" class="section-module-name">{{ module }}</span>
          </div>
          <div v-for="permission of permissions[module]" :value="permission" :key="permission"
               class="mb-3"
          >
            <div class="d-flex align-items-center" style="margin-left:60px;"
            :class="{'d-none': searchInput && !permission.name.includes(searchInput)}"
            >
              <img class="mini-checkbox me-3"
                   :class="{'checkbox-checked': getCheckboxValue(module, permission), 'bg-gray':isRoleAdmin()}"
                   :src="getMiniCheckboxIconURL(module, permission)"
                   v-on:click="toggleCheckboxValue(module, permission)"
              />
              <div class="mini-checkbox-description fw-light">{{ permission.description || permission.name }}</div>
            </div>
          </div>
        </section>


        <div class="row">
          <div class="col-10"></div>
          <div class="offer-1 col">
            <button class="user-detail-button save-button" v-on:click="updateUser()">
              {{ $route.params.id !== 'new' ? 'Save Changes' : 'Send Invitation' }}
            </button>
          </div>
        </div>
        <hr
            v-if="$route.params.id!=='new'"
            style="background: #ECEAE2"/>
        <div
            v-if="$route.params.id!=='new'"

            class="row mt-4 mb-4">
          <div class="col-9">
            <div class="fs-5 fw-light mb-2">Deactivate user</div>
            <div class="fs-6 fw-lighter pe-5 me-5 fw-light w-75">
              This will not end the user’s subscription or payments and you will continue to be charged. You can cancel
              your subscription, or switch payment methods to keep the team active.
            </div>
          </div>
          <div class="offset-1 col-2">
            <button v-on:click="$bvModal.show('deactivate-modal')" class="user-detail-button deactivate-button">
              Deactivate User
            </button>
          </div>
        </div>

      </b-card>
    </div>
    <b-modal id="add-account-modal" size="md" centered hide-header hide-footer>
      <div class="d-flex flex-column justify-content-center p-4">
        <div class="m-auto mb-3">
          <h5 v-if="$route.params.id!=='new'"
          >Account Saved</h5>
          <h5 v-else>User Invited</h5>

        </div>
        <button class="m-auto user-detail-button save-button" v-on:click="$router.push('/dashboard/user-management')">
          go to Users List
        </button>

      </div>
    </b-modal>
    <b-modal id="deactivate-modal" size="md" centered hide-header hide-footer>
      <div class="d-flex flex-column justify-content-center p-4">
        <div class="m-auto mb-3">
          <h5>Do you confirm user deactivation?</h5>

        </div>
        <div class="row">
          <div class="col-6">
        <button class="m-auto user-detail-button d-inline" v-on:click="$bvModal.hide('deactivate-modal')">
          Cancel
        </button>
            </div>
          <div class="col-6">
        <button class="m-auto user-detail-button deactivate-button d-inline" v-on:click="deactivateUser()">
          Deactivate
        </button>
            </div>
          </div>

      </div>
    </b-modal>
  </div>

</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import PortalService from "@/services/portal.service";

export default {
  name: "userDetail",
  components: {PageHeader},
  data() {
    return {
      searchInput: '',
      loading: false,
      openSections: ["AIRFLOW",
        "MINIO",
        "ARGOCD",
        "HARBOR",
        "GITLAB",
        "TRITON",
        "DASHBOARD"],
      user: {},
      modules: [
        "AIRFLOW",
        "MINIO",
        "ARGOCD",
        "HARBOR",
        "GITLAB",
        "TRITON",
        "DASHBOARD"
      ],
      permissions: {},
      selectGroups: {
        "AIRFLOW": [],
        "MINIO": [],
        "ARGOCD": [],
        "HARBOR": [],
        "GITLAB": [],
        "TRITON": [],
        "DASHBOARD": []
      },
      roleOptions: [
        {label: 'Choose a role', id: null},
        {label: 'Member', id: 'Member'},
        {label: 'Admin', id: 'Admin'}
      ],
      roleSelected: null,
    }
  },
  mounted() {
    this.loading = true;
    this.error = null;
    let user_id = this.$route.params.id;
    if (user_id !== 'new') {

      PortalService.getUserDetail(user_id).then(({data}) => {
        this.user = data;
        this.roleSelected = this.getUserRole();
        this.updateGroups();
      })
    } else {
      this.updateGroups();
    }

  },
  methods: {
    updateGroups() {
      for (let [index, module] of this.modules.entries()) {
        PortalService.getModuleGroup(module).then(({data}) => {
          if (data.length > 0) {
            this.$set(this.permissions, module, data)
            for (let group of data) {
              if (this.user?.groups?.includes(group.name)) {
                this.selectGroups[module].push(group)
              }
            }
          }
          if (index === this.modules.length - 1) {
            this.loading = false;
          }
        })
      }
    },
    checkSearch(module) {
      if (!this.searchInput) {
        return false
      } else {
        if (module.toLowerCase().includes(this.searchInput.toLowerCase())) {
          return false
        }
        for (let item of this.permissions[module]) {
          if (item.name.toLowerCase().includes(this.searchInput.toLowerCase())) {
            return false
          }
        }
        return true
      }
    },
    toggleLargeCheckbox(module) {
      if (this.getLargeCheckbox(module) === 'full') {
        this.$set(this.selectGroups, module, [])
      } else {
        this.$set(this.selectGroups, module, this.permissions[module])
      }
    },
    getMiniCheckboxIconURL(module, permission) {
      let fileName = 'empty'
      if (this.getCheckboxValue(module, permission)) {
        fileName = 'full'
      }
      return `/images/lg-checkbox/${fileName}.svg`
    },
    toggleCheckboxValue(module, permission) {
      if (this.selectGroups[module].includes(permission)) {
        let temp = new Set(this.selectGroups[module]);
        temp.delete(permission);
        this.selectGroups[module] = [...temp];
      } else {
        this.selectGroups[module].push(permission)
      }
    },
    getCheckboxValue(module, permission) {
      return this.selectGroups[module].includes(permission) || this.isRoleAdmin()
    },
    getLargeCheckbox(module) {
      if (this.isRoleAdmin()) {
        return 'full'
      }
      let allLength = this.permissions[module].length
      let selectedLenth = this.selectGroups[module].length
      if (allLength === selectedLenth) {
        return 'full'
      } else if (selectedLenth === 0) {
        return 'empty'
      } else {
        return 'half'
      }
    },
    getLargeCheckboxIconURL(module) {
      let fileName = this.getLargeCheckbox(module)
      return `/images/lg-checkbox/${fileName}.svg`
    },
    getFullname() {
      if (!this.loading) {
        let firstName = this.user.firstName || ''
        let lastName = this.user.lastName || ''
        if (firstName === '' && lastName === '') {
          return 'User'
        }
        return firstName + ' ' + lastName
      }
      return 'loading...'
    },
    getCollapseIconPath(module) {
      let iconName = 'open';
      if (this.openSections.includes(module)) {
        iconName = 'closed'
      }
      return `/images/collapse-section/${iconName}.svg`
    },
    toggleCollapse(module) {
      if (this.openSections.includes(module)) {
        let temp = new Set(this.openSections);
        temp.delete(module);
        this.openSections = [...temp];
      } else {
        this.openSections.push(module)
      }
    },
    getUserRole() {
      return this.user.roles.length > 0 ? this.user.roles[0] : null;
    },
    setRoleValue(value) {
      if (value) {
        this.roleSelected = value
      }
    },
    deactivateUser() {
      this.loading = true;
      PortalService.toggleUserActive(this.user?.id, false).then(() => {
        this.$router.push('/dashboard/user-management')
      })
    },
    isRoleAdmin() {
      return this.roleSelected === 'Admin'
    },
    updateUser() {
      this.loading = true;
      let role = null;
      if (this.roleSelected === 'Admin') {
        role = 'ADMIN'
      } else {
        role = 'MEMBER'
      }
      let userData = {
        "email": this.user.email,
        "role": role,
        "modules": {}
      }

      if (this.$route.params.id !== 'new') {
        userData["firstName"] = this.user.firstName
        userData["lastName"] = this.user.lastName
      }
      if (this.isRoleAdmin()) {
        for (const [key, value] of Object.entries(this.permissions)) {
          if (value.length > 0) {
            userData.modules[key] = [];
            for (let groupObj of value) {
              userData.modules[key].push(groupObj.name)
            }
          }
        }
      } else {
        for (const [key, value] of Object.entries(this.selectGroups)) {
          if (value.length > 0) {
            userData.modules[key] = [];
            for (let groupObj of value) {
              userData.modules[key].push(groupObj.name)
            }
          }
        }
      }

      if (this.$route.params.id === 'new') {
        PortalService.inviteUser(userData).then(() => {
          this.$bvModal.show('add-account-modal')
        });
      } else {
        PortalService.updateUser(this.$route.params.id, userData).then(() => {
          this.$bvModal.show('add-account-modal')
        });
      }

    }
  }
};
</script>

<style>
button.w-100.deactivate.button {
  background: #DD3D05;
  border: 0;
  padding: 10px;
  color: white;
  border-radius: 5px;
}

input[type=checkbox] {
  height: 18px;
}

.user-detail-button {
  width: 80%;
  border: 0;
  height: 40px !important;
  font-size: 19px;
  font-weight: 500;
  border-radius: 5px;
  margin-left: 20%;
}

.save-button {
  background: #13D39E;
  color: #062D33;
}

label, div.form-label.labelText {
  font-weight: 300;
  font-size: 18px;
  color: rgba(8, 62, 71, 1);
  margin-bottom: 2px;
}

.form-input-font, select[label=role] {
  font-weight: 500 !important;
  font-size: 16px !important;
}

.deactivate-button {
  background: #DD3D05;
  color: white !important;
  margin-top: 10px
}

.checkbox-icon {
  border-radius: 5px;
  margin-left: 20px;
  width: 38px;
}

.collapse-arrow {
  width: 14px;
}

.limited-height .collapse-arrow {
  width: 10px;
  margin-left: 4px
}

.checkbox-half {
  background: white;
  border: solid 1px #CBD5E1;
}

.section-module-name {
  font-weight: 400;
  font-size: 18px;
}

.limited-height {
  height: 93px !important;
}

.mini-checkbox {
  border-radius: 5px;
  background: white;
  border: 1px solid #CBD5E1;
}

.mini-checkbox-description {
  font-size: 14px;
}

.checkbox-checked {
  background: #13D39E !important;
  border-color: white;
}

.bg-gray {
  background: gray !important;
}
</style>
