<template>
  <div id="assignees-list-container">
    <div class="d-flex align-items-center mb-3">
      <div class="title">Assignees</div>
      <div style="position: relative; margin-left: 1rem" v-if="false">
        <div
          :class="['tab', { active: open_menu ? true : false }]"
          @click.stop="toggle_assignee_menu()"
        >
          Assignee
        </div>
        <Menu7
          @change-filter="changeFilter"
          @close-menu="toggle_assignee_menu"
          v-if="lookups && lookups.assingees.length"
          :open_menu="open_menu"
          :menu_items="lookups.assingees"
        />
      </div>
    </div>
    <div v-if="!data || !data.length">
      <b-alert :show="!data || !data.length ? true : false" variant="danger">
        No data found
      </b-alert>
    </div>
    <div v-else>
      <div
        v-for="(user, index) in data"
        :key="index"
        class="block d-flex align-items-center justify-content-between"
      >
        <div style="flex: 0.3" class="d-flex align-items-center">
          <div class="avtar d-flex align-items-center justify-content-center">
            <img v-if="user.key.avatar" :src="user.key.avatar" alt="avtar" />
            <span class="text-uppercase" v-else>{{
              user.key.name.substr(0, 2)
            }}</span>
          </div>
          <div>
            <div class="user-name">{{ user.key.name }}</div>
            <div class="user-email">{{ user.key.email }}</div>
          </div>
        </div>
        <div
          style="flex: 0.4"
          class="d-flex align-items-center justify-content-between"
        >
          <div v-if="user.period1.priorities.length">
            <div class="d-flex align-items-center">
              <div class="marker" style="background-color: #124bd8"></div>
              <div class="label">Period 1</div>
            </div>
            <div class="d-flex align-items-center">
              <div
                class="sub-label"
                :style="!index ? 'color: #112849' : ''"
                v-for="(priority, index) in user.period1.priorities"
                :key="index"
              >
                {{ priority.value }} {{ priority.key }}
              </div>
            </div>
          </div>
          <div v-if="user.period1.priorities.length">
            <apex-chart
              height="100"
              type="donut"
              :options="getOptions(user.period1.priorities)"
              :series="getSeries(user.period1.priorities)"
            />
          </div>
        </div>
        <div
          style="flex: 0.4"
          class="d-flex align-items-center justify-content-between"
        >
          <div v-if="user.period2.priorities.length">
            <div class="d-flex align-items-center">
              <div class="marker" style="background-color: #89a5ec"></div>
              <div class="label">Period 2</div>
            </div>
            <div class="d-flex align-items-center">
              <div
                class="sub-label"
                :style="!index ? 'color: #112849' : ''"
                v-for="(priority, index) in user.period2.priorities"
                :key="index"
              >
                {{ priority.value }} {{ priority.key }}
              </div>
            </div>
          </div>
          <div v-if="user.period2.priorities.length">
            <apex-chart
              height="100"
              width="100"
              type="donut"
              :options="getOptions(user.period2.priorities)"
              :series="getSeries(user.period2.priorities)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu7 from "@/pages/Tickets/components/Menu7.vue";

export default {
  name: "Assignees-List",
  props: ["data", "lookups"],
  data() {
    return {
      open_menu: false,
    };
  },
  components: { Menu7 },
  methods: {
    toggle_assignee_menu() {
      this.open_menu = !this.open_menu;
    },
    changeFilter(key, val) {
      console.log(
        key,
        val.map((each) => each.id)
      );
    },
    getOptions(priorities) {
      return {
        legend: {
          show: false,
        },
        colors: ["#112849", "#124BD8", "#89A5EC"],
        labels: priorities.map((each) => each.key),
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: () => {
            return "";
          },
        },
      };
    },
    getSeries(priorities) {
      return priorities.map((each) => each.value);
    },
  },
};
</script>

<style scoped>
#assignees-list-container {
  background-color: #0B3133;
  border-radius: 4px;
  border: 1px solid #ebe9e1;
  padding: 20px 25px;
}
#assignees-list-container .title {
  color: #112849;
  font-size: 18px;

}
#assignees-list-container .tab {
  cursor: pointer;
  background-color: #F9F9F9;
  border-radius: 6px;
  color: #7e8282;
  font-size: 14px;

  padding: 6px 12px;
}
#assignees-list-container .tab:hover {
  background-color: #ebe9e1;
}
#assignees-list-container .tab.active {
  background-color: #112849;
  color: #8bcdea;
}
#assignees-list-container .block {
  border-bottom: 1px solid #ebe9e1;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
}
#assignees-list-container .avtar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #7968d3;
  color: #ffffff;
  font-size: 20px;
  margin-right: 0.5rem;
}
#assignees-list-container .avtar img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
#assignees-list-container .user-name {
  color: #112849;
  font-size: 16px;

}
#assignees-list-container .user-email {
  color: #7e8282;
  font-size: 14px;

}
#assignees-list-container .marker {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 0.5rem;
}
#assignees-list-container .label {
  color: #7e8282;
  font-size: 14px;

}
#assignees-list-container .sub-label {
  color: #a3a4a2;
  font-size: 14px;

  margin-right: 0.5rem;
}
</style>
