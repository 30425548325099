<template>
  <div id="analytics-container">
    <div>
      <div
        class="d-flex justify-content-between align-items-center"
        style="margin-bottom: 25px"
      >
        <div class="main-title">Analytics</div>
        <div style="poition: relative" v-if="false">
          <div
            :class="[
              'download-btn',
              { active: open_download_menu ? true : false },
            ]"
            @click.stop="toggle_download_menu()"
          ></div>
          <Menu1 :open_menu="open_download_menu" @close-menu="closeMenu" />
        </div>
      </div>
      <div v-if="error && error.length">
        <b-alert :show="error && error.length ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
      </div>
      <div class="d-flex align-items-center" style="margin-bottom: 25px">
        <DateRange
          label="period1"
          @change-range="changeRange"
          :range="period1_dateRange"
          style="margin-right: 25px"
        />
        <span class="compare-label" style="margin-right: 25px">
          Compare to
        </span>
        <DateRange
          label="period2"
          @change-range="changeRange"
          :range="period2_dateRange"
        />
      </div>
      <common-loading v-if="loading" />
      <div v-else-if="analytics && lookups">
        <b-row style="margin-bottom: 25px">
          <b-col md="7" sm="12">
            <TicketStatus
              :lookup="lookups.statuses"
              :data="analytics.cards_by_status"
            />
          </b-col>
          <b-col md="5" sm="12">
            <InitialResponse
              :lookup="lookups.ttfr_breached"
              :data="analytics.cards_by_ttfr"
            />
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 25px">
          <b-col md="6" sm="12">
            <TicketTypes
              :lookup="lookups.issue_types"
              :data="analytics.cards_by_type"
            />
          </b-col>
          <b-col md="6" sm="12">
            <InitialResponseBar
              :lookup="lookups.ttr_breached"
              :data="analytics.cards_by_ttr"
            />
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 25px">
          <b-col>
            <TicketPriority
              :lookup="lookups.priorities"
              :data="analytics.cards_by_priority"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <Assignees :data="analytics.cards_by_assignee" :lookups="lookups" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Menu1 from "./components/Menu1.vue";
import DateRange from "./components/DateRange.vue";
import TicketStatus from "./components/TicketStatus.vue";
import InitialResponse from "./components/InitialResponse.vue";
import TicketTypes from "./components/TicketTypes.vue";
import InitialResponseBar from "./components/InitialResponseBar.vue";
import TicketPriority from "./components/TicketPriority.vue";
import Assignees from "./components/Assignees.vue";

import CardService from "../../services/card.service";

export default {
  name: "analytics-page",
  components: {
    Menu1,
    DateRange,
    TicketStatus,
    InitialResponse,
    TicketTypes,
    InitialResponseBar,
    TicketPriority,
    Assignees,
  },
  data() {
    const curr = new Date();
    const prev = curr.getDate() - curr.getDay() - 7;

    const period1_from = curr;
    const period1_to = new Date(
      curr.setDate(curr.getDate() - curr.getDay() + 6)
    );

    const period2_from = new Date(curr.setDate(prev));
    const period2_to = new Date(curr.setDate(prev + 6));

    return {
      open_download_menu: false,
      loading: false,
      error: null,
      lookups: null,
      analytics: null,
      period1_dateRange: { startDate: period1_from, endDate: period1_to },
      period2_dateRange: { startDate: period2_from, endDate: period2_to },
    };
  },
  methods: {
    toggle_download_menu() {
      this.open_download_menu = !this.open_download_menu;
    },
    closeMenu() {
      this.open_download_menu = false;
    },
    query(params = {}) {
      this.loading = true;
      this.error = null;

      if (this.period1_dateRange.startDate)
        params["period1_from"] = new Date(
          this.period1_dateRange.startDate
        ).toISOString();

      if (this.period1_dateRange.endDate)
        params["period1_to"] = new Date(
          this.period1_dateRange.endDate
        ).toISOString();

      if (this.period2_dateRange.startDate)
        params["period2_from"] = new Date(
          this.period2_dateRange.startDate
        ).toISOString();

      if (this.period2_dateRange.endDate)
        params["period2_to"] = new Date(
          this.period2_dateRange.endDate
        ).toISOString();

      CardService.Analytics(params)
        .then(({ data }) => {
          this.analytics = data;
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error in loading analytics.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeRange(label, startDate, endDate) {
      if (label === "period1") {
        this.period1_dateRange = { startDate, endDate };
      }
      if (label === "period2") {
        this.period2_dateRange = { startDate, endDate };
      }
      this.query();
    },
  },
  mounted() {
    this.loading = true;
    this.error = null;

    CardService.Lookups(null)
      .then(({ data }) => {
        this.lookups = data;
      })
      .catch(({ message }) => {
        this.error = message
          ? message
          : "There was an error in loading lookups.";
      })
      .finally(() => {
        this.query();
        this.loading = false;
      });
  },
};
</script>

<style scoped>
#analytics-container {
  height: 100vh;
  overflow: auto;
  padding: 25px;
}
#analytics-container .main-title {
  font-size: 22px;

  color: #a3a4a2;
}
#analytics-container .download-btn {
  height: 50px;
  width: 50px;
  cursor: pointer;
  background-image: url(../../assets/images/download-icon.png);
  background-repeat: no-repeat;
  background-position: center;
}
#analytics-container .download-btn:hover {
  background-image: url(../../assets/images/download-icon-active.png);
}
#analytics-container .download-btn.active {
  background-image: url(../../assets/images/download-icon-active.png);
}
</style>
