import Vue from "vue";

const baseURL = process.env.VUE_APP_HARBOR_API_URL;

class ArgoCDService {
    static GetDashboard() {
        const url = `${baseURL}/argocd/dashboard`;
        return Vue.axios.get(
            url,{headers:{
                'Content-Type':'application/json'
                }}
        );
    }
}

export default ArgoCDService;
