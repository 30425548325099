<template>
  <div
      id="callback-container"
      class="d-flex justify-content-center align-items-center"
  >
    <common-loading v-if="loading"/>
    <div v-else-if="error">
      <div class="error" v-html="error"></div>
    </div>
  </div>
</template>

<script>
import {JWTService} from "../../storage";
import {TOKEN_EXCHANGE} from "../../store/auth.module.js";
import AuthService from "@/services/auth.service";

export default {
  name: "callback-page",
  data() {
    return {error: "", loading: false};
  },
  mounted() {
    if (this.$route.query.code) {
      this.loading = true;

      const payload = {
        grant_type: `authorization_code`,
        client_id: `frontend-client`,
        redirect_uri: `${window.location.origin}/callback`,
        code: `${this.$route.query.code}`,
        scope: `openid`,
        code_verifier: JWTService.getCodeVerifier(),
      };

      return this.$store
          .dispatch(TOKEN_EXCHANGE, payload)
          .then(() => {

            const user = AuthService.GetUserInfo()
            if (user.realm_access && user.realm_access?.roles?.length > 0 && user.realm_access.roles[0] === 'Admin') {
              return this.$router.push({name: "Dashboard"});
            } else {
              return this.$router.push({name: "MemberLanding"});

            }
          })
          .catch(({message}) => {
            this.error = message
                ? message
                : "There was an error in exchanging token.";
          })
          .finally(() => {
            this.loading = false;
          });
    } else if (this.$route.query.error_description) {
      this.error = this.$route.query.error_description;
    }
  },
};
</script>

<style scoped>
#callback-container {
  height: 100vh;
}

#callback-container .error {
  font-size: 22px;

  color: #f44336;
}
</style>
