<template>
  <div
    id="menu-container"
    class="menu-list"
    style="left: 0px; top: 45px; width: 175px"
    v-if="open_menu"
    v-click-outside="onClickOutside"
  >
    <div style="margin-bottom: 5px">
      <base-input
        placeholder="Search..."
        cus_style="padding:10px 10px 10px 35px;background-color:#0B3133"
        :remove_default_margin="true"
        field="term"
        :value="term"
        @change-value="setValue"
      >
        <template v-slot:attachment>
          <img
            class="search-icon"
            src="../../../../assets/images/search-icon.png"
            alt="search-icon"
          />
        </template>
      </base-input>
    </div>
    <div
      v-for="(menu_item, index) in menu_items.filter((each) =>
        each.toLocaleLowerCase().includes(term.toLocaleLowerCase())
      )"
      :class="[
        'menu-item',
        { active: selected_item === menu_item ? true : false },
      ]"
      :key="index"
      @click.stop="select_item(menu_item)"
    >
      {{ menu_item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "year-menu",
  props: ["open_menu"],
  emits: ["change-filter", "close-menu"],
  data() {
    return {
      menu_items: ["2024", "2023", "2022"],
      selected_item: "",
      term: "",
    };
  },
  methods: {
    select_item(menu_item) {
      this.selected_item = menu_item;
    },
    setValue(val, field) {
      this[field] = val;
    },
    onClickOutside() {
      this.$emit("close-menu");
    },
  },
  watch: {
    selected_item(val) {
      this.$emit("change-filter", "year", val);
    },
  },
};
</script>

<style scoped>
.search-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
