<template>
  <div>
 <PageHeader
        :title="billing_type"
        parentLabel="Cloud Billing"
    />
    <div id="billing-container">
      <common-loading v-if="loading"/>
      <div v-else-if="billing_type">
        <div
            style="padding: 25px"
            class="d-flex align-items-center justify-content-between"
        >

          <div class="main-title">
            Cloud Billing<span class="border"></span
          ><span>{{ billing_type }}</span>
          </div>

        </div>
        <AWS v-if="billing_type === 'AWS'"/>
        <NoAccounts :provider=billing_type v-else/>
      </div>
    </div>
  </div>

</template>

<script>
import AWS from "./AWS/index.vue";
import NoAccounts from "./components/NoAccounts.vue";
import PageHeader from "@/components/ui/PageHeader.vue";

export default {
  name: "billing-page",
  components: {PageHeader, AWS, NoAccounts},
  data() {
    return {
      loading: false,
      billing_type: null,
      options: [
        {id: null, label: "Select any"},
        {id: 1, label: "Account Name Here"},
        {id: 2, label: "Account Name Here"},
        {id: 3, label: "Account Name Here"},
      ],
      access: 1,
    };
  },
  methods: {
    setValue(val, field) {
      this[field] = val;
    },
    setType(val) {
      if (
          val &&
          val.params &&
          val.params.billing_type &&
          (!this.billing_type || this.billing_type !== val.params.billing_type)
      ) {
        this.billing_type = val.params.billing_type;
      }
    },
  },
  watch: {
    $route(val) {
      this.setType(val);
    },
  },
  mounted() {
    this.setType(this.$route);
  },
};
</script>

<style scoped>
#billing-container {
  height: 100vh;
  overflow: auto;
}

#billing-container .main-title {
  font-size: 22px;

  color: #a3a4a2;
}

#billing-container .border {
  height: 20px;
  width: 2px;
  background-color: #a3a4a2;
  margin: 0rem 0.5rem;
}
</style>
