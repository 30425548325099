<template>
  <div id="content-container">
    <common-loading v-if="loading" />
    <div v-else-if="error && error.length">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
    </div>
    <div v-else-if="data">
      <div style="margin-top: 1rem">
        <div class="Model-title">Model Details</div>
        <b-row>
          <b-col md="4" xl="2">
            <div class="bill-queue">
              <div
                v-for="(each, index) in modelDetails"
                :key="index"
                style="position: relative"
              >
                <div
                  class="bill-queue-tab d-flex align-items-center"
                  :class="[
                    {
                      active: selected_tab_index === index,
                    },
                  ]"
                  @click.stop="selectTab(index)"
                >
                  <div class="bill-queue-label">
                    {{ each.service }}
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="8" xl="10">
            <div class="bill-detail">
              <div class="service-container">
                <div
                  v-for="(tab, index) in modelDetails.find((e, i) => {
                    if (i === selected_tab_index) return e;
                  }).model_detail"
                  :key="index"
                >
                  <div>
                    <div
                      v-if="tab.model_type === 'Inputs'"
                      class="service py-2"
                    >
                      <div class="usage-type pb-2">{{ tab.model_type }}</div>
                      <div class="d-flex">
                        <div v-if="tab.inputSize">
                          <div
                            v-for="(value, index) in tab.inputSize"
                            :key="index"
                            class="d-flex"
                          >
                            <span class="input-name">{{ value.name }}</span>
                            <div
                              v-for="(cell, index) in convertInputSizeToArray(
                                value.inputSize
                              )"
                              :key="index"
                              class="input-cells m-2"
                            >
                              {{ cell }}
                            </div>
                          </div>
                        </div>
                        <span v-else> no inputs available </span>
                      </div>
                    </div>

                    <div
                      v-else-if="tab.model_type === 'outputs'"
                      class="service"
                    >
                      <div class="usage-type pb-2">{{ tab.model_type }}</div>
                      <div class="d-flex">
                        <div v-if="tab.outputs">
                          <div
                            class="d-flex"
                            v-for="(value, index) in tab.outputs"
                            :key="index"
                          >
                            <span class="input-name">{{ value.name }}</span>
                            <div
                              v-for="(cell, index) in convertInputSizeToArray(
                                value.outputSize
                              )"
                              :key="index"
                              class="input-cells m-2"
                            >
                              {{ cell }}
                            </div>
                          </div>
                        </div>
                        <span v-else> no outputs available </span>
                      </div>
                    </div>
                    <div
                      v-else-if="tab.model_type === 'status'"
                      class="service"
                    >
                      <div class="usage-type pb-2">{{ tab.model_type }}</div>
                      <div class="d-flex">
                        <div v-if="tab.statuses">
                          <div
                            class="d-flex"
                            v-for="(value, index) in tab.statuses"
                            :key="index"
                          >
                            <span class="input-name">{{
                              value.description
                            }}</span>
                            <div class="m-2">
                              {{ value.createdAt }}
                            </div>
                          </div>
                        </div>
                        <span v-else> no status available </span>
                      </div>
                    </div>

                    <div
                      v-else-if="tab.model_type === 'general'"
                      class="d-flex flex-column gap-2"
                    >
                      <div class="text-capitalize">backend</div>
                      <div class="text-capitalize bold-label">
                        {{ data.backend ? data.backend : "---" }}
                      </div>
                      <div class="text-capitalize">dynamic batching</div>
                      <div class="text-capitalize bold-label">
                        {{ data.dynamicPatch ? "On" : "Off" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "content-page",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      loading: false,
      error: null,
      modelDetails: [
        {
          service: "general details",
          model_detail: [
            {
              model_type: "general",
            },
          ],
        },
        {
          service: "inputs",
          model_detail: [
            {
              model_type: "Inputs",
              inputSize: this.data.inputHistory,
            },
          ],
        },
        {
          service: "outputs",
          model_detail: [
            {
              model_type: "outputs",
              outputs: this.data.outputs,
            },
          ],
        },
        {
          service: "status history",
          model_detail: [
            {
              model_type: "status",
              statuses: this.data.statusHistory,
            },
          ],
        },
      ],
      selected_tab_index: 0,
    };
  },
  methods: {
    selectTab(index) {
      this.selected_tab_index = index;
    },
    convertInputSizeToArray(inputSize) {
      if (inputSize) {
        return inputSize.trim().split(/\s+/);
      }
    },
  },
};
</script>

<style scoped>
#content-container {
  margin: 1.5rem;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #ebe9e1;
  padding: 0 20px 20px 20px;
}

#content-container .top-container {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ebe9e1;
}

#content-container .top-container .filter-tab {
  background-color: #89e9cf;
  color: #112849;
  border-radius: 6px;

  font-size: 14px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
}

#content-container .bill-queue-tab {
  background-color: #f9f9f9;
  color: #112849;

  font-size: 14px;
  font-weight: 700;
  min-height: 50px;
  padding: 10px 20px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  text-transform: capitalize;
}

#content-container .bill-queue-tab.active {
  background-color: #0fa97e;
  color: #f9f9f9;
  padding: 10px 20px 10px 20px;
}

#content-container .bill-queue-tab.active::after {
  content: "";
  position: absolute;
  right: -39px;
  border: solid 25px transparent;
  border-left-color: #0fa97e;
  border-left-width: 15px;
}

#content-container .bill-queue-tab.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  color: #d4d2cb;
}

#content-container .bill-queue-value {
  margin-right: 0.5rem;
}

#content-container .bill-detail {
  min-height: 450px;
  border-radius: 4px;
  border: 1px solid #ebe9e1;
  padding: 15px;
}

#content-container .bill-detail .bill-detail-tab {
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 6px;
  color: #7e8282;
  font-size: 14px;

  padding: 6px 12px;
  margin: 5px 0px;
}

#content-container .bill-detail .bill-detail-tab:hover {
  background-color: #ebe9e1;
}

#content-container .bill-detail .bill-detail-tab.active {
  background-color: #112849;
  color: #89e9cf;
}

#content-container .bill-detail .filter-container {
  border-bottom: 1px solid #ebe9e1;
  padding-bottom: 1rem;
}

#content-container .bill-detail .service-container {
  max-height: 450px;
  overflow: auto;
}

#content-container .bill-detail .service-container::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

#content-container .bill-detail .service {
  color: #112849;
  font-size: 14px;

  margin: 5px 0px;
}

#content-container .bill-detail .sub_service {
  color: #5a6062;
  font-size: 14px;

  margin: 5px 0px;
}

#content-container .bill-detail .sub_sub_service {
  color: #5a6062;
  font-size: 14px;

  margin: 5px 0px;
}

.Model-title {
  font-size: 18px;
  font-weight: 700;
  color: #083e47;
  padding-bottom: 15px;
}

.usage-type {
  font-size: 14px;
  font-weight: 500;
  color: #021214;
  width: 150px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.input-cells {
  background-color: #f3f4f4;
  color: #112849;
  font-size: 14px;
  font-weight: 500;
  min-width: 50px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-name {
  font-size: 12px;
  font-weight: 500;
  color: #021214;
  text-transform: capitalize;
  align-self: center;
  flex-grow: 2;
  padding-right: 20px;
}

.bold-label {
  font-weight: 700;
  font-size: 16px;
  color: #083e47;
}
</style>
