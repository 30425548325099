<template>
  <div class="text-primary">
    <div id="registration-container" class="margin-under-header">
      <b-card>

        <div class="fs-5 fw-bold">Welcome to OmniOps!</div>

      </b-card>
    </div>
    <PageHeader title="Home"/>
  </div>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";

export default {
  name: 'Main-Dashboard',
  components: {PageHeader},
};
</script>

<style scoped>

#registration-container {
  padding: 0px 25px;
}

#registration-container .tab {
  background-color: #89E9CF;
  border-radius: 4px;
  color: #112849;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}

#registration-container .tab .label {

  font-size: 14px;
}

#registration-container .tab .value {

  font-size: 26px;
}

#registration-container .tab .value .red-label {

  font-size: 14px;
  color: #ce1a36;
  margin-left: 0.25rem;
}

#registration-container .tab .value .red-label img {
  margin-right: 0.15rem;
  margin-top: -1px;
}

.border-right {
  border-right: 1px solid #ECEAE2;
}

.font-size-14px {
  font-size: 14px;
}

.font-size-12px {
  font-size: 12px;
}

.bg-primary {
  background-color: #0C5966 !important;
}

.bg-secondary {
  background-color: #E7FBF5 !important;
}

.text-primary {
  color: #062D33 !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #7E828280;
}

.border-bottom-lightgray {
  border-bottom: 1px solid #7E828280;
}

.text-deactivated {
  color: #7E828280;
}

.active-graph {
  border-bottom: 2px solid black;
}
</style>