import Vue from "vue";

const baseURL = process.env.VUE_APP_API_URL;

class CardService {
  static Cards(params = {}) {
    const url = `${baseURL}/cards`;

    return Vue.axios.get(url, { params });
  }

  static Stats() {
    const url = `${baseURL}/cards/stats`;

    return Vue.axios.get(url);
  }

  static Analytics(params = {}) {
    const url = `${baseURL}/cards/analytics`;

    return Vue.axios.get(url, { params });
  }

  static Lookups() {
    const url = `${baseURL}/cards/lookups`;

    return Vue.axios.get(url);
  }
}

export default CardService;
