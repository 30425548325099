import AuthService from "../services/auth.service";

import { JWTService } from "../storage";

export const TOKEN_EXCHANGE = "token_exchange";
export const TOKEN_REFRESH = "token_refresh";
export const LOGOUT = "logout";

const AUTHENTICATE = "authenticate";
const DEAUTHENTICATE = "deauthenticate";

export default {
  state: {
    authenticated: !!JWTService.getToken(),
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
  },
  actions: {
    [TOKEN_EXCHANGE]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        AuthService.APIFortTokenExchange(payload)
          .then(({ data }) => {
            commit(AUTHENTICATE, data);

            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [TOKEN_REFRESH]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        AuthService.APIFortTokenRefresh(payload)
          .then(({ data }) => {
            commit(AUTHENTICATE, data);

            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [LOGOUT]({ commit }) {
      commit(DEAUTHENTICATE);
    },
  },
  mutations: {
    [AUTHENTICATE](state, { access_token, refresh_token, id_token }) {
      state.authenticated = true;

      JWTService.setToken(access_token);
      JWTService.setRefreshToken(refresh_token);
      JWTService.setIdToken(id_token);
    },
    [DEAUTHENTICATE](state) {
      state.authenticated = false;

      JWTService.removeToken();
      JWTService.removeRefreshToken();
    },
  },
};
