var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"ticket-list-container"}},[(_vm.cards)?_c('div',{staticClass:"block-list d-flex align-item-center"},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" New ("+_vm._s(_vm.cards.filter((each) => each.status === "New").length)+") ")]),_c('div',{staticClass:"list"},_vm._l((_vm.cards.filter(
            (each) => each.status === 'New'
          )),function(card,index){return _c('Ticket',{key:index,attrs:{"card":card}})}),1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" Pending ("+_vm._s(_vm.cards.filter((each) => each.status === "Pending").length)+") ")]),_c('div',{staticClass:"list"},_vm._l((_vm.cards.filter(
            (each) => each.status === 'Pending'
          )),function(card,index){return _c('Ticket',{key:index,attrs:{"card":card}})}),1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" Waiting on Customer ("+_vm._s(_vm.cards.filter((each) => each.status === "Waiting on Customer") .length)+") ")]),_c('div',{staticClass:"list"},_vm._l((_vm.cards.filter(
            (each) => each.status === 'Waiting on Customer'
          )),function(card,index){return _c('Ticket',{key:index,attrs:{"card":card}})}),1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" Third party ("+_vm._s(_vm.cards.filter((each) => each.status === "Third party").length)+") ")]),_c('div',{staticClass:"list"},_vm._l((_vm.cards.filter(
            (each) => each.status === 'Third party'
          )),function(card,index){return _c('Ticket',{key:index,attrs:{"card":card}})}),1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" Reported to engineering team ("+_vm._s(_vm.cards.filter( (each) => each.status === "Reported to engineering team" ).length)+") ")]),_c('div',{staticClass:"list"},_vm._l((_vm.cards.filter(
            (each) => each.status === 'Reported to engineering team'
          )),function(card,index){return _c('Ticket',{key:index,attrs:{"card":card}})}),1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" In-Progress ("+_vm._s(_vm.cards.filter((each) => each.status === "In-Progress").length)+") ")]),_c('div',{staticClass:"list"},_vm._l((_vm.cards.filter(
            (each) => each.status === 'In-Progress'
          )),function(card,index){return _c('Ticket',{key:index,attrs:{"card":card}})}),1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" Resolved ("+_vm._s(_vm.cards.filter((each) => each.status === "Resolved").length)+") ")]),_c('div',{staticClass:"list"},_vm._l((_vm.cards.filter(
            (each) => each.status === 'Resolved'
          )),function(card,index){return _c('Ticket',{key:index,attrs:{"card":card}})}),1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" Canceled ("+_vm._s(_vm.cards.filter((each) => each.status === "Canceled").length)+") ")]),_c('div',{staticClass:"list"},_vm._l((_vm.cards.filter(
            (each) => each.status === 'Canceled'
          )),function(card,index){return _c('Ticket',{key:index,attrs:{"card":card}})}),1)]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(" Closed ("+_vm._s(_vm.cards.filter((each) => each.status === "Closed").length)+") ")]),_c('div',{staticClass:"list"},_vm._l((_vm.cards.filter(
            (each) => each.status === 'Closed'
          )),function(card,index){return _c('Ticket',{key:index,attrs:{"card":card}})}),1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }