<template>
  <div id="tickets-topbar-container">
    <common-loading v-if="loading" />
    <div v-else-if="error && error.length">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
    </div>
    <div v-else-if="data">
      <b-row>
        <b-col md="2">
          <div class="block d-flex align-item-center justify-content-center">
            <div>
              <div class="label" style="font-family: Poppins-Bold">
                Open Tickets
              </div>
              <div class="value" style="font-family: Poppins-Bold">
                {{ data.opened }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div
            class="block d-flex align-item-center justify-content-center flex-wrap"
          >
            <div
              v-for="(card, index) in data.cards_by_priority"
              :key="index"
              :style="`padding:0px 30px;border-left:${
                index ? '1px solid #d0dbf7' : ''
              }`"
            >
              <div class="label">{{ card.key }}</div>
              <div class="value">{{ card.value }}</div>
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <div
            class="block d-flex align-item-center justify-content-center flex-wrap"
          >
            <div
              v-for="(card, index) in data.cards_by_type"
              :key="index"
              :style="`padding:0px 30px;border-left:${
                index ? '1px solid #d0dbf7' : ''
              }`"
            >
              <div class="label">{{ card.key }}</div>
              <div class="value">{{ card.value }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CardService from "../../../services/card.service";

export default {
  name: "tickets-topbar",
  data() {
    return { loading: false, error: null, data: null };
  },
  mounted() {
    this.loading = true;
    this.error = null;

    CardService.Stats()
      .then(({ data }) => {
        this.data = data;
      })
      .catch(({ message }) => {
        this.error = message ? message : "There was an error in loading stats.";
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
#tickets-topbar-container {
  padding: 0px 25px 15px 25px;
}

#tickets-topbar-container .block {
  background-color: #e7edfb;
  border-radius: 4px;
  border: 1px solid #d0dbf7;
  color: #112849;
  text-align: center;
  padding: 15px;
  margin-bottom: 0.5rem;
}

#tickets-topbar-container .label {
  font-size: 14px;

}

#tickets-topbar-container .value {
  font-size: 26px;

}

#tickets-topbar-container .middle-border {
  border: 1px solid #d0dbf7;
}
</style>
