<template>
  <div id="user-filter-container">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <base-input
          placeholder="Search users"
          cus_style="padding:10px 10px 10px 35px;background-color:#0B3133"
          :remove_default_margin="true"
          field="term"
          :value="term"
          @change-value="setValue"
        >
          <template v-slot:attachment>
            <img
              class="search-icon"
              src="../../../assets/images/search-icon.png"
              alt="search-icon"
            />
          </template>
        </base-input>
      </div>
      <div>
        <base-button style_variant="primary" @onClick="openModal">
          Invite Users
        </base-button>
      </div>
    </div>
    <InviteUser />
  </div>
</template>

<script>
import InviteUser from "./InviteUser.vue";

export default {
  name: "user-filter",
  components: { InviteUser },
  data() {
    return { term: "" };
  },
  methods: {
    setValue(val, field) {
      this[field] = val;
    },
    openModal() {
      this.$bvModal.show("invite-user-modal");
    },
  },
};
</script>

<style scoped>
#user-filter-container .search-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
