<template>
  <div>
    <PageHeader
      :title="data.modelName"
      :isAdvanced="data.isDedicatedServer"
      :modelId="data.uuid"
      :isModelDetails="isModelDetails"
      parentLabel="Inference Dashboard"
      :backAllowed="true"
    />

    <div id="details-container">
      <common-loading v-if="loading" />
      <div v-else-if="error && error.length">
        <b-alert :show="error && error.length ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
      </div>
      <div v-else-if="data !== undefined">
        <div v-if="data">
          <div id="insights-container">
            <div class="d-flex align-items-center gap-2 pb-4">
              <div :class="['status-tag', getModelStatus(data.currentStatus)]">
                {{ data.currentStatus }}
              </div>
              <div class="model-name flex-grow-1 fs-6">
                {{ data.modelName }}
              </div>
              <div class="d-flex flex-grow-2 gap-4">
                <OptimizationButton
                  v-if="getModelStatus(data.currentStatus) === 'optimized'"
                  :isDetailsPage="true"
                  :modelId="data.uuid"
                />
                <PrimeButton
                  class="model-button"
                  icon="pi pi-copy"
                  @click="copyModelName(data.modelName)"
                />
              </div>
            </div>
            <div class="tabs grid-auto-fir-minmax">
              <div
                style="padding: 0rem 0.25rem"
                v-for="insight in insights"
                :key="insight.name"
              >
                <div class="tab">
                  <div class="left-border">
                    <div
                      class="label"
                      style="
                        text-transform: capitalize;
                        font-weight: bold;
                        padding-left: 10px;
                      "
                    >
                      {{ insight.name }}
                    </div>
                    <div class="value" style="padding-left: 10px" v-if="data">
                      <span class="pe-2" style="font-weight: bold">{{
                        insight.value
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BillingContent :data="data" />
      </div>

      <NoAccounts v-else />
    </div>
  </div>
</template>

<script>
import TritonInferenceService from "@/services/tritonInference.service";
import BillingContent from "@/pages/Billing/AWS/components/Content.vue";
import NoAccounts from "@/pages/Billing/components/NoAccounts.vue";
import PageHeader from "@/components/ui/PageHeader.vue";
import OptimizationButton from "@/components/common/OptimizationButton.vue";

const baseURL = process.env.VUE_APP_TRITON_URL;
export default {
  name: "Triton-inference-details",
  components: { NoAccounts, PageHeader, BillingContent, OptimizationButton },
  data() {
    return {
      loading: false,
      error: null,
      isModelDetails: true,
      paramId: null,
      data: {},
      insights: null,
    };
  },
  created() {
    this.paramId = this.$route.params.id;
  },
  mounted() {
    this.loading = true;

    TritonInferenceService.GetModelById(this.paramId)
      .then((response) => {
        this.data = response.data;
        this.insights = [
          {
            name: "GPU Memory Used",
            value: this.data.gpuMemoryUsage,
          },
          {
            name: "GPU Power usage",
            value: this.data.gpuPowerUsage,
          },
          {
            name: "GPU Power Limit",
            value: this.data.gpuPowerLimits,
          },
          {
            name: "Number of Requests",
            value: this.data.executionCount,
          },
          {
            name: "Queue Duration (milliseconds)",
            value: this.data.gpuQueueDuration,
          },
          {
            name: "Request Handling Time (milliseconds)",
            value: this.data.gpuComputeInputDuration,
          },
          {
            name: "Failed Requests",
            value: this.data.failedCount,
          },
          {
            name: "Instances",
            value: this.data.instances,
          },
        ];
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getModelStatus(model) {
      if (model) return model.toLowerCase();
    },
    getModelNameVersion(modelName) {
      return modelName.split("__");
    },
    getModelNameOnly(modelName) {
      return this.getModelNameVersion(modelName)[0];
    },
    copyModelName(modelName) {
      let formattedModelName = modelName.replace(/\s+/g, "_");
      let url = `${baseURL}/${this.getModelNameOnly(formattedModelName)}`;
      navigator.clipboard.writeText(url);

      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "copied to clipboard",
        life: 3000,
      });
    },
  },
};
</script>

<style scoped>
#details-container {
  margin-top: 110px;
  padding: 0px 25px;
}

#details-container .tab {
  background-color: #13d39e;
  border-radius: 4px;
  border: 1px solid #89e9cf;
  color: #112849;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}

#details-container .tab .label {
  font-size: 14px;
}

#details-container .tab .value {
  font-size: 26px;
}

#details-container .tab .value .red-label {
  font-size: 14px;
  color: #ce1a36;
  margin-left: 0.25rem;
}

#details-container .tab .value .red-label img {
  margin-right: 0.15rem;
  margin-top: -1px;
}

#content-container .top-container .filter-tab[data-v-71fd258a] {
  background-color: #89e9cf;
  color: #13d39e;
  border-radius: 6px;
  font-size: 14px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
}

.left-border {
  border-left: #89e9cf 3px solid;
}

#insights-container {
  padding: 0px 40px 0px 25px;
}

#insights-container .tab {
  background-color: #e7fbf5;
  border-radius: 4px;
  border: 1px solid #89e9cf;
  color: #112849;
  text-align: left;
  padding: 15px;
  cursor: pointer;
}

#insights-container .tab .label {
  font-size: 14px;
}

#insights-container .tab .value {
  font-size: 26px;
}

.status-tag {
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px;
  border-radius: 16px;
  text-transform: capitalize;
}

.deploying {
  background-color: #f0f6ff;
  border: 1px solid #bfdbfe;
  color: #2463eb;
}

.deployed {
  background-color: #e7fbf5;
  border: 1px solid #5ae0bb;
  color: #0fa97e;
}

.conflict {
  background-color: #fff8f0;
  border: 1px solid #fee1bf;
  color: #c27113;
}

.pending {
  background-color: #fff8f0;
  border: 1px solid #fee1bf;
  color: #c27113;
}

.deactivated {
  background-color: #fff0f0;
  border: 1px solid #febfbf;
  color: #eb2424;
}

.failed {
  background-color: #fff0f0;
  border: 1px solid #febfbf;
  color: #eb2424;
}

.optimized {
  background-color: #f5f0ff;
  border: 1px solid #d6bffe;
  color: #470fa9;
}

.optimizing {
  background-color: #f0f6ff;
  border: 1px solid #bfdbfe;
  color: #2463eb;
}

.model-name {
  color: #083e47;
  font-size: 16px;
  font-weight: 500;
}

.model-button {
  background-color: #13d39e !important;
  color: #021214 !important;
  border: none !important;
  width: 72px !important;
  height: 48px !important;
}

.optimize-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #6459a7;
  color: #ffffff;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
}

.optimization {
  background-color: #f5f0ff;
  border: 1px solid #d6bffe;
  color: #470fa9;
}

.grid-auto-fir-minmax {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}
</style>
