<template>
  <div id="initial-response-container">
    <div class="title mb-3">Initial Response</div>
    <div
      v-if="options && series && series.length"
      class="block d-flex align-items-center"
    >
      <apex-chart
        height="185"
        type="donut"
        :options="options"
        :series="series[0].data"
      />
      <div class="sub-title">Period 1</div>
    </div>
    <div
      v-if="options && series && series.length"
      class="block d-flex align-items-center"
    >
      <apex-chart
        height="185"
        type="donut"
        :options="options"
        :series="series[1].data"
      />
      <div class="sub-title">Period 2</div>
    </div>
    <div
      class="mt-4 d-flex align-items-center"
      v-if="options && options.labels && options.labels.length"
    >
      <div
        v-for="(label, index) in options.labels"
        :key="index"
        style="margin-right: 1rem"
        class="d-flex align-items-center"
      >
        <div
          class="legend-marker"
          :style="`background-color: ${colors[index]}`"
        ></div>
        <div class="legend-text">{{ label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Initial-Response",
  props: ["lookup", "data"],
  data() {
    return {
      options: null,
      series: [],
      colors: ["#124BD8", "#89A5EC", "#112849"],
    };
  },
  methods: {
    setData() {
      let temp = [];

      for (let i = 0; i < this.lookup.length; i++) {
        const key = this.lookup[i],
          period1_rec = this.data.period1.find((each) => each.key === key),
          period2_rec = this.data.period2.find((each) => each.key === key);

        temp.push({
          label:
            key === "open"
              ? "Open"
              : key === "false"
              ? "Met"
              : key === "true"
              ? "Exceeded"
              : "",
          period1: (period1_rec && period1_rec.value) || 0,
          period2: (period2_rec && period2_rec.value) || 0,
        });
      }

      if (temp.length) {
        this.options = {
          legend: {
            show: false,
          },
          colors: this.colors,
          labels: temp.map((each) => each.label),
          plotOptions: {
            pie: {
              donut: {
                size: "50%",
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: () => {
              return "";
            },
          },
        };

        this.series = [
          {
            name: "Period 1",
            data: temp.map((each) => each.period1),
          },
          {
            name: "Period 2",
            data: temp.map((each) => each.period2),
          },
        ];
      }
    },
  },
  mounted() {
    this.setData();
  },
};
</script>

<style scoped>
#initial-response-container {
  background-color: #0B3133;
  border-radius: 4px;
  border: 1px solid #ebe9e1;
  padding: 20px 25px;
}
#initial-response-container .title {
  color: #112849;
  font-size: 18px;

}
#initial-response-container .sub-title {
  margin-left: 1rem;
  color: #112849;
  font-size: 14px;

}
#initial-response-container .block {
  border-bottom: 1px solid #ebe9e1;
  padding: 10px 0px;
}
#initial-response-container .legend-marker {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 0.5rem;
}
#initial-response-container .legend-text {
  color: #7e8282;
  font-size: 14px;

}
</style>
