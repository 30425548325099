import jwt_decode from "jwt-decode";

import StorageService from "./storage.service";

class JWTService extends StorageService {
  static get access_token_key() {
    return "access_token";
  }

  static getToken() {
    return sessionStorage.getItem(this.access_token_key);
  }

  static setToken(token) {
    sessionStorage.setItem(this.access_token_key, token);
  }

  static removeToken() {
    sessionStorage.removeItem(this.access_token_key);
  }

  static get refresh_token_key() {
    return "refresh_token";
  }

  static getRefreshToken() {
    return sessionStorage.getItem(this.refresh_token_key);
  }

  static setRefreshToken(token) {
    sessionStorage.setItem(this.refresh_token_key, token);
  }

  static removeRefreshToken() {
    sessionStorage.removeItem(this.refresh_token_key);
  }

  static get id_token_key() {
    return "id_token";
  }

  static getIdToken() {
    return sessionStorage.getItem(this.id_token_key);
  }

  static setIdToken(token) {
    sessionStorage.setItem(this.id_token_key, token);
  }

  static removeIdToken() {
    sessionStorage.removeItem(this.id_token_key);
  }

  static get code_verifier_key() {
    return "code_verifier";
  }

  static getCodeVerifier() {
    return sessionStorage.getItem(this.code_verifier_key);
  }

  static setCodeVerifier(token) {
    sessionStorage.setItem(this.code_verifier_key, token);
  }

  static removeCodeVerifier() {
    sessionStorage.removeItem(this.code_verifier_key);
  }

  static get code_challenge_key() {
    return "code_challenge";
  }

  static getCodeChallenge() {
    return sessionStorage.getItem(this.code_challenge_key);
  }

  static setCodeChallenge(token) {
    sessionStorage.setItem(this.code_challenge_key, token);
  }

  static removeCodeChallenge() {
    sessionStorage.removeItem(this.code_challenge_key);
  }

  static isExpired() {
    let token, payload;

    token = this.getToken();

    if (!token) return true;

    payload = jwt_decode(token);

    return new Date().getTime() >= new Date(payload.exp * 1000);
  }
}

export default JWTService;
