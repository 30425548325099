<template>
  <div>

    <PageHeader
        title="Harbor Dashboard"
        href="https://harbor.staging.omniops.me/c/oidc/login?redirect_url=/harbor/projects"
        is-button-new-tab="true"
        button-label="go to Harbor"/>



    <div class="margin-under-header">
 <common-loading v-if="loading"/>
      <div class="center-div" id="no-projects" v-if="!dashboardData">
        <h1>No projects
          <b-icon-archive></b-icon-archive>
        </h1>
      </div>
      <b-card-group deck v-else>
        <div class="d-flex flex-wrap dashboard-cards mx-5">
          <b-card
              :title=item.project_name.toUpperCase()
              style="width: 588px;height: 246px; max-width: 48%; min-width: 460px;border: none"
              header-tag="header"
              footer-tag="footer"
              class="dashboard-card-custom"
              v-for="(item, index) in dashboardData" :key="index"

          >
            <b-button
                id="harbor-link"
                :href="item['harbor_link']" target="_blank">
              <img src="@/assets/images/box-arrow-top-right-icon.svg"/>
            </b-button>
            <br>

            <b-card-text>
              <div class="row">
                <div class="col-3">
                  <button class="btn copy-btn" @click="copyText(item['tag'])">
                    <img src="@/assets/images/copy_icon.svg"/>
                    <span style="font-size: 14px;padding-left: 10px">Copy Tag</span>
                  </button>
                </div>
                <div class="col-7">
                  <button class="btn copy-btn" @click="copyText(item['image'])">
                    <img src="@/assets/images/copy_icon.svg"/>
                    <span style="font-size: 14px;padding-left: 10px">Copy Push</span>
                  </button>
                </div>
              </div>


              <div class="row">
                <div class="col-6 vertical-border">
                  <div class="border-1">
                    <span style="font-size: 10px;opacity: 0.5">Modified: </span>
                    <span style="font-size: 10px">{{ item['project_stats']['updated_at'] }}</span>
                  </div>
                </div>
                <div class="col-6">
                  <span style="font-size: 10px;opacity: 0.5">Created: </span>
                  <span style="font-size: 10px;">{{ item['project_stats']['created_at'] }}</span>
                </div>
              </div>
            </b-card-text>
            <template #footer>
              <div class="footer-div">
                <div class="row text-center footer-labels">
                  <div class="col-3 vertical-border">
                    <img src="@/assets/images/server-icon.svg"/>
                    <span class="ms-2">Storage</span>
                  </div>
                  <div class="col-3 vertical-border">
                    <img src="@/assets/images/pull-request-icon.svg"/>
                    <span class="ms-2">Pulls</span>
                  </div>
                  <div class="col-3 vertical-border">
                    <img src="@/assets/images/folded-book-icon.svg"/>
                    <span class="ms-2">Repos</span>
                  </div>
                  <div class="col-3">
                    <img src="@/assets/images/people-icon.svg"/>
                    <span class="ms-2">Members</span>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-3 vertical-border">
                    {{ item['summary_data']['storage_used'] }}
                  </div>
                  <div class="col-3 vertical-border">
                    {{ item['project_stats']['pull_count'] }}
                  </div>
                  <div class="col-3 vertical-border">
                    {{ item['summary_data']['repos'] }}
                  </div>
                  <div class="col-3">
                    {{ item['summary_data']['members'] }}
                  </div>
                </div>
              </div>
            </template>
          </b-card>
        </div>
      </b-card-group>
    </div>
  </div>
</template>

<script>

import HarborService from "@/services/harbor.service";
import PageHeader from "@/components/ui/PageHeader.vue";

export default {
  name: "Harbor-dashboard",

  components: {PageHeader},
  data() {
    return {
      error: null,
      loading: false,
      dashboardData: [],
    };
  },
  mounted() {
    this.loading = true;
    this.error = null;

    HarborService.GetUserInfo(null)
        .then(({data}) => {
          this.dashboardData = data
        })
        .catch(({message}) => {
          this.error = message
              ? message
              : "There was an error in loading lookups.";
        })
        .finally(() => {
          this.loading = false;
        });
  },
  methods: {
    copyText(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy text: ', err);
      } finally {
        document.body.removeChild(textArea);
      }
    },
  },


};
</script>

<style>
#registration-container {

}

#registration-container .main-title {
  padding: 20px 25px;
  font-size: 22px;

  text-align: center;
}

#registration-container .content-block {
  border-radius: 4px;
  background-color: #0B3133;
  border: 1px solid #ebe9e1;
  padding: 20px 25px;
  margin: 0px 25px;
}

.dashboard-cards {
  gap: 36px;
  padding-left: 30px;
  padding-bottom: 50px;
}

#no-projects {
  color: #a3a4a2;
}

.card-footer {
  background-color: #E7FBF5 !important;
}

.card-title {
  font-size: 16px;
}

.dashboard-card-custom {
  flex-grow: 1;
  border-radius: 4px;
  opacity: 1;
  width: 588px;
  height: 246px;
  max-width: 48%;
  min-width: 460px;
  border: none
}

.copy-btn {
  top: 179px;
  left: 125px;
  width: 152px;
  height: 41px;
  opacity: 1;
  border: none;
  color: #13D39E !important;
}

.footer-labels {
  font-size: 12px;
  color: #7e8282;
}

.vertical-border {
  border-right: 1px solid rgba(126, 130, 130, 0.5);
}

#new-project {
  background-color: #13D39E;
  color: #0B3133
}

#new-project:hover {
  background-color: #13D39E;
}

.footer-div {
  margin: auto;
  padding: 10px;
}

#harbor-link {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 58px;
  height: 38px;
  background-color: #13D39E;
  border: none
}

#harbor-link:hover {
  background-color: #13D39E;

}

.center-div {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>

