<template>
  <div id="single-user-container">
    <div class="d-flex align-items-center justify-content-between">
      <div style="flex: 0.7" class="d-flex align-items-center">
        <div class="avtar d-flex align-items-center justify-content-center">
          EC
        </div>
        <div>
          <div class="user-name">Ethan Corwin</div>
          <div class="user-email">Jettie92@yahoo.com</div>
        </div>
      </div>
      <div style="flex: 0.3" class="d-flex align-items-center">
        <base-button style_variant="teritary">Resend invite</base-button>
        <base-button style_variant="teritary">Revoke access</base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "single-user",
};
</script>

<style scoped>
#single-user-container {
  border-bottom: 1px solid #ebe9e1;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
}
#single-user-container .avtar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #7968d3;
  color: #ffffff;
  font-size: 20px;
  margin-right: 0.5rem;
}
#single-user-container .user-name {
  color: #112849;
  font-size: 16px;

}
#single-user-container .user-email {
  color: #7e8282;
  font-size: 14px;

}
</style>
