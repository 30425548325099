<template>
  <div id="cloud-setting-detail-container">
    <b-row>
      <b-col md="12">
        <div
          class="top-container d-flex align-items-center justify-content-between"
        >
          <div class="platform">
            <div class="label">{{ platform[0] }}</div>
            <div class="accounts">{{ platform[1].length }} accounts</div>
          </div>
          <div>
            <base-button style_variant="primary" @onClick="addAccount">
              Add New Account
            </base-button>
          </div>
        </div>
      </b-col>
      <b-col md="7" v-if="platform[1].length">
        <div
          v-for="(each, index) in platform[1]"
          :key="index"
          class="account-info d-flex align-items-center justify-content-between"
        >
          <div>
            <div class="account-name">{{ each.name }}</div>
            <div>
              <span class="account-tab">
                <img src="/images/check.png" alt="check" />
                <span> Connected </span>
              </span>
            </div>
          </div>
          <div class="edit-link" @click="editAccount(each)">Edit</div>
        </div>
      </b-col>
      <b-col v-else md="12">
        <div
          class="no-account-container d-flex align-items-center justify-content-center"
        >
          <div>
            <div class="d-flex align-items-center justify-content-center mb-3">
              <div class="logo"></div>
            </div>
            <div class="title">No Connected Accounts</div>
            <div class="mb-3 sub-title">
              You haven’t connected any accounts yet.
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <AddAccountModal
      :mode="mode"
      :edit_data="edit_data"
      :updatedTime="updatedTime"
    />
  </div>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import AddAccountModal from "./AccountModal.vue";

export default {
  components: { BaseButton, AddAccountModal },
  name: "cloud-setting-detail",
  props: ["platform"],
  data() {
    return { mode: "add", edit_data: null, updatedTime: null };
  },
  methods: {
    addAccount() {
      this.mode = "add";
      this.edit_data = null;
      this.openModal();
    },
    editAccount(data) {
      this.mode = "edit";
      this.edit_data = data;
      this.updatedTime = new Date().getTime();
      this.openModal();
    },
    openModal() {
      this.$bvModal.show("add-account-modal");
    },
  },
};
</script>

<style scoped>
.top-container {
  padding: 1rem;
  border-bottom: 1px solid #eceae2;
}
.platform .label {
  color: #112849;
  font-size: 16px;

}
.platform .accounts {
  color: #7e8282;

}
.account-info {
  padding: 1rem;
  border-bottom: 1px solid #eceae2;
}
.account-name {
  color: #112849;

}
.edit-link {
  cursor: pointer;
  text-decoration: underline;
  color: #a3a4a2;

}
.account-tab {
  font-size: 13px;
  color: #0d9943;
  background-color: #e8f3eb;
  border-radius: 4px;
  border: 1px solid #c8e7d3;
  padding: 0rem 0.25rem;
}
.no-account-container {
  height: 400px;
  text-align: center;
}
.logo {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background-color: #e7edfb;
}
.title {
  color: #112849;
  font-size: 22px;

}
.sub-title {
  color: #5a6062;
  font-size: 14px;

}
</style>
