<template>
  <div id="map-container">
    <common-loading v-if="loading"/>
    <div v-else-if="error && error.length">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
    </div>
    <div
        v-else-if="
        services_chart &&
        services_chart.length &&
        total_chart &&
        total_chart.length
      "
    >
      <div
          class="top-container d-flex align-items-center justify-content-between"
      >
        <div style="flex: 1 auto">
          <div class="d-flex align-items-center">
            <div style="position: relative">
              <div
                  class="filter-tab"
                  style="margin-right: 0.5rem"
                  @click.stop="openYearMenu"
              >
                2022 (this year)
              </div>
              <YearMenu
                  :open_menu="open_year_menu"
                  @close-menu="openYearMenu"
                  @change-filter="changeFilter"
              />
            </div>
            <div style="position: relative">
              <div class="filter-tab" @click.stop="openMonthMenu">
                August (this month)
              </div>
              <MonthMenu
                  :open_menu="open_month_menu"
                  @close-menu="openMonthMenu"
                  @change-filter="changeFilter"
              />
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div
              :class="['map-filter', { active: tab === 1 ? true : false }]"
              style="border-radius: 6px 0px 0px 6px"
              @click.stop="selectTab(1)"
          >
            By Service
          </div>
          <div
              :class="['map-filter', { active: tab === 2 ? true : false }]"
              style="border-radius: 0px 6px 6px 0px"
              @click.stop="selectTab(2)"
          >
            Total
          </div>
        </div>
      </div>
      <div>
        <apex-chart
            height="400"
            type="line"
            :options="getOptions(tab)"
            :series="getSeries(tab)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import YearMenu from "./YearMenu.vue";
import MonthMenu from "./MonthMenu.vue";
import CostService from "../../../../services/cost.service";

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

function sort_by_key(a, b) {
  if (a["year"] < b["year"]) {
    return -1;
  } else if (a["year"] === b["year"]) {
    if (a["month"] < b["month"]) {
      return -1;
    }
    if (a["month"] > b["month"]) {
      return 1;
    }
  }
  if (a["year"] > b["year"]){
    return 1;
  }
  return 0;
}

// const month = [
//   "Jan",
//   "Feb",
//   "Mar",
//   "Apr",
//   "May",
//   "Jun",
//   "Jul",
//   "Aug",
//   "Sep",
//   "Oct",
//   "Nov",
//   "Dec",
// ];

export default {
  name: "map-page",
  components: {YearMenu, MonthMenu},
  data() {
    return {
      tab: 1,
      open_year_menu: false,
      open_month_menu: false,
      loading: false,
      error: null,
      services_chart: null,
      total_chart: null,
    };
  },
  methods: {
    selectTab(index) {
      this.tab = index;
    },
    getOptions(index) {
      return {
        legend: {
          show: true,
          showForSingleSeries: true,
          horizontalAlign: "left",
        },
        // labels: month,
        xaxis: {
          type: "Service",
          // labels: {
          //   formatter: function (val) {
          //     return month[val - 1];
          //   },
          // },
        },
        yaxis: {
          type: "Cost",
          labels: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
        },
        forecastDataPoints: {
          // count: 5,
        },
        stroke: {
          width: 2,
          curve: "smooth",
        },
        colors:
            index === 2
                ? ["#124BD8"]
                : [
                  "#8BCDEA",
                  "#124BD8",
                  "#CE1A36",
                  "#DE6918",
                  "#0D9943",
                  "#B76BD3",
                  "#0D3597",
                  "#89A5EC",
                ],
      };
    },
    getSeries(index) {
      let result = null;
      if (index === 1 && this.services_chart && this.services_chart.length) {
        result = this.services_chart.map((each) => {
          return {
            name: each[0],
            data: each[1].map((rec) => {
              return {
                x: rec.month + "/" + rec.year,
                y: rec.cost,
              };
            }),
          };
        });
      } else if (index === 2 && this.total_chart && this.total_chart.length) {
        result = this.total_chart.map((each) => {
          return {
            name: each[0],
            data: each[1].map((rec) => {
              return {
                x: rec.month + "/" + rec.year,
                y: rec.cost,
              };
            }),
          };
        });
      }
      return result;
    },
    openYearMenu() {
      this.open_year_menu = !this.open_year_menu;
      this.open_month_menu = false;
    },
    openMonthMenu() {
      this.open_year_menu = false;
      this.open_month_menu = !this.open_month_menu;
    },
    changeFilter(key, val) {
      console.log(key, val);
    },
    query(params = {}) {
      this.loading = true;
      this.error = null;

      CostService.Chart(params)
        .then(({data}) => {
          this.services_chart = groupBy(data.services_chart, "service");
          this.total_chart = groupBy(data.total_chart, "service");

          this.services_chart = Object.keys(this.services_chart).map((key) => [
            key,
            this.services_chart[key].sort((a, b) => sort_by_key(a, b)),
          ]);
          this.total_chart = Object.keys(this.total_chart).map((key) => [
            key,
            this.total_chart[key].sort((a, b) => sort_by_key(a, b)),
          ]);
        })
        .catch(({message}) => {
          this.error = message
              ? message
              : "There was an error in loading chart.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.query();
  },
};
</script>

<style scoped>
#map-container {
  margin: 1.5rem 0rem;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #ebe9e1;
  padding: 20px;
}

#map-container .top-container {
  padding-bottom: 1rem;
}

#map-container .top-container .filter-tab {
  background-color:#89E9CF;
  color: #112849;
  border-radius: 6px;

  font-size: 14px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
}

#map-container .top-container .map-filter {
  width: 150px;
  text-align: center;
  background-color: #F9F9F9;
  border: 1px solid #ebe9e1;
  color: #112849;

  font-size: 14px;
  padding: 10px;
  cursor: pointer;
}

#map-container .top-container .map-filter.active {
  background-color:#89E9CF;
  border-color: #13D39E;
  color: #0B3133;
}
</style>
