<template>
  <div style="padding: 0px 25px">
    <div
        id="no-account-container"
        class="d-flex align-items-center justify-content-center"
    >
      <div>
        <div class="d-flex align-items-center justify-content-center mb-3">
          <div class="logo"></div>
        </div>
        <div class="title">No Connected Accounts</div>
        <div class="mb-3 sub-title">
          You haven’t connected any accounts to your {{ provider }} yet.
        </div>
        <base-button style_variant="primary" style="background: #13D39E; border: none"
                     @onClick="$router.push('/dashboard/settings/1')">
          Go to Cloud Settings
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "no-account-page",
  // get param from parent
  props: {
    provider: {
      type: String,
      default: "AWS",
    },
  },

};
</script>

<style scoped>
#no-account-container {
  height: 400px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #ebe9e1;
  text-align: center;
}

.logo {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background-color: #e7edfb;
}

.title {
  color: #13D39E;
  font-size: 22px;

}

.sub-title {
  color: #5a6062;
  font-size: 14px;

}
</style>
