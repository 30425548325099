<template>
  <div class="w-100">
    <PageHeader
        title="Admin Settings"
        buttonRouterTo="/user-management/new"
        button-label="Invite User"
    />


    <div class="margin-under-header">
      <common-loading v-if="loading"/>

      <div v-if="!loading" class="d-flex align-items-end">
        <div class="fs-4 fw-bold mx-5 pe-5 mt-0 flex-grow-1">User</div>
        <div class="d-flex flex-grow-2 gap-2">
          <div class="me-5">
            <span class="me-2">
              <PrimeDropdown
                  v-model="filter.module"
                  :options="moduleOptions"
                  optionLabel="label"
                  style="height:40px"
                  @change="setModuleValue"
                  placeholder="Filter by Module"
                />
            </span>
            <span class="me-2">
              <PrimeDropdown
                  v-model="filter.role"
                  :options="roleOptions"
                  optionLabel="label"
                  style="height:40px"
                  @change="setRoleValue"
                  placeholder="Filter by Role"
                />
            </span>
            <span class="p-input-icon-left me-2">
              <i class="pi pi-search"/>
              <InputText
                  type="text"
                  v-model="name"
                  v-on:change="updateUserList()"
                  placeholder="Search users..."
              />
            </span>
          </div>
        </div>
      </div>
      <!--  Cards -->
      <b-card
          v-if="!loading"
          class="ms-5 mt-3 mb-5"
          style="min-height: 500px ;width: 94%; border: solid 1px #EBE9E1; border-radius: 0;"
          header-tag="header"
          footer-tag="footer"
      >
        <div class="flex mb-2">

          <PrimeButton
              v-on:click="setFilter('all')"
              :class="{'me-2': true, 'secondary': filter.cat === 'all', 'not-active': filter.cat !== 'all'}"
              style="height:40px; border-radius:0;">All Users
          </PrimeButton>
          <PrimeButton
              v-on:click="setFilter('new')"
              :class="{'me-2': true, 'secondary': filter.cat === 'new', 'not-active': filter.cat !== 'new'}"
              style="height:40px; border-radius:0;">
            <div class="flex">
              <div class="dot red"></div>
              New
              <div class="ms-1 dot inline-dot red text-white">{{ newUsersCount }}</div>
            </div>
          </PrimeButton>
          <PrimeButton
              v-on:click="setFilter('active')"
              :class="{'me-2': true, 'secondary': filter.cat === 'active', 'not-active': filter.cat !== 'active'}"
              style="height:40px; border-radius:0;">
            <div class="flex">
              <div class="dot primary"></div>
              Active
            </div>
          </PrimeButton>
          <PrimeButton
              v-on:click="setFilter('deactivated')"
              :class="{'me-2': true, 'secondary': filter.cat === 'deactivated', 'not-active': filter.cat !== 'deactivated'}"
              style="height:40px; border-radius:0;">
            <div class="flex">
              <div class="dot deactivated"></div>
              Deactivate
            </div>
          </PrimeButton>
        </div>

        <b-table
            label-sort-asc=""
            label-sort-desc=""
            label-sort-clear=""
            :items="users"
            :fields="fields"
            sort-desc=""
        >
          <template #cell(enabled)="data">
            <InputSwitch v-if="data.item.enabled" :value="data.item.enabled"
                         v-on:click="showDeactivateModal(data.item.id)"/>
            <InputSwitch v-else :value="data.item.enabled" v-on:click="toggleActive(data.item.id, data.item.enabled)"/>
          </template>
          <template #cell(name)="data">
            <div style="max-width: 300px">
              <div class="dot"
                   :class="{'red':data.item.isNew, 'primary': !data.item.isNew && data.item.enabled, 'deactivated': !data.item.enabled}"></div>
              <div class="text-color d-inline">{{ data.item.firstName }} {{ data.item.lastName }} {{
                  data.item.id == currentUser.id ? '(You)' : ''
                }}
              </div>
            </div>

            <!--            <div style="max-width: 400px">-->
            <!--              <div class="lite-text-color d-inline me-1">{{ data.item.id }}</div>-->
            <!--              <img :id=" `copy-icon-${data.item.id}`" class='d-inline copy-icon' src="@/assets/images/copy.svg"-->
            <!--                   :onclick="`navigator.clipboard.writeText('${data.item.id}')` "/>-->
            <!--              <b-popover :target="`copy-icon-${data.item.id}`" content="Copied" triggers="click blur">-->
            <!--                Copied-->
            <!--              </b-popover>-->
            <!--            </div>-->
          </template>
          <template #cell(email)="data">
            <div class="lite-text-color">{{ data.item.email }}</div>
          </template>
          <template #cell(role)="data">
            <base-select
                v-if="data.item.id==userEdit?.id"
                :options="roleOptions.slice(1)"
                class="mb-0"
                field="role"
                :value="userEditRole"
                @change-value="setEditRole"
                cus_style="height:43px"
            ></base-select>
            <div v-else>{{ data.item.roles[0] }}</div>

          </template>
          <template #cell(access)="data">
            <div v-if="data.item?.attributes?.modules">
              <b-badge v-for="item in data.item.attributes.modules.slice(0, 5)"
                      :key="item" class="gray-badge me-2 d-inline"
              >{{ item }}
              </b-badge>
              <i v-if="data.item.attributes.modules.length > 5" 
                class="pi pi-ellipsis-h" 
                v-b-tooltip.hover 
                :title="data.item.attributes.modules.slice(5).join(', ')"/>
            </div>
            <div v-else>-</div>
          </template>
          <template #cell(actions)="data">
            <div v-if='data.item.id !==userEdit?.id'>
              <!-- <span v-on:click="initiateEditUser(data.item)" class="me-2"><img class="w-27px"
                                                                               src="@/assets/images/edit-circle.svg"/></span> -->
              <router-link
                  :to="'/user-management/'+data.item.id"
              >
                <span><img class="w-31px" src="@/assets/images/arrow-right.svg"/></span>
              </router-link>
            </div>
            <div v-else>
              <span class="me-2 "><img
                  v-on:click="updateUserRole()"
                  class="w-31px" src="@/assets/images/check.svg"/></span>
              <span v-on:click="userEdit=null;"><img class="w-27px" src="@/assets/images/x.svg"/></span>
            </div>
          </template>

        </b-table>
      </b-card>
    </div>
    <b-modal id="deactivate-modal" size="md" centered hide-header hide-footer>
      <div class="d-flex flex-column justify-content-center p-4">
        <div class="m-auto mb-3">
          <h5>Do you confirm user deactivation?</h5>

        </div>
        <div class="row">
          <div class="col-6">
            <button class="m-auto user-detail-button d-inline" v-on:click="$bvModal.hide('deactivate-modal')">
              Cancel
            </button>
          </div>
          <div class="col-6">
            <button class="m-auto user-detail-button deactivate-button d-inline" v-on:click="deactivateUser()">
              Deactivate
            </button>
          </div>
        </div>

      </div>
    </b-modal>
    <PrimePaginator
       v-if="users.length && !loading"
      :first.sync="offset"
      :rows.sync="rows"
      :totalRecords="totalRecords"
      @page="onPage($event)"
    ></PrimePaginator>
  </div>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import PortalService from "@/services/portal.service";

export default {
  name: "AdminSettings",

  components: {PageHeader},
  data() {
    return {
      roleOptions: [],
      moduleOptions: [],
      newUsersCount: 0,
      loading: false,
      filter: {
        cat: 'all',
        module: null,
        role: null,
        name: null,
      },
      currentUser: {},
      fields: [
        {key: 'enabled', label: '', sortable: false},
        {key: 'name', sortable: true},
        {key: 'email', sortable: true},
        {key: 'role', sortable: true},
        {key: 'access', sortable: false},
        {key: 'actions', label: "", sortable: false},
      ],
      userEdit: null,
      userEditRole: null,
      users: [],
      deactiavteUserid: null,
      offset: 0,
      rows: 10,
      page: 1,
      totalRecords: 10,
    }
  },
  mounted() {
    this.updateUserList();
    this.getCurrentUser();
    this.getRoleOptions();
    this.getModuleOptions();
  },
  methods: {
    showDeactivateModal(id) {
      this.deactiavteUserid = id;
      this.$bvModal.show('deactivate-modal')
    },
    deactivateUser() {
      this.loading = true;
      PortalService.toggleUserActive(this.deactiavteUserid, false).then(() => {
        this.$bvModal.hide('deactivate-modal')
        this.updateUserList();
      })
    },
    getCurrentUser() {
      PortalService.getCurrentUser().then(({data}) => {
        this.currentUser = data
      })
    },
    getRoleOptions(){
      PortalService.getRoleOptions().then(({data}) => {
        this.roleOptions = [{id: null, label: 'All'}, ...data.map((item) => {
          return {id: item.name.toUpperCase(), label:item.name}
        })]
      })
    },
    getModuleOptions(){
      PortalService.getModuleOptions().then(({data}) => {
        this.moduleOptions = [{id: null, label: 'All'},...data.map((item) => {
          return {id: item, label:item.charAt(0) + item.slice(1).toLowerCase()}
        })]
      })
    },
    updateUserList() {
      this.loading = true;
      this.error = null;
      let param_filter = {};
      let cat = this.filter.cat;
      let role = this.filter.role?.id;
      let module = this.filter.module?.id;
      param_filter['pageIndex'] = this.page;
      param_filter['pageSize'] = this.rows;
      if (name !== null) {
        param_filter['search'] = this.name;
      }
      if (role !== null) {
        param_filter['role'] = role;
      }
      if (module !== null) {
        param_filter['Modules'] = module;
      }
      if (cat === 'new') {
        param_filter['isNewUser'] = true;
      } else if (cat === 'deactivated') {
        param_filter['isActive'] = false;
      } else if (cat === 'active') {
        param_filter['isActive'] = true;
      }
      PortalService.getUsers(param_filter).then(({data}) => {
        this.users = data.result;
        this.loading = false;
        this.newUsersCount = data.newUsers
        this.totalRecords = data.totalRecords < 10 ? 10 : data.totalRecords;
      })
    },
    setFilter(cat) {
      this.filter.cat = cat
      this.updateUserList();
    },
    setRoleValue(role) {
      this.filter.role = role.value
      this.updateUserList();
    },
    setModuleValue(module) {
      this.filter.module = module.value
      this.updateUserList();
    },
    initiateEditUser(user) {
      this.userEdit = user
      this.userEditRole = user.roles[0].toUpperCase();
    },
    setEditRole(value){
      this.userEditRole = value;
    },
    onPage(event) {
      this.page = event.page + 1;
      this.updateUserList();
    },
    async updateUserRole() {
      this.loading = true;
      let userData = {
        "firstName": this.userEdit.firstName,
        "lastName": this.userEdit.lastName,
        "email": this.userEdit.email,
        "role": this.userEditRole,
        "modules": {}
      }

      let modules = [
        "AIRFLOW",
        "MINIO",
        "ARGOCD",
        "HARBOR",
        "GITLAB",
        "TRITON",
        "DASHBOARD"
      ]

      let permissions = {}
      let selectGroups = {
        "AIRFLOW": [],
        "MINIO": [],
        "ARGOCD": [],
        "HARBOR": [],
        "GITLAB": [],
        "TRITON": [],
        "DASHBOARD": []
      }


      for (let [index, module] of modules.entries()) {
        await PortalService.getModuleGroup(module).then(({data}) => {
          if (data.length > 0) {
            permissions[module] = data;
            for (let group of data) {
              if (this.userEdit?.groups?.includes(group.name)) {
                selectGroups[module].push(group)
              }
            }
          }
          if (index === modules.length - 1) {
            if (this.userEditRole === 'ADMIN') {
              for (const [key, value] of Object.entries(permissions)) {
                if (value.length > 0) {
                  userData.modules[key] = [];
                  for (let groupObj of value) {
                    userData.modules[key].push(groupObj.name)
                  }
                }
              }
            } else {
              for (const [key, value] of Object.entries(selectGroups)) {
                if (value.length > 0) {
                  userData.modules[key] = [];
                  for (let groupObj of value) {
                    userData.modules[key].push(groupObj.name)
                  }
                }
              }
            }

            PortalService.updateUser(this.userEdit.id, userData).then(() => {
              this.updateUserList();
              this.userEdit = null;
            });
          }
        })
      }


    },
    toggleActive(id, value) {
      this.loading = true;
      PortalService.toggleUserActive(id, !value).then(() => {
        this.updateUserList();
      })
    }
  },
};
</script>

<style>
.primary, .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #13D39E !important;
}

.secondary {
  background: #00B583 !important;
}

.not-active {
  background: #EBe9e1 !important;
  color: #112849 !important;
  border: 0 !important;
}

.gray-badge {
  background: #EAEAEC !important;
  color: #454158 !important;
}

.dot {
  display: inline-table;
  max-width: 13px !important;
  width: 13px !important;
  height: 13px;
  border-radius: 50%;
  margin-right: 10px;
}

.inline-dot {
  max-width: 23px !important;
  width: 23px !important;
}

.red {
  background-color: #DD3D05;
}

.copy-icon {
  width: 20px !important
}

.deactivated {
  background: #6B7576;
}

.table.b-table > thead > tr > [aria-sort=none], .table.b-table > tfoot > tr > [aria-sort=none], .table.b-table > thead > tr > [aria-sort=descending], .table.b-table > tfoot > tr > [aria-sort=descending], .table.b-table > thead > tr > [aria-sort=ascending], .table.b-table > tfoot > tr > [aria-sort=ascending] {
  background-image: url("/public/images/sort.svg") !important;
  background-size: auto !important;
}

.text-color {
  color: #062D33;
  font-weight: 400;
}

.lite-text-color {
  color: #6B7576;
}

.role-dropdown > button {
  border: 1px solid #EBE9E1;
  background: white;
  color: #062D33;
}

.w-27px {
  width: 27px;
}

.w-31px {
  width: 31px;
}

td {
  vertical-align: middle;
}
</style>
