<template>
  <div id="users-container">
    <div class="main-title">Users</div>
    <div class="content-block mb-4">
      <Filters />
      <div class="mt-5">
        <div v-for="(user, index) in users" :key="index">
          <User />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filters from "./components/filters.vue";
import User from "./components/user.vue";

export default {
  name: "Users-Page",
  components: { Filters, User },
  data() {
    return { users: [1, 2, 3, 4, 5] };
  },
};
</script>

<style>
#users-container {
  height: 100vh;
  overflow: auto;
}
#users-container .main-title {
  padding: 20px 25px;
  font-size: 22px;

  color: #a3a4a2;
}
#users-container .content-block {
  border-radius: 4px;
  background-color: #0B3133;
  border: 1px solid #ebe9e1;
  padding: 20px 25px;
  margin: 0px 25px;
}
</style>
