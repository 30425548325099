<template>
  <common-loading v-if="loading" />
  <div v-else-if="isDetailsPage" @click="optimization" class="optimize-button">
    <img src="@/assets/images/rocket-icon.svg" alt="rocket-icon" />
    <span>Deploy Optimized Version</span>
  </div>
  <div
    v-else
    class="optimization-button d-flex align-items-center gap-1 px-2"
    @click="optimization"
  >
    deploy it
    <img src="@/assets/images/rocket-icon.svg" />
  </div>
</template>

<script>
import TritonInferenceService from "@/services/tritonInference.service";
export default {
  name: "OptimizationButton",
  data() {
    return {
      loading: false,
    };
  },
  props: {
    isDetailsPage: {
      type: Boolean,
      default: false,
    },
    modelId: {
      type: String,
      default: "",
    },
  },
  methods: {
    optimization() {
      this.loading = true;

      TritonInferenceService.optimizeModel(this.modelId)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "Model is optimized successfully",
            life: 3000,
          });
          this.$store.commit("tritonModels/updateModelStatus", {
            modelId: this.modelId,
            status: "deployed",
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error Message",
            detail: error.message || error,
            life: 3000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.optimization-button {
  cursor: pointer;
  background-color: #6459a7;
  color: #ffffff;
  border-radius: 16px;
}
</style>
