import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import tritonModels from "./triton.models";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    tritonModels,
  },
});
