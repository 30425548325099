import Vue from "vue";

const baseURL = process.env.VUE_APP_API_URL;

class CloudConnectService {
  static list(params = {}) {
    const url = `${baseURL}/cloud-connect`;

    return Vue.axios.get(url, { params });
  }

  static CreateAccount(account_type, payload) {
    const url = `${baseURL}/cloud-connect/${account_type}`;

    return Vue.axios.post(url, payload);
  }

  static UpdateAccount(id, payload) {
    const url = `${baseURL}/cloud-connect/${id}`;

    return Vue.axios.put(url, payload);
  }

  static DeleteAccount(id) {
    const url = `${baseURL}/cloud-connect/${id}`;

    return Vue.axios.delete(url);
  }
}

export default CloudConnectService;
