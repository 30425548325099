<template>
  <div id="ticket-container" @mouseenter="mouseenter" @mouseleave="mouseleave">
    <div v-if="card">
      <div v-if="!is_hovered">
        <div style="border-bottom: 1px solid #ebe9e1">
          <div class="topbar d-flex align-items-center justify-content-between">
            <div class="task-type">{{ card.issue_type }}</div>
            <div class="task-number">{{ card.id }}</div>
          </div>
          <div
            :class="[
              'task-stage',
              { critical: card.priority === 'Critical' ? true : false },
              { priority: card.priority === 'Priority' ? true : false },
              { high: card.priority === 'High' ? true : false },
              { medium: card.priority === 'Medium' ? true : false },
              { minor: card.priority === 'Minor' ? true : false },
            ]"
          >
            <img
              src="../../../assets/images/critical-icon.png"
              alt="critical-icon"
              v-if="card.priority === 'Critical'"
            />
            <img
              src="../../../assets/images/high-icon.png"
              alt="high-icon"
              v-if="card.priority === 'High'"
            />
            <img
              src="../../../assets/images/medium-icon.png"
              alt="medium-icon"
              v-if="card.priority === 'Medium'"
            />
            <img
              src="../../../assets/images/minor-icon.png"
              alt="minor-icon"
              v-if="card.priority === 'Minor'"
            />
            <span class="stage-label">{{ card.priority }}</span>
          </div>
        </div>
        <div>
          <div class="client-name">{{ card.project.name }}</div>
          <div class="desc">
            {{ card.summary }}
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <div class="avtar d-flex align-items-center justify-content-center">
            {{ card.assignee.name.slice(0, 1) }}
          </div>
          <div class="assignee-name">{{ card.assignee.name }}</div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex align-items-center" style="margin-bottom: 2rem">
          <div>
            <div>
              <img
                src="../../../assets/images/send-clock-icon.png"
                alt="send-clock-icon"
              />
            </div>
            <div class="date-range">
              {{ card.created | moment("MMM DD(hh:mm A)") }}
            </div>
          </div>
          <div style="margin: 0px 8px">
            <img
              src="../../../assets/images/arrow.svg"
              alt="arrow"
              height="34px"
              width="8px"
            />
          </div>
          <div>
            <div>
              <img
                src="../../../assets/images/send-clock-icon.png"
                alt="send-clock-icon"
              />
            </div>
            <div class="date-range">
              {{ card.last_updated | moment("MMM DD(hh:mm A)") }}
            </div>
          </div>
        </div>
        <div style="margin-bottom: 0.72rem">
          <div class="time-label">Time to first response</div>
          <div class="time-sub-label">within {{ get_diff(card.ttfr) }}</div>
          <div
            :class="[
              'time-block',
              'd-flex',
              'align-items-center',
              { red: card.ttfr_breached === 'true' ? true : false },
              { green: card.ttfr_breached === 'false' ? true : false },
              { blue: card.ttfr_breached === 'open' ? true : false },
            ]"
            v-if="card.ttfr"
          >
            <img
              src="../../../assets/images/bridge.png"
              alt="bridge"
              style="margin-right: 0.5rem; height: 18px; width: 18px"
              v-if="card.ttfr_breached === 'true'"
            />
            <img
              src="../../../assets/images/not_bridge.png"
              alt="not_bridge"
              style="margin-right: 0.5rem; height: 18px; width: 18px"
              v-if="card.ttfr_breached === 'false'"
            />
            <div>
              {{ card.ttfr | moment("MMM DD, YY(hh:mm A)") }}
            </div>
          </div>
          <div class="time-block" v-else>———</div>
        </div>
        <div>
          <div class="time-label">Time to resolution</div>
          <div class="time-sub-label">within {{ get_diff(card.ttr) }}</div>
          <div
            :class="[
              'time-block',
              'd-flex',
              'align-items-center',
              { red: card.ttr_breached === 'true' ? true : false },
              { green: card.ttr_breached === 'false' ? true : false },
              { blue: card.ttr_breached === 'open' ? true : false },
            ]"
            v-if="card.ttr"
          >
            <img
              src="../../../assets/images/bridge.png"
              alt="bridge"
              style="margin-right: 0.5rem; height: 18px; width: 18px"
              v-if="card.ttr_breached === 'true'"
            />
            <img
              src="../../../assets/images/not_bridge.png"
              alt="not_bridge"
              style="margin-right: 0.5rem; height: 18px; width: 18px"
              v-if="card.ttr_breached === 'false'"
            />
            <div>
              {{ card.ttr | moment("MMM DD, YY(hh:mm A)") }}
            </div>
          </div>
          <div class="time-block" v-else>———</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "single-ticket",
  props: ["card"],
  data() {
    return { is_hovered: false };
  },
  methods: {
    mouseenter() {
      this.is_hovered = true;
    },
    mouseleave() {
      this.is_hovered = false;
    },
    get_diff(val) {
      if (val) {
        const now = moment();
        const date = moment(val);
        const duration = moment.duration(now.diff(date));
        const hours = duration.asHours();
        const days = duration.asDays();
        const years = duration.asYears();

        if (years) return `${parseInt(years, 10)} years`;
        if (days) return `${parseInt(days, 10)} days`;
        if (hours) return `${parseInt(hours, 10)} hrs`;
      } else {
        return "----";
      }
    },
  },
};
</script>

<style scoped>
#ticket-container {
  width: 100%;
  border-radius: 8px;
  background-color: #0B3133;
  box-shadow: 2px 2px 10px #121b231a;
  padding: 15px;
  margin-bottom: 1rem;
  cursor: pointer;
}
#ticket-container:hover {
  background-color: #112849;
}
#ticket-container .task-type {
  color: #112849;
  font-size: 13px;

}
#ticket-container .task-number {
  color: #a3a4a2;
  font-size: 11px;
}
#ticket-container .task-stage {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  padding: 1px 8px;
  display: inline-block;
}
#ticket-container .stage-label {
  font-size: 13px;

  margin-left: 0.2rem;
}
#ticket-container .task-stage.critical {
  background-color: #f9efe7;
  border: 1px solid #f7e5d8;
  color: #de6918;
}
#ticket-container .task-stage.priority {
  background-color: #f9efe7;
  border: 1px solid #f7e5d8;
  color: #de6918;
}
#ticket-container .task-stage.high {
  background-color: #F9F9F9;
  border: 1px solid #ebe9e1;
  color: #7e8282;
}
#ticket-container .task-stage.medium {
  background-color: #F9F9F9;
  border: 1px solid #ebe9e1;
  color: #7e8282;
}
#ticket-container .task-stage.minor {
  background-color: #F9F9F9;
  border: 1px solid #ebe9e1;
  color: #7e8282;
}
#ticket-container .client-name {
  color: #7e8282;
  font-size: 13px;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
#ticket-container .desc {
  color: #112849;
  font-size: 16px;

  height: 70px;
  overflow: auto;
}
#ticket-container .desc::-webkit-scrollbar {
  width: 2px;
}
#ticket-container .desc::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#ticket-container .desc::-webkit-scrollbar-thumb {
  background: #112849;
}
#ticket-container .desc::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#ticket-container .avtar {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #7968d3;
  color: #ffffff;
  font-size: 13px;

  margin-right: 0.5rem;
}
#ticket-container .assignee-name {
  color: #7e8282;
  font-size: 13px;

}
#ticket-container .date-range {
  color: #89E9CF;
  font-size: 10px;

}
#ticket-container .time-label {
  font-size: 14px;
  color: #F9F9F9;
}
#ticket-container .time-sub-label {
  color:#89E9CF;
  font-size: 11px;

}
#ticket-container .time-block {
  border-radius: 4px;
  font-size: 13px;
  display: inline-block;
  padding: 2px 5px;
}
#ticket-container .time-block.blue {
  background-color: #0d3597;
  color: #d0dbf7;
}
#ticket-container .time-block.green {
  background-color: #0d9943;
  color: #0B3133;
}
#ticket-container .time-block.red {
  background-color: #ce1a36;
  color: #0B3133;
}
</style>
