<template>
  <b-modal id="invite-user-modal" size="md" centered hide-header hide-footer>
    <b-row>
      <b-col>
        <img
          src="../../../assets/images/close.png"
          alt="close"
          class="close-icon"
          @click.stop="closeModal"
        />
      </b-col>
    </b-row>
    <div style="padding: 0rem 1rem 1.5rem">
      <b-row class="mb-2">
        <b-col><div class="title">Invite users</div></b-col>
      </b-row>

      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>

      <b-row>
        <b-col>
          <base-tags-input
            label="Email address"
            placeholder="Enter email"
            :tags="tags"
            @add-tag="addTag"
            @remove-tag="removeTag"
            :helper_text="'Separate multiple emails using a comma.'"
            helper_text_style="color:#7E8282;"
          />
        </b-col>
      </b-row>

      <b-row class="mt-4 mb-2">
        <b-col>
          <b-form-group>
            <b-form-radio-group
              id="radio-group"
              v-model="selected"
              :options="radio_options"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <base-select
            label="Access"
            :options="options"
            field="access"
            :value="access"
            @change-value="setValue"
          ></base-select>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col
          md="8"
          offset-md="4"
          class="d-flex align-items-center justify-content-end"
        >
          <base-button
            style_variant="teritary"
            cus_style="margin-right:1rem"
            :disabled="loading"
            @onClick="closeModal"
          >
            Cancel
          </base-button>
          <base-button
            style_variant="primary"
            :loading="loading"
            :disabled="loading"
            @onClick="submit"
          >
            Send
          </base-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "InviteUserModal",
  emits: ["refresh-page"],
  data() {
    return {
      error: null,
      loading: false,
      options: [
        { id: null, label: "Select any" },
        { id: 1, label: "Can view" },
        { id: 2, label: "Can edit" },
      ],
      access: null,
      selected: "",
      radio_options: [
        { text: "Agent", value: "Agent" },
        { text: "Customer", value: "Customer" },
      ],
      tags: [],
    };
  },
  methods: {
    addTag(val) {
      this.tags.push(val);
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    setValue(val, field) {
      this[field] = val;
    },
    submit() {
      this.$emit("refresh-page");
      this.closeModal();
    },
    closeModal() {
      this.$bvModal.hide("invite-user-modal");
      this.reset();
    },
    reset() {
      this.error = null;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 3rem 1rem;
}
#invite-user-modal .close-icon {
  float: right;
  cursor: pointer;
}
#invite-user-modal .title {
  font-size: 22px;

  color: #112849;
  margin-bottom: 1rem;
}
#invite-user-modal #radio-group {
  display: flex;
  margin-bottom: 1rem;
}
</style>

<style>
.custom-control-label {
  color: #112849;
  font-size: 14px;

  display: block;
  margin-top: -27px;
  margin-right: 27px;
  margin-left: 27px;
}
</style>
