<template>
  <div id="loading-container" class="p-5">
    <b-spinner></b-spinner>
    <span class="loading-label">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "common-loading",
};
</script>

<style scoped>
#loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

#loading-container .loading-label {
  margin-left: 1rem;
  color: #260b19;
  font-size: 18px;
}
</style>
