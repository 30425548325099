<template>
  <div
    id="menu-container"
    class="menu-list"
    style="left: 0px; top: 45px; width: 250px"
    v-if="open_menu"
    v-click-outside="onClickOutside"
  >
    <div style="margin-bottom: 5px">
      <base-input
        placeholder="Search..."
        cus_style="padding:10px 10px 10px 35px;background-color:#0B3133"
        :remove_default_margin="true"
        field="term"
        :value="term"
        @change-value="setValue"
      >
        <template v-slot:attachment>
          <img
            class="search-icon"
            src="../../../assets/images/search-icon.png"
            alt="search-icon"
          />
        </template>
      </base-input>
    </div>
    <div
      v-for="(menu_item, index) in menu_items.filter((each) =>
        each.name.toLocaleLowerCase().includes(term.toLocaleLowerCase())
      )"
      :class="['menu-item', 'd-flex']"
      :key="index"
      style="padding: 10px 10px 2px 10px"
      @click.stop="select_item(menu_item)"
    >
      <b-check
        :id="`select-${index}`"
        :name="`select-${index}`"
        :checked="
          selected_item.findIndex((rec) => rec.id === menu_item.id) >= 0
        "
      />
      <div style="margin-left: -1.5rem; margin-top: -4px">
        {{ menu_item.name }}
      </div>
    </div>
    <div class="bottom-container d-flex align-items-center justify-content-end">
      <div
        class="link"
        style="color: #124bd8; margin-right: 0.5rem"
        @click.stop="selectAll"
      >
        ALL
      </div>
      <div class="link" style="color: #7e8282" @click.stop="selectNone">
        NONE
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-7",
  props: ["open_menu", "menu_items"],
  emits: ["change-filter", "close-menu"],
  data() {
    return {
      term: "",
      selected_item: [],
    };
  },
  methods: {
    select_item(menu_item) {
      const index = this.selected_item.findIndex(
        (rec) => rec.id === menu_item.id
      );
      if (index >= 0)
        this.selected_item = this.selected_item.filter(
          (each) => each.id !== menu_item.id
        );
      else this.selected_item.push(menu_item);
    },
    selectAll() {
      this.selected_item = this.menu_items.filter((each) =>
        each.name.toLocaleLowerCase().includes(this.term.toLocaleLowerCase())
      );
    },
    selectNone() {
      this.selected_item = [];
    },
    setValue(val, field) {
      this[field] = val;
    },
    onClickOutside() {
      this.$emit("close-menu");
    },
  },
  watch: {
    selected_item(val) {
      this.$emit("change-filter", "assignee", val);
    },
  },
};
</script>

<style scoped>
.bottom-container {
  padding: 10px 0px 0px 0px;
  border-top: 1px solid #ebe9e1;
  font-size: 12px;

}
.link {
  cursor: pointer;
}
.search-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
