<template>
  <apex-chart height="400" type="line" :options="currentGraphOptions" :series="series1"></apex-chart>
</template>
<script>
export default {
  name: 'DynamicGraph',
  props: {
    currentGraphOptions: {}
  }
}
</script>
<style scoped>

#registration-container .tab .value .red-label img {
  margin-right: 0.15rem;
  margin-top: -1px;
}

</style>