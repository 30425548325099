<template>
  <div class="input-root" :style="getRootStyle">
    <div class="form-label labelText" v-if="label">
      {{ label }}
    </div>
    <select
      :class="[
        'form-select',
        'form-control',
        { invalidInput: is_invalid ? true : false },
      ]"
      :label="label"
      :placeholder="placeholder"
      v-model.trim="inputfield"
      :style="cus_style"
      :readonly="readOnly"
    >
      <option v-for="option in options" :key="option.id" :value="option.id">
        {{ option.label }}
      </option>
    </select>
    <div class="helper_text" :style="helper_text_style" v-if="helper_text">
      {{ helper_text }}
    </div>
  </div>
</template>

<script>
export default {
  emits: ["change-value"],
  props: [
    "options",
    "label",
    "placeholder",
    "is_invalid",
    "helper_text",
    "helper_text_style",
    "field",
    "value",
    "cus_style",
    "remove_default_margin",
    "readOnly",
  ],
  data() {
    return { inputfield: this.value };
  },
  watch: {
    value: function (val) {
      this.inputfield = val;
    },
    inputfield() {
      this.$emit("change-value", this.inputfield, this.field);
    },
  },
  computed: {
    getRootStyle() {
      return `margin-bottom: ${this.remove_default_margin ? "0px" : "20px"};`;
    },
  },
};
</script>

<style>
.labelText {
  font-size: 16px;

  color: #112849;
  margin-bottom: 5px;
}
.helper_text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.invalidInput {
  border-color: red !important;
  background-color: white !important;
}
.form-control {
  padding: 10px;
  font-size: 14px;

  border-radius: 6px;
  color: #7e8282;
  background-color: #F9F9F9;
  border: 1px solid #ebe9e1;
  width: -webkit-fill-available;
}
.form-control:focus-visible {
  border-color: #13D39E;
  background-color: white;
  color: #112849;
  outline: none;
  box-shadow: none;
}
.form-control:hover {
  border-color:#13D39E;
  background-color: white;
  color: #112849;
  outline: none;
  box-shadow: none;
}
.form-control:focus {
  border-color: #13D39E;
  background-color: white;
  color: #112849;
  outline: none;
  box-shadow: none;
}
.form-control[readonly] {
  background-color: #f5f3ef;
  border-color: #ebe9e5;
}
select option {
  border-radius: 4px !important;
  font-size: 14px !important;
  color: #112849 !important;
  padding: 10px !important;
  background-color: #ffffff !important;
}
select option:hover {
  background-color: #F9F9F9 !important;
}
select option:checked {
  background-color: #e7edfb !important;
  color: #124bd8 !important;
}
select option:focus {
  background-color: #e7edfb !important;
  color: #124bd8 !important;
}
</style>
