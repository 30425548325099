<template>
  <div id="ticket-list-container">
    <div v-if="cards" class="block-list d-flex align-item-center">
      <div class="block">
        <div class="title">
          New ({{ cards.filter((each) => each.status === "New").length }})
        </div>
        <div class="list">
          <Ticket
            v-for="(card, index) in cards.filter(
              (each) => each.status === 'New'
            )"
            :key="index"
            :card="card"
          />
        </div>
      </div>
      <div class="block">
        <div class="title">
          Pending ({{
            cards.filter((each) => each.status === "Pending").length
          }})
        </div>
        <div class="list">
          <Ticket
            v-for="(card, index) in cards.filter(
              (each) => each.status === 'Pending'
            )"
            :key="index"
            :card="card"
          />
        </div>
      </div>
      <div class="block">
        <div class="title">
          Waiting on Customer ({{
            cards.filter((each) => each.status === "Waiting on Customer")
              .length
          }})
        </div>
        <div class="list">
          <Ticket
            v-for="(card, index) in cards.filter(
              (each) => each.status === 'Waiting on Customer'
            )"
            :key="index"
            :card="card"
          />
        </div>
      </div>
      <div class="block">
        <div class="title">
          Third party ({{
            cards.filter((each) => each.status === "Third party").length
          }})
        </div>
        <div class="list">
          <Ticket
            v-for="(card, index) in cards.filter(
              (each) => each.status === 'Third party'
            )"
            :key="index"
            :card="card"
          />
        </div>
      </div>
      <div class="block">
        <div class="title">
          Reported to engineering team ({{
            cards.filter(
              (each) => each.status === "Reported to engineering team"
            ).length
          }})
        </div>
        <div class="list">
          <Ticket
            v-for="(card, index) in cards.filter(
              (each) => each.status === 'Reported to engineering team'
            )"
            :key="index"
            :card="card"
          />
        </div>
      </div>
      <div class="block">
        <div class="title">
          In-Progress ({{
            cards.filter((each) => each.status === "In-Progress").length
          }})
        </div>
        <div class="list">
          <Ticket
            v-for="(card, index) in cards.filter(
              (each) => each.status === 'In-Progress'
            )"
            :key="index"
            :card="card"
          />
        </div>
      </div>
      <div class="block">
        <div class="title">
          Resolved ({{
            cards.filter((each) => each.status === "Resolved").length
          }})
        </div>
        <div class="list">
          <Ticket
            v-for="(card, index) in cards.filter(
              (each) => each.status === 'Resolved'
            )"
            :key="index"
            :card="card"
          />
        </div>
      </div>
      <div class="block">
        <div class="title">
          Canceled ({{
            cards.filter((each) => each.status === "Canceled").length
          }})
        </div>
        <div class="list">
          <Ticket
            v-for="(card, index) in cards.filter(
              (each) => each.status === 'Canceled'
            )"
            :key="index"
            :card="card"
          />
        </div>
      </div>
      <div class="block">
        <div class="title">
          Closed ({{ cards.filter((each) => each.status === "Closed").length }})
        </div>
        <div class="list">
          <Ticket
            v-for="(card, index) in cards.filter(
              (each) => each.status === 'Closed'
            )"
            :key="index"
            :card="card"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ticket from "./ticket.vue";
export default {
  name: "ticker-list",
  props: ["cards"],
  components: { Ticket },
};
</script>

<style scoped>
#ticket-list-container .block-list {
  min-height: 500px;
  width: 100%;
  overflow: auto;
}
#ticket-list-container .block-list::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
#ticket-list-container .block-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#ticket-list-container .block-list::-webkit-scrollbar-thumb {
  background: #111111;
}
#ticket-list-container .block-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#ticket-list-container .block {
  flex: 0.2;
  background-color: #F9F9F9;
  padding: 15px;
  min-width: 270px;
}
#ticket-list-container .block:not(:last-child) {
  border-right: 1px solid #d4d2cb;
}
#ticket-list-container .title {
  padding: 5px 10px 20px 10px;
  color: #7e8282;
  font-size: 16px;

}
</style>
