<template>
  <div id="AWS-container">
    <common-loading v-if="loading"/>
    <div v-else-if="error && error.length">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
    </div>
    <div v-else-if="data && data.average_monthly_cost !== undefined">
      <div v-if="data">
        <b-row class="tabs" style="padding: 0rem 0.5rem">
          <b-col style="padding: 0rem 0.25rem">
            <div class="tab">
              <div class="label">Average Monthly Charges</div>
              <div class="value">
                <span v-html="$amount(data.average_monthly_cost)"></span>
              </div>
            </div>
          </b-col>
          <b-col style="padding: 0rem 0.25rem">
            <div class="tab">
              <div class="label">Charges This Month</div>
              <div class="value d-flex align-items-center justify-content-center">
                <span v-html="$amount(data.this_month)"></span>
                <div
                    class="red-label d-flex align-items-center justify-content-center"
                >
                  <img
                      src="../../../assets/images/red_up_arrow_circle.png"
                      alt="red_up_arrow_circle"
                  />
                  {{ data.this_month_percentage.toFixed(2) }}%
                </div>
              </div>
            </div>
          </b-col>
          <b-col style="padding: 0rem 0.25rem">
            <div class="tab">
              <div class="label">Charges Last Month</div>
              <div class="value d-flex align-items-center justify-content-center">
                <span v-html="$amount(data.last_month)"></span>
                <div
                    class="red-label d-flex align-items-center justify-content-center"
                >
                  <img
                      src="../../../assets/images/red_up_arrow_circle.png"
                      alt="red_up_arrow_circle"
                  />
                  {{ data.last_month_percentage.toFixed(2) }}%
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <BillingContent/>
      <BillingMap/>
    </div>

    <NoAccounts v-else/>
  </div>
</template>

<script>
import BillingMap from "./components/Map.vue";
import BillingContent from "./components/Content.vue";
import CostService from "../../../services/cost.service";
import NoAccounts from "@/pages/Billing/components/NoAccounts.vue";

export default {
  name: "AWS-page",
  components: {NoAccounts, BillingMap, BillingContent},
  data() {
    return {
      loading: false,
      error: null,
      data: null,
    };
  },
  mounted() {
    this.loading = true;
    this.error = null;

    CostService.Stats()
        .then(({data}) => {
          this.data = data;
        })
        .catch(({message}) => {
          this.error = message ? message : "There was an error in loading stats.";
        })
        .finally(() => {
          this.loading = false;
        });
  },
};
</script>

<style scoped>
#AWS-container {
  padding: 0px 25px;
}

#AWS-container .tab {
  background-color: #13D39E;
  border-radius: 4px;
  border: 1px solid #89E9CF;
  color: #112849;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}

#AWS-container .tab .label {

  font-size: 14px;
}

#AWS-container .tab .value {

  font-size: 26px;
}

#AWS-container .tab .value .red-label {

  font-size: 14px;
  color: #ce1a36;
  margin-left: 0.25rem;
}

#AWS-container .tab .value .red-label img {
  margin-right: 0.15rem;
  margin-top: -1px;
}

#content-container .top-container .filter-tab[data-v-71fd258a] {
    background-color: ##89E9CF;
    color: #13D39E;
    border-radius: 6px;
    font-size: 14px;
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
}
</style>
