<template>
  <div id="analytics-date-range-container" v-if="dateRange">
    <date-range-picker
      ref="picker"
      opens="right"
      v-model="dateRange"
      @toggle="toggle($event)"
      @select="select($event)"
      :timePicker="true"
      :timePicker24Hour="false"
      :class="[{ active: opened ? true : false }]"
    >
      <template #input="picker">
        <div class="d-flex align-items-center">
          <div>
            <div class="d-none">
              {{ picker.startDate }} - {{ picker.endDate }}
            </div>
            <div class="range-container">
              {{ picker.startDate | moment("MMM DD, YYYY") }}
              -
              {{ picker.endDate | moment("MMM DD, YYYY") }}
            </div>
            <div class="compare-container" v-if="false">
              <span class="compare-label"> Compare to: </span>
              <span class="compare-range"> May 9, 2022 - May 15, 2022 </span>
            </div>
          </div>
          <div style="margin-left: 1rem">
            <img
              v-if="!opened"
              src="../../../assets/images/polygon.png"
              alt="polygon"
            />
            <img
              v-else
              src="../../../assets/images/polygon_active.png"
              alt="polygon_active"
            />
          </div>
        </div>
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "analytics-date-range",
  props: ["label", "range"],
  emits: ["change-range"],
  components: { DateRangePicker },
  data() {
    return { dateRange: null, opened: false };
  },
  methods: {
    openDatePicker() {
      this.$refs.picker.togglePicker(true);
    },
    toggle(val) {
      this.opened = val;
    },
    select({ startDate, endDate }) {
      this.$emit("change-range", this.label, startDate, endDate);
    },
    setData() {
      this.dateRange = this.range;
    },
  },
  mounted() {
    this.setData();
  },
};
</script>

<style>
.range-container {
  color: #112849;
  font-size: 24px;
}
.compare-container {
  font-size: 14px;

}
.compare-label {
  color: #7e8282;
}
.compare-range {
  color: #112849;
}
.reportrange-text {
  background-color: transparent !important;
  border-radius: 6px !important;
  border: 1px solid #c7c7c1 !important;
  color: #112849 !important;
  padding: 10px !important;
}
.reportrange-text:hover {
  background-color: #ebe9e1 !important;
}
.active .reportrange-text {
  background-color: #112849 !important;
}
.active .reportrange-text .range-container,
.active .reportrange-text .compare-label,
.active .reportrange-text .compare-range {
  color: #8bcdea;
}
.cancelBtn {
  background-color: transparent !important;
  border-radius: 6px !important;
  color: #a3a4a2 !important;

}
.applyBtn {
  background-color: #124bd8 !important;
  border-radius: 6px !important;
  color: #0B3133 !important;

}
/* .in-range {
  background-color: #89a5ec !important;
  color: #ffffff !important;
}
.start-date,
.end-date {
  background-color: #7d98db !important;
  color: #ffffff !important;
} */
</style>
