<template>
  <div id="tickets-filter-container">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <div class="d-flex align-items-center flex-wrap">
        <div style="margin-right: 1rem">
          <base-input
            placeholder="Search tickets…"
            cus_style="padding:10px 10px 10px 35px;background-color:#0B3133"
            :remove_default_margin="true"
            field="term"
            :value="term"
            @change-value="setValue"
          >
            <template v-slot:attachment>
              <img
                class="search-icon"
                src="../../../assets/images/search-icon.png"
                alt="search-icon"
              />
            </template>
          </base-input>
        </div>
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          class="d-flex align-items-center"
        >
          <div style="margin-right: 1rem; position: relative">
            <div
              :class="[
                'tab',
                { active: open_menu_index == index ? true : false },
              ]"
              @click.stop="toggle_filter_menu(index)"
            >
              <span
                v-if="
                  filters &&
                  filters.issue_priority &&
                  filters.issue_priority.length &&
                  index === 1
                "
              >
                {{ filters.issue_priority.length }}
              </span>
              <span
                v-if="
                  filters &&
                  filters.issue_type &&
                  filters.issue_type.length &&
                  index === 2
                "
              >
                {{ filters.issue_type.length }}
              </span>
              <span
                v-if="
                  filters &&
                  filters.issue_status &&
                  filters.issue_status.length &&
                  index === 3
                "
              >
                {{ filters.issue_status.length }}
              </span>
              <span
                v-if="
                  filters &&
                  filters.initial_response &&
                  filters.initial_response.length &&
                  index === 4
                "
              >
                {{ filters.initial_response.length }}
              </span>
              <span
                v-if="
                  filters &&
                  filters.reporter &&
                  filters.reporter.length &&
                  index === 5
                "
              >
                {{ filters.reporter.length }}
              </span>
              <span
                v-if="
                  filters &&
                  filters.assignee &&
                  filters.assignee.length &&
                  index === 6
                "
              >
                {{ filters.assignee.length }}
              </span>
              <span>
                <span v-if="filters && filters.create_date && index === 0">
                  {{ filters.create_date }}
                </span>
                <span v-else>
                  {{ tab }}
                </span>
              </span>
            </div>
            <Menu1
              @change-filter="changeFilter"
              @close-menu="closeMenu"
              v-if="lookups && index === 0"
              :open_menu="open_menu_index == index"
            />
            <Menu2
              @change-filter="changeFilter"
              @close-menu="closeMenu"
              v-if="lookups && lookups.priorities.length && index === 1"
              :open_menu="open_menu_index == index"
              :menu_items="lookups.priorities"
            />
            <Menu3
              @change-filter="changeFilter"
              @close-menu="closeMenu"
              v-if="lookups && lookups.issue_types.length && index === 2"
              :open_menu="open_menu_index == index"
              :menu_items="lookups.issue_types"
            />
            <Menu4
              @change-filter="changeFilter"
              @close-menu="closeMenu"
              v-if="lookups && lookups.statuses.length && index === 3"
              :open_menu="open_menu_index == index"
              :menu_items="lookups.statuses"
            />
            <Menu5
              @change-filter="changeFilter"
              @close-menu="closeMenu"
              v-if="lookups && index === 4"
              :open_menu="open_menu_index == index"
            />
            <Menu6
              @change-filter="changeFilter"
              @close-menu="closeMenu"
              v-if="lookups && lookups.reporters.length && index === 5"
              :open_menu="open_menu_index == index"
              :menu_items="lookups.reporters"
            />
            <Menu7
              @change-filter="changeFilter"
              @close-menu="closeMenu"
              v-if="lookups && lookups.assingees.length && index === 6"
              :open_menu="open_menu_index == index"
              :menu_items="lookups.assingees"
            />
          </div>
        </div>
      </div>
      <div>
        <base-button
          type="submit"
          style_variant="teritary"
          @onClick="resetFilter"
        >
          Reset Filters
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import Menu1 from "./Menu1.vue";
import Menu2 from "./Menu2.vue";
import Menu3 from "./Menu3.vue";
import Menu4 from "./Menu4.vue";
import Menu5 from "./Menu5.vue";
import Menu6 from "./Menu6.vue";
import Menu7 from "./Menu7.vue";

export default {
  name: "tickets-filter",
  props: ["lookups", "filters"],
  emits: ["change-filter", "reset-filter"],
  components: { Menu1, Menu2, Menu3, Menu4, Menu5, Menu6, Menu7 },
  data() {
    return {
      tabs: [
        "Create date",
        "Priority",
        "Type",
        "Status",
        "Initial response",
        "Reporter",
        "Assignee",
      ],
      open_menu_index: null,
      term: "",
    };
  },
  methods: {
    toggle_filter_menu(index) {
      if (this.open_menu_index === index) this.open_menu_index = null;
      else this.open_menu_index = index;
    },
    setValue(val, field) {
      this[field] = val;
    },
    changeFilter(key, val) {
      this.$emit("change-filter", key, val);
    },
    resetFilter() {
      this.$emit("reset-filter");
    },
    closeMenu() {
      this.open_menu_index = null;
    },
  },
  watch: {
    term(val) {
      this.$emit("change-filter", "search", val);
    },
  },
};
</script>

<style scoped>
#tickets-filter-container {
  width: 100%;
  background-color: #0B3133;
  border-top: 1px solid #ebe9e1;
  border-bottom: 1px solid #ebe9e1;
  border-radius: 4px;
  padding: 15px 25px;
}
#tickets-filter-container .search-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
#tickets-filter-container .tab {
  cursor: pointer;
  background-color: #F9F9F9;
  border-radius: 6px;
  color: #7e8282;
  font-size: 14px;

  padding: 6px 12px;
  margin: 5px 0px;
}
#tickets-filter-container .tab:hover {
  background-color: #ebe9e1;
}
#tickets-filter-container .tab.active {
  background-color: #112849;
  color: #89E9CF;
}
</style>
