<template>
  <div id="cloud-settings-container">
    <common-loading v-if="loading"/>
    <div v-else-if="error && error.length">
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>
    </div>
    <div v-else-if="data">
      <b-row>
        <div class="d-flex align-items-center">
          <img
              v-if="detail_of"
              src="../../../../assets/images/left-arrow.png"
              alt="left-arrow"
              style="
              transform: rotate(180deg);
              cursor: pointer;
              margin-right: 1rem;
              margin-top: -4px;
            "
              @click.stop="showDetailOf(null)"
          />
          <div class="title">Cloud Settings</div>
        </div>
        <b-col md="8" v-if="!detail_of">
          <div class="sub-title">Connect your cloud platform:</div>
          <div
              v-for="(platform, index) in data"
              :key="index"
              class="platform d-flex align-items-center justify-content-between"
              @click.stop="showDetailOf(platform)"
          >
            <div>
              <div class="label">{{ platform[0] }}</div>
              <div class="accounts">{{ platform[1].length }} accounts</div>
            </div>
            <div>
              <img
                  src="../../../../assets/images/left-arrow.png"
                  alt="left-arrow"
              />
            </div>
          </div>
        </b-col>
        <b-col v-else>
          <Detail :platform="detail_of"/>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Detail from "./Detail.vue";
import CloudConnectService from "../../../../services/cloud.connect.service";

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export default {
  name: "cloud-setting-page",
  components: {Detail},
  data() {
    return {
      platforms: [
        {label: "Amazon Web Services (AWS)", accounts: 0},
        {label: "Google Cloud Platform (GCP)", accounts: 0},
        {label: "Microsoft Azure", accounts: 0},
      ],
      detail_of: null,
      loading: false,
      error: null,
      data: [["Amazon Web Services (AWS)", []]],
    };
  },
  methods: {
    showDetailOf(platform) {
      this.detail_of = platform;
    },
    query(params = {}) {
      this.loading = true;
      this.error = null;

      CloudConnectService.list(params)
          .then(({data}) => {
            if (data && data.length) {
              this.data = groupBy(data, "account_type");
              this.data = Object.keys(this.data).map((key) => [
                key,
                this.data[key],
              ]);
            }
          })
          .catch(({message}) => {
            this.error = message
                ? message
                : "There was an error in loading list.";
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  mounted() {
    this.query();
  },
};
</script>

<style scoped>
#cloud-settings-container {
  padding: 1rem;
}

.title {
  font-size: 18px;

  color: #112849;
  margin-bottom: 0.25rem;
}

.sub-title {
  font-size: 14px;
  color: #5a6062;
  margin-bottom: 1rem;
}

.platform {
  cursor: pointer;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  border: 1px solid #c7c7c1;
  background-color: white;
}

.platform:hover {
  box-shadow: 0px 3px 6px #121b234d;
}

.platform .label {
  color: #112849;
  font-size: 16px;

}

.platform .accounts {
  color: #7e8282;

}
</style>
