import TritonInferenceService from "@/services/tritonInference.service";

export default {
  namespaced: true,
  state: {
    models: [],
  },
  mutations: {
    setModels(state, models) {
      state.models = models.data.models;
    },
    updateModelStatus(state, { modelId, status }) {
      const model = state.models.find((model) => model.uuid === modelId);

      if (model) {
        model.currentStatus = status;
      }
    },
  },
  actions: {
    async fetchModels({ commit }, payload) {
      const models = await TritonInferenceService.GetModels(payload);
      commit("setModels", models);

      return models;
    },
  },
  getters: {},
};
