<template>
  <div
    id="menu-container"
    class="menu-list"
    style="left: 0px; top: 45px; width: 175px"
    v-if="open_menu"
    v-click-outside="onClickOutside"
  >
    <div
      v-for="(menu_item, index) in menu_items"
      :class="['menu-item', 'd-flex']"
      :key="index"
      style="padding: 10px 10px 2px 10px"
      @click.stop="select_item(menu_item)"
    >
      <b-check
        :id="`select-${index}`"
        :name="`select-${index}`"
        :checked="
          selected_item.findIndex((rec) => rec.label === menu_item.label) >= 0
        "
      />
      <div style="margin-left: -1.5rem; margin-top: -4px">
        {{ menu_item.label }}
      </div>
    </div>
    <div class="bottom-container d-flex align-items-center justify-content-end">
      <div
        class="link"
        style="color: #124bd8; margin-right: 0.5rem"
        @click.stop="selectAll"
      >
        ALL
      </div>
      <div class="link" style="color: #7e8282" @click.stop="selectNone">
        NONE
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-5",
  props: ["open_menu"],
  emits: ["change-filter", "close-menu"],
  data() {
    return {
      menu_items: [
        { label: "Open", value: "open" },
        { label: "Met", value: "false" },
        { label: "Exceeded", value: "true" },
      ],
      selected_item: [],
    };
  },
  methods: {
    select_item(menu_item) {
      const index = this.selected_item.findIndex(
        (rec) => rec.label === menu_item.label
      );
      if (index >= 0)
        this.selected_item = this.selected_item.filter(
          (each) => each !== menu_item
        );
      else this.selected_item.push(menu_item);
    },
    selectAll() {
      this.selected_item = this.menu_items;
    },
    selectNone() {
      this.selected_item = [];
    },
    onClickOutside() {
      this.$emit("close-menu");
    },
  },
  watch: {
    selected_item(val) {
      this.$emit("change-filter", "initial_response", val);
    },
  },
};
</script>

<style scoped>
.bottom-container {
  padding: 10px 0px 0px 0px;
  border-top: 1px solid #ebe9e1;
  font-size: 12px;

}
.link {
  cursor: pointer;
}
</style>
