<template>
  <div id="ticket-status-container">
    <div class="title mb-3">Ticket Status</div>
    <div v-if="options && series && series.length">
      <apex-chart height="400" type="bar" :options="options" :series="series" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Ticket-Status",
  props: ["lookup", "data"],
  data() {
    return {
      options: null,
      series: [],
    };
  },
  methods: {
    setData() {
      let temp = [];

      for (let i = 0; i < this.lookup.length; i++) {
        const key = this.lookup[i],
          period1_rec = this.data.period1.find((each) => each.key === key),
          period2_rec = this.data.period2.find((each) => each.key === key);

        temp.push({
          label: key,
          period1: (period1_rec && period1_rec.value) || 0,
          period2: (period2_rec && period2_rec.value) || 0,
        });
      }

      if (temp.length) {
        this.options = {
          plotOptions: {
            bar: {
              borderRadius: 4,
              columnWidth: "45%",
              dataLabels: { position: "top" },
            },
          },
          colors: ["#124BD8", "#89A5EC"],
          xaxis: {
            categories: temp.map((each) => each.label),
            labels: {
              style: {
                fontSize: "14",

                colors: ["#112849"],
              },
            },
          },
          yaxis: {
            labels: {
              formatter: (value) => {
                return value + " tickets";
              },
              style: {
                fontSize: "11",

                colors: ["#A3A4A2"],
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "left",
            fontSize: "14px",

            markers: {
              height: 10,
              width: 10,
              radius: 50,
            },
            customLegendItems: ["Period 1", "Period 2"],
            labels: { colors: ["#7E8282", "#7E8282"] },
          },
        };

        this.series = [
          {
            name: "Period 1",
            data: temp.map((each) => each.period1),
          },
          {
            name: "Period 2",
            data: temp.map((each) => each.period2),
          },
        ];
      }
    },
  },
  mounted() {
    this.setData();
  },
};
</script>

<style scoped>
#ticket-status-container {
  background-color: #0B3133;
  border-radius: 4px;
  border: 1px solid #ebe9e1;
  padding: 20px 25px;
}
#ticket-status-container .title {
  color: #112849;
  font-size: 18px;
}
</style>
