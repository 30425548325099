import Vue from "vue";

const baseURL = process.env.VUE_APP_API_URL;

class CostService {
  static Stats() {
    const url = `${baseURL}/costs/stats`;

    return Vue.axios.get(url);
  }

  static Chart() {
    const url = `${baseURL}/costs/chart`;

    return Vue.axios.get(url);
  }

  static Details(params = {}) {
    const url = `${baseURL}/costs/details`;

    return Vue.axios.get(url, { params });
  }
}

export default CostService;
