import Vue from "vue";

const baseURL = process.env.VUE_APP_HARBOR_API_URL;

class MinioService {
    static Register(payload) {
        const url = `${baseURL}/minio/user`;
        return Vue.axios.post(
            url, payload
        );
    }

    static GetUserInfo(params) {
        console.log(params)
        const url = `${baseURL}/minio/user`;
        return Vue.axios.get(
            url, {params}
        );
    }

    static GetProjectStats(params) {
        const url = `${baseURL}/${params}/stats`;
        return Vue.axios.get(
            url, {params}
        );
    }

}

export default MinioService;
