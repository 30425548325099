<template>
  <div>
    <PageHeader title="Settings"/>
    <div id="settings-container" class="margin-under-header">


      <common-loading v-if="loading"/>
      <div v-else>

        <div style="padding: 0px 25px 15px 25px" v-if="error && error.length">
          <b-alert :show="error && error.length ? true : false" variant="danger">
            <div v-html="error || '&nbsp;'"></div>
          </b-alert>
        </div>
        <div style="padding: 0px 25px">
          <b-row>
            <b-col md="3">
              <Sidebar/>
            </b-col>
            <b-col md="9">
              <Content/>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import Content from "./components/Content.vue";
import PageHeader from "@/components/ui/PageHeader.vue";

export default {
  name: "Settings-Page",
  components: {PageHeader, Sidebar, Content},
  data() {
    return {
      loading: false,
      error: null,
    };
  },
};
</script>

<style scoped>
#settings-container {

}

#settings-container .main-title {
  padding: 25px;
  font-size: 22px;

  color: #a3a4a2;
}
</style>
