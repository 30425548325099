import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import VueMoment from "vue-moment";
import vClickOutside from "v-click-outside";

import router from "./router.js";
import store from "./store/index.js";
import App from "./App.vue";

import APIService from "./services/api";

import BaseInput from "./components/ui/BaseInput.vue";
import BaseSelect from "./components/ui/BaseSelect.vue";
import BaseButton from "./components/ui/BaseButton.vue";
import BaseSpinner from "./components/ui/BaseSpinner.vue";
import BaseTagsInput from "./components/ui/BaseTagsInput.vue";

import CommonLoading from "./components/common/loading.vue";

import AmountFormat from "./plugins/amount_format";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import PrimeVue from "primevue/config";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";
import SelectButton from "primevue/selectbutton";
import InputText from "primevue/inputtext";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import InputSwitch from "primevue/inputswitch";
import Paginator from "primevue/paginator";
import ProgressBar from "primevue/progressbar";
import Lottie from "vue-lottie";

import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/lara-light-teal/theme.css";
import "primeicons/primeicons.css";
import Accordion from "primevue/accordion/Accordion";
import AccordionTab from "primevue/accordiontab/AccordionTab";

Vue.use(PrimeVue);
Vue.use(ConfirmationService);
Vue.use(ToastService);
Vue.use(ConfirmationService);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueApexCharts);
Vue.use(VueMoment);
Vue.use(vClickOutside);

Vue.use(AmountFormat);

Vue.component("apex-chart", VueApexCharts);

Vue.component("base-input", BaseInput);
Vue.component("base-select", BaseSelect);
Vue.component("base-button", BaseButton);
Vue.component("base-spinner", BaseSpinner);
Vue.component("base-tags-input", BaseTagsInput);

Vue.component("common-loading", CommonLoading);
Vue.component("amount-format", AmountFormat);
Vue.component("PrimeButton", Button);
Vue.component("OverlayPanel", OverlayPanel);
Vue.component("SelectButton", SelectButton);
Vue.component("InputText", InputText);
Vue.component("ConfirmPopup", ConfirmPopup);
Vue.component("PrimeToast", Toast);
Vue.component("PrimeAccordion", Accordion);
Vue.component("PrimeAccordionTab", AccordionTab);
Vue.component("PrimeDropdown", Dropdown);
Vue.component("FileUpload", FileUpload);
Vue.component("InputSwitch", InputSwitch);
Vue.component("PrimePaginator", Paginator);
Vue.component("ProgressBar", ProgressBar);
Vue.component("LottieAnimation", Lottie);

new APIService();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
