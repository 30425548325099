<template>
  <div
      id="logout-container"
      class="d-flex justify-content-center align-items-center"
  >
    <common-loading/>
  </div>
</template>

<script>
import {LOGOUT} from "@/store/auth.module";
import {JWTService} from "../../storage";

export default {
  name: "Logout-page",
  mounted() {
    this.$store.dispatch(LOGOUT).then(() => {
      window.open(
          `${process.env.VUE_APP_KEYCLOAK_URL}/logout?post_logout_redirect_uri=${
              window.location.origin
          }/&id_token_hint=${JWTService.getIdToken()}`,
          "_self"
      );
    });
  },
};
</script>

<style scoped>
#logout-container {
  height: 100vh;
}
</style>
