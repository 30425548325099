<template>
  <div id="content-sidebar-container">
    <profile-settings v-if="$route.params.index == 0" />
    <cloud-settings v-if="$route.params.index == 1" />
  </div>
</template>

<script>
import ProfileSettings from "./ProfileSettings/index.vue";
import CloudSettings from "./CloudSettings/index.vue";

export default {
  name: "content-sidebar",
  components: { ProfileSettings, CloudSettings },
};
</script>

<style scoped>
#content-sidebar-container {
  background-color: white;
  border: 1px solid #ebe9e1;
  min-height: 100vh;
  border-radius: 4px;
}
</style>
