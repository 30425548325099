var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"ticket-container"},on:{"mouseenter":_vm.mouseenter,"mouseleave":_vm.mouseleave}},[(_vm.card)?_c('div',[(!_vm.is_hovered)?_c('div',[_c('div',{staticStyle:{"border-bottom":"1px solid #ebe9e1"}},[_c('div',{staticClass:"topbar d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"task-type"},[_vm._v(_vm._s(_vm.card.issue_type))]),_c('div',{staticClass:"task-number"},[_vm._v(_vm._s(_vm.card.id))])]),_c('div',{class:[
            'task-stage',
            { critical: _vm.card.priority === 'Critical' ? true : false },
            { priority: _vm.card.priority === 'Priority' ? true : false },
            { high: _vm.card.priority === 'High' ? true : false },
            { medium: _vm.card.priority === 'Medium' ? true : false },
            { minor: _vm.card.priority === 'Minor' ? true : false },
          ]},[(_vm.card.priority === 'Critical')?_c('img',{attrs:{"src":require("../../../assets/images/critical-icon.png"),"alt":"critical-icon"}}):_vm._e(),(_vm.card.priority === 'High')?_c('img',{attrs:{"src":require("../../../assets/images/high-icon.png"),"alt":"high-icon"}}):_vm._e(),(_vm.card.priority === 'Medium')?_c('img',{attrs:{"src":require("../../../assets/images/medium-icon.png"),"alt":"medium-icon"}}):_vm._e(),(_vm.card.priority === 'Minor')?_c('img',{attrs:{"src":require("../../../assets/images/minor-icon.png"),"alt":"minor-icon"}}):_vm._e(),_c('span',{staticClass:"stage-label"},[_vm._v(_vm._s(_vm.card.priority))])])]),_c('div',[_c('div',{staticClass:"client-name"},[_vm._v(_vm._s(_vm.card.project.name))]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.card.summary)+" ")])]),_c('div',{staticClass:"d-flex align-items-center mt-2"},[_c('div',{staticClass:"avtar d-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.card.assignee.name.slice(0, 1))+" ")]),_c('div',{staticClass:"assignee-name"},[_vm._v(_vm._s(_vm.card.assignee.name))])])]):_c('div',[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"margin-bottom":"2rem"}},[_c('div',[_vm._m(0),_c('div',{staticClass:"date-range"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.card.created,"MMM DD(hh:mm A)"))+" ")])]),_vm._m(1),_c('div',[_vm._m(2),_c('div',{staticClass:"date-range"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.card.last_updated,"MMM DD(hh:mm A)"))+" ")])])]),_c('div',{staticStyle:{"margin-bottom":"0.72rem"}},[_c('div',{staticClass:"time-label"},[_vm._v("Time to first response")]),_c('div',{staticClass:"time-sub-label"},[_vm._v("within "+_vm._s(_vm.get_diff(_vm.card.ttfr)))]),(_vm.card.ttfr)?_c('div',{class:[
            'time-block',
            'd-flex',
            'align-items-center',
            { red: _vm.card.ttfr_breached === 'true' ? true : false },
            { green: _vm.card.ttfr_breached === 'false' ? true : false },
            { blue: _vm.card.ttfr_breached === 'open' ? true : false },
          ]},[(_vm.card.ttfr_breached === 'true')?_c('img',{staticStyle:{"margin-right":"0.5rem","height":"18px","width":"18px"},attrs:{"src":require("../../../assets/images/bridge.png"),"alt":"bridge"}}):_vm._e(),(_vm.card.ttfr_breached === 'false')?_c('img',{staticStyle:{"margin-right":"0.5rem","height":"18px","width":"18px"},attrs:{"src":require("../../../assets/images/not_bridge.png"),"alt":"not_bridge"}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.card.ttfr,"MMM DD, YY(hh:mm A)"))+" ")])]):_c('div',{staticClass:"time-block"},[_vm._v("———")])]),_c('div',[_c('div',{staticClass:"time-label"},[_vm._v("Time to resolution")]),_c('div',{staticClass:"time-sub-label"},[_vm._v("within "+_vm._s(_vm.get_diff(_vm.card.ttr)))]),(_vm.card.ttr)?_c('div',{class:[
            'time-block',
            'd-flex',
            'align-items-center',
            { red: _vm.card.ttr_breached === 'true' ? true : false },
            { green: _vm.card.ttr_breached === 'false' ? true : false },
            { blue: _vm.card.ttr_breached === 'open' ? true : false },
          ]},[(_vm.card.ttr_breached === 'true')?_c('img',{staticStyle:{"margin-right":"0.5rem","height":"18px","width":"18px"},attrs:{"src":require("../../../assets/images/bridge.png"),"alt":"bridge"}}):_vm._e(),(_vm.card.ttr_breached === 'false')?_c('img',{staticStyle:{"margin-right":"0.5rem","height":"18px","width":"18px"},attrs:{"src":require("../../../assets/images/not_bridge.png"),"alt":"not_bridge"}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.card.ttr,"MMM DD, YY(hh:mm A)"))+" ")])]):_c('div',{staticClass:"time-block"},[_vm._v("———")])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../assets/images/send-clock-icon.png"),"alt":"send-clock-icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"0px 8px"}},[_c('img',{attrs:{"src":require("../../../assets/images/arrow.svg"),"alt":"arrow","height":"34px","width":"8px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../assets/images/send-clock-icon.png"),"alt":"send-clock-icon"}})])
}]

export { render, staticRenderFns }