<template>
  <button
    :class="[
      'btn',
      {
        primaryBtn: style_variant === 'primary',
        secondaryBtn: style_variant === 'secondary',
        teritaryBtn: style_variant === 'teritary',
      },
    ]"
    :type="type"
    @click.prevent="onClick"
    :style="cus_style"
    :disabled="disabled || loading"
  >
    <div class="d-flex justify-content-center align-items-center">
      <b-spinner small v-if="loading" style="margin-right: 0.5rem"></b-spinner>
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  emits: ["onClick"],
  props: ["type", "style_variant", "cus_style", "loading", "disabled"],
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>

<style scoped>
.btn {
  font-size: 16px;

  border-radius: 6px;
  padding: 10px 20px;
  width: -webkit-fill-available;
  cursor: pointer;
}
.btn:focus {
  box-shadow: none;
}
.primaryBtn,
.primaryBtn:focus {
  background-color: #124bd8;
  color: #0B3133;
  border: 1px solid #124bd8;
}
.primaryBtn:hover {
  background-color: #0d3597;
  color: #0B3133;
  border-color: #0d3597;
}
.primaryBtn:disabled {
  opacity: 0.3;
}
.secondaryBtn,
.secondaryBtn:focus {
  background-color: #ebe9e1;
  color: #363d43;
  border: 1px solid #ebe9e1;
}
.secondaryBtn:hover {
  background-color: #c7c7c1;
  color: #363d43;
  border-color: #c7c7c1;
}
.secondaryBtn:disabled {
  opacity: 0.3;
}
.teritaryBtn,
.teritaryBtn:focus {
  background-color: transparent;
  color: #a3a4a2;
  border: none;
}
.teritaryBtn:hover {
  background-color: transparent;
  color: #124bd8;
  border: none;
}
.teritaryBtn:disabled {
  opacity: 0.3;
}
</style>
