<template>
  <div>
 <PageHeader title="Harbor Registeration" />
  <div class="content-container  w-50 margin-under-header px-5">

    <div class="">
      <span v-if="showError" id="alert" class="lh-base text-wrap badge fs-6 text-bg-danger w-100 p-4 my-3">
        {{ errorMessage }}
      </span>

      <validation-observer ref="observer">
        <b-alert :show="!!(error && error.length)" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
        <form @submit.prevent="submitForm">

          <validation-provider
              name="project"
              rules="required|min:6|max:235"
              v-slot="{ errors }"
          >
            <base-input
                label="Project"
                placeholder="Enter project"
                @change-value="setValue"
                field="project"
                :value="project"
                :disabled="loading"
                :is_invalid="errors && errors.length"
                :helper_text="errors && errors.length ? errors[0] : ''"
            ></base-input>
          </validation-provider>

          <div class="">
            <base-button
                type="submit"
                :disabled="loading"
                :loading="loading"
                class="btn register-btn"
                @onClick="submitForm"
            >
              <span v-if="loading">Registering...</span>
              <span v-else>Register</span>
            </base-button>
          </div>

        </form>
      </validation-observer>
    </div>
  </div>
</div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate/dist/vee-validate.full";
import HarborService from "@/services/harbor.service";
import PageHeader from "@/components/ui/PageHeader.vue";

export default {
  name: "Harbor-page",

  components: {
    PageHeader,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      error: null,
      loading: false,
      project: "",
      showForm: true,
      tagData: "",
      imageData: "",
      harborLink: "",
      errorMessage: '', // To store the error message
      showError: false, // To control the visibility of the error message

    };
  },
  methods: {
    async submitForm() {
      const valid = await this.$refs.observer.validate();

      if (!valid) return;
      this.loading = true
      const payload = {
        "project": this.project
      };
      try {
        await HarborService.Register(payload);
        this.$router.push('/dashboard/harbor');
      } catch (error) {
        let details = error.errors;
        if (Array.isArray(details)) {
          details = details[0].msg
        }
        this.errorMessage = details;
        this.showError = true;
        // Use setTimeout to hide the element after 10 seconds
        setTimeout(() => {
          this.showError = false; // Set showError to false to hide the element
        }, 5000);
      } finally {
        this.loading = false
      }


    },
    setValue(val, field) {
      this[field] = val;
    }
  },
};
</script>

<style scoped>
#registration-container {
  position: absolute;
  left: 37%;
  top: 25%;
  width: 450px;
  background: none;
}

.register-btn {
  background-color: #13D39E !important;
  border: none;
  color: #0B3133;
}

.register-btn:hover{
  background-color: #13D39E !important;
  color: #0B3133;
}

</style>

