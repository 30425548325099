var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"setting-sidebar-container"}},_vm._l((_vm.links),function(link,index){return _c('div',{key:index,staticClass:"d-flex align-items-center link",on:{"mouseenter":function($event){return _vm.mouseenter(index)},"mouseleave":_vm.mouseleave,"click":function($event){$event.stopPropagation();return _vm.changeRoute(index)}}},[_c('div',[_c('img',{attrs:{"src":_vm.hover_index == index || _vm.active_index == index
            ? link.active_icon
            : link.icon,"alt":link.icon}})]),_c('div',{staticClass:"link-label",class:[
        {
          active:
            _vm.hover_index == index || _vm.active_index == index ? true : false,
        },
      ]},[_vm._v(" "+_vm._s(link.label)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }