<template>
  <div>
    <PageHeader
      title="New Model"
      parentLabel="Inference Dashboard"
      :backAllowed="true"
    />
    <div v-if="loading" class="loading-container">
      <div class="loading-content">
        <LottieAnimation :options="lottieOptions" :height="200" :width="200" />
        <ProgressBar :value="uploadProgress" />
        <p class="pt-2">Uploading... Please wait.</p>
      </div>
    </div>
    <form v-else @submit.prevent="submitForm">
      <div class="new-model-container">
        <div class="new-model col-8 col-lg-5">
          <h1 class="heading">New Model</h1>
          <div class="d-flex flex-column p-input-filled gap-1">
            <label class="label" for="modelName">Model Name</label>
            <InputText
              id="modelName"
              type="text"
              v-model="form.modelName"
              @input="validateModelName"
              :class="{ 'is-invalid': !isValidModelName }"
            />
            <span v-if="!isValidModelName" class="text-danger">
              model name must be lowercase, no space
            </span>
          </div>
          <div class="d-flex flex-column gap-1 pt-3">
            <label class="label" for="source">Source</label>
            <PrimeDropdown
              v-model="form.selectedSource"
              :options="source"
              optionLabel="name"
              :class="{ 'is-invalid': !isValidSelectedSource }"
              @input="validateSelectedSource"
            />
            <span v-if="!isValidSelectedSource" class="text-danger">
              Source is required
            </span>
          </div>
          <div
            v-if="form.selectedSource"
            class="d-flex flex-column p-input-filled gap-1 pt-3"
          >
            <label class="label" for="sourceName">{{
              form.selectedSource.name
            }}</label>
            <div class="d-flex gap-2">
              <span
                v-if="form.selectedSource.id !== 2"
                class="p-input-icon-left w-100"
              >
                <i :class="form.selectedSource.icon" />
                <InputText
                  id="sourceName"
                  type="text"
                  v-model="form.sourceUrl"
                  @input="validateSourceUrl(form.selectedSource.id)"
                  :class="{ 'is-invalid': !isValidSourceUrl }"
                  class="w-100"
                />
              </span>
              <span v-else class="upload-file-name w-100">
                <i :class="form.selectedSource.icon" />
                {{ sourceFile.name }}
              </span>
              <label
                v-if="form.selectedSource.id === 2"
                class="upload-button-label"
              >
                <input
                  type="file"
                  @change="onUpload"
                  ref="file"
                  class="upload-button"
                />
                Browse
              </label>
            </div>
            <span v-if="!isValidSourceUrl" class="text-danger">
              {{
                form.selectedSource.id === 1
                  ? "URL must start with http:// or https://"
                  : "URL must start with s3://"
              }}
            </span>
          </div>
          <div class="d-flex flex-column p-input-filled gap-1 pt-3">
            <div class="d-flex justify-content-between">
              <label class="label" for="inputSize">Backend</label>
              <label class="label" for="inputSize">Input size</label>
            </div>
            <div class="d-flex flex-wrap flex-xl-nowrap gap-2">
              <PrimeDropdown
                v-model="backendType"
                :options="backend"
                optionLabel="name"
                class="flex-grow-1 w-100"
              />
              <InputText
                id="inputSize"
                type="text"
                v-model="form.inputSize"
                @input="validateInputSize"
                class="flex-grow-1 w-100"
                :class="{
                  'is-invalid': !isValidInputSize,
                  'cursor-not-allowed': backendType.name !== 'PyTorch',
                }"
                :disabled="backendType.name !== 'PyTorch'"
              />
            </div>
            <span v-if="!isValidInputSize" class="text-danger">
              Input size must be in the format [1,2,3,4] and at least 4 values
            </span>
          </div>
          <div class="advanced-settings mt-2">
            <button id="settings" popovertarget="advanced" type="button">
              <span> advanced optimization settings </span>
            </button>
            <div id="advanced" popover="manual" anchor="settings" class="popup">
              <h3 class="popup-title">advanced optimization settings</h3>
              <div class="d-flex flex-column p-input-filled gap-1">
                <label class="label" for="latency"
                  >Max Latency Constraint (MS)</label
                >
                <InputText
                  id="latency"
                  type="number"
                  v-model.number="form.latency"
                  @input="validateFloat('latency')"
                  :class="{ 'is-invalid': !isValidLatency }"
                />
                <span v-if="!isValidLatency" class="text-danger">
                  Latency must be a number
                </span>
              </div>
              <div class="d-flex flex-column p-input-filled gap-1 pt-3">
                <label class="label" for="throughput"
                  >Min Throughput Constraint (INFER/SEC)</label
                >
                <InputText
                  id="throughput"
                  type="number"
                  v-model.number="form.throughput"
                  @input="validateInteger('throughput')"
                  :class="{ 'is-invalid': !isValidThroughput }"
                />
                <span v-if="!isValidThroughput" class="text-danger">
                  Throughput must be an integer
                </span>
              </div>
              <div class="d-flex flex-column p-input-filled gap-1 pt-3">
                <label class="label" for="memory"
                  >Max GPU Used Memory (MB)</label
                >
                <InputText
                  id="memory"
                  type="number"
                  v-model.number="form.memory"
                  @input="validateInteger('memory')"
                  :class="{ 'is-invalid': !isValidMemory }"
                />
                <span v-if="!isValidMemory" class="text-danger">
                  Memory must be an integer
                </span>
              </div>
              <PrimeButton
                class="model-button mt-3"
                label="Apply Settings"
                popovertarget="advanced"
              />
            </div>
          </div>
          <div class="dedicated-server p-2 mt-3">
            <div class="d-flex align-items-center gap-4">
              <InputSwitch v-model="form.dedicatedServer" />
              <span>Create a dedicated server for this model</span>
            </div>
            <div
              v-if="form.dedicatedServer"
              class="d-flex flex-column p-input-filled gap-1 pt-3"
            >
              <div class="d-flex justify-content-between">
                <label class="label" for="maxReplicas"
                  >Number of Replicas</label
                >
                <span>(Optional)</span>
              </div>
              <div class="d-flex flex-wrap flex-xl-nowrap gap-2">
                <InputText
                  id="minReplicas"
                  type="number"
                  class="flex-grow-1"
                  placeholder="Min"
                  v-model.number="form.minReplicas"
                  @input="validateInteger('minReplicas')"
                  :class="{ 'is-invalid': !isValidMinReplicas }"
                />
                <InputText
                  id="maxReplicas"
                  type="number"
                  class="flex-grow-1"
                  placeholder="Max"
                  v-model.number="form.maxReplicas"
                  @input="validateInteger('maxReplicas')"
                  :class="{ 'is-invalid': !isValidMaxReplicas }"
                />
              </div>
              <span
                v-if="!isValidMinReplicas || !isValidMaxReplicas"
                class="text-danger pt-2"
              >
                Number of replicas must be an integer
              </span>
              <span
                v-if="form.minReplicas > form.maxReplicas"
                class="text-danger pt-2"
              >
                Min replicas must be less than max replicas
              </span>
              <div class="d-flex align-items-center gap-2 pt-3">
                <InputSwitch v-model="form.autoScaling" :disabled="true" />
                <span>Auto-Scaling</span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <PrimeButton
              class="model-button mt-3"
              label="Deploy"
              type="submit"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TritonInferenceService from "@/services/tritonInference.service";
import PageHeader from "@/components/ui/PageHeader.vue";
import animationData from "@/assets/animation/loading.json";

export default {
  name: "New-Model",
  components: { PageHeader },
  data() {
    return {
      loading: false,
      error: null,
      isValidModelName: true,
      isValidInputSize: true,
      isValidSelectedSource: true,
      isValidSourceUrl: true,
      isValidMinReplicas: true,
      isValidMaxReplicas: true,
      isValidLatency: true,
      isValidThroughput: true,
      isValidMemory: true,
      sourceFile: "",
      backendType: "",
      uploadProgress: 0,
      form: {
        modelName: "",
        inputSize: "",
        selectedSource: null,
        sourceUrl: "",
        latency: null,
        throughput: null,
        memory: null,
        dedicatedServer: false,
        maxReplicas: null,
        minReplicas: null,
        autoScaling: false,
      },
      source: [
        // { name: "public url", icon: "pi pi-globe", id: 1 },
        { name: "file upload", icon: "pi pi-file-arrow-up", id: 2 },
        // { name: "s3 bucket", icon: "pi pi-box", id: 3 },
      ],
      backend: [
        { name: "TensorFlow", id: 1 },
        { name: "PyTorch", id: 2 },
        { name: "ONNX", id: 3 },
        { name: "TensorRT", id: 4 },
      ],
      data: null,
      lottieOptions: { animationData: animationData },
    };
  },
  watch: {
    "form.maxReplicas"(newValue) {
      this.form.autoScaling = !!newValue;
    },
    backendType(newValue) {
      if (newValue.name !== "PyTorch") {
        this.form.inputSize = "";
        this.isValidInputSize = true;
      }
    },
    sourceFile(newValue) {
      const fileName = newValue.name.split(".").pop();

      switch (fileName) {
        case "pb":
        case "h5":
        case "tflite":
        case "saved_model":
          this.backendType = this.backend[0];
          break;
        case "pt":
        case "pth":
        case "bin":
          this.backendType = this.backend[1];
          break;
        case "onnx":
          this.backendType = this.backend[2];
          break;
        case "plan":
        case "trt":
          this.backendType = this.backend[3];
          break;
        default:
          this.backendType = null;
      }
    },
  },
  methods: {
    validateInputSize() {
      const regex = /^\[\s*(\d+\s*,\s*){3,}\d+\s*\]$/;
      this.isValidInputSize = regex.test(this.form.inputSize);
    },
    validateModelName() {
      const regex = /^[a-z0-9-_]+$/;
      this.isValidModelName = regex.test(this.form.modelName);
    },
    validateSelectedSource() {
      this.isValidSelectedSource = !!this.form.selectedSource;
    },
    validateSourceUrl(fieldId) {
      const urlRegex = /^(http:\/\/|https:\/\/)/;
      const s3Regex = /^s3:\/\//;

      switch (fieldId) {
        case 1:
          this.isValidSourceUrl = urlRegex.test(this.form.sourceUrl);
          break;
        case 3:
          this.isValidSourceUrl = s3Regex.test(this.form.sourceUrl);
          break;
      }
    },
    validateInteger(field) {
      const regex = /^\d*$/;
      switch (field) {
        case "minReplicas":
          this.isValidMinReplicas = regex.test(this.form.minReplicas);
          break;
        case "maxReplicas":
          this.isValidMaxReplicas = regex.test(this.form.maxReplicas);
          break;
        case "throughput":
          this.isValidThroughput = regex.test(this.form.throughput);
          break;
        case "memory":
          this.isValidMemory = regex.test(this.form.memory);
          break;
      }
    },
    validateFloat(field) {
      const regex = /^\d+(\.\d+)?$/;
      switch (field) {
        case "latency":
          this.isValidLatency = regex.test(this.form.latency);
          break;
      }
    },
    validateForm() {
      this.isValidModelName = !!this.form.modelName && this.isValidModelName;
      if (this.backendType.name === "PyTorch") {
        this.isValidInputSize = !!this.form.inputSize && this.isValidInputSize;
      }
      this.isValidSelectedSource =
        !!this.form.selectedSource && this.isValidSelectedSource;

      return (
        this.isValidModelName &&
        this.isValidInputSize &&
        this.isValidSelectedSource
      );
    },
    inputSizeToString() {
      return this.form.inputSize
        .replace("[", "")
        .replace("]", "")
        .split(",")
        .join(" ");
    },
    async submitForm() {
      if (!this.validateForm()) {
        this.error = "Please fill in all required fields.";
        return;
      }
      if (!this.sourceFile) {
        this.error = "Please fill in all required fields.";
        return;
      }
      if (this.form.minReplicas > this.form.maxReplicas) {
        this.error = "Min replicas must be less than max replicas";
        return;
      }

      const formData = {};
      const fileData = new FormData();

      fileData.append("attachedFile", this.sourceFile);
      fileData.append("fileName", this.sourceFile.name);
      fileData.append("modelName", this.form.modelName);

      formData.modelName = this.form.modelName;

      if (this.backendType.name === "PyTorch") {
        formData.inputSize = this.inputSizeToString();
      } else {
        formData.inputSize = "";
      }

      const selectedSourceId = this.form.selectedSource?.id;
      if (selectedSourceId === 1 || selectedSourceId === 3) {
        formData.sourceUrl = this.form.sourceUrl;
      }

      formData.maxLatency = this.form.latency || 20;
      formData.minThroughput = this.form.throughput || 100;
      formData.maxGpuMemory = this.form.memory || 1000;
      formData.dedicatedServer = this.form.dedicatedServer;

      formData.maxReplicas = this.form.maxReplicas || 10;
      formData.minReplicas = this.form.minReplicas || 1;

      formData.autoScaling = this.form.autoScaling;

      try {
        this.loading = true;

        if (selectedSourceId === 2 && this.sourceFile) {
          const response = await TritonInferenceService.UploadFile(
            fileData,
            (progress) => {
              this.uploadProgress = progress;
            }
          );
          formData.fileUuid = response.data.fileUuid;
        }
        await TritonInferenceService.CreateModel(JSON.stringify(formData));
        this.resetForm();
        this.$toast.add({
          severity: "success",
          summary: "Success Message",
          detail: "Model created successfully",
          life: 3000,
        });
        this.$router.push({ path: "/dashboard/Inference" });
      } catch (error) {
        this.error = error?.message || "Something went wrong";
        this.$toast.add({
          severity: "error",
          summary: "Error Message",
          detail: this.error,
          life: 3000,
        });
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.form = {
        modelName: "",
        selectedSource: null,
        sourceUrl: "",
        sourceFile: null,
        latency: "",
        throughput: "",
        memory: "",
        dedicatedServer: false,
        maxReplicas: "",
        minReplicas: "",
        autoScaling: false,
      };

      this.sourceFile = "";
    },
    onUpload() {
      this.sourceFile = this.$refs.file.files[0];
    },
  },
};
</script>

<style scoped>
.new-model-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 110px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.new-model {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ebe9e1;
}

.heading {
  font-size: 18px;
  font-weight: 700;
  color: #083e47;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.label {
  font-size: 16px;
  font-weight: 300;
  color: #083e47;
  text-transform: capitalize;
}

.p-inputtext {
  font-size: 14px;
  font-weight: 500;
  color: #021214;
}

.advanced-settings {
  background-color: #f3f4f4;
  min-height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  width: 430px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ebe9e1;
  z-index: 10;
  left: anchor(center);
  margin-right: 10px;
}

#settings {
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #0c5966;
  text-transform: capitalize;
  width: 100%;
}
.popup-title {
  font-size: 16px;
  font-weight: 400;
  color: #083e47;
  text-transform: capitalize;
}

.model-button {
  background-color: #13d39e !important;
  color: #021214 !important;
  border: none !important;
  width: 140px !important;
  height: 38px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 5px !important;
  text-align: center !important;
}

.dedicated-server {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ebe9e1;
  border-radius: 8px;
  min-height: 64px;
}

.dedicated-server span {
  font-size: 16px;
  font-weight: 300;
  color: #083e47;
}

.upload-button {
  display: none;
}

.upload-button-label {
  display: inline-block;
  padding: 5px 10px;
  background-color: transparent;
  color: #0fa97e;
  border: 2px solid #13d39e;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  flex-grow: 0.5;
  width: 20%;
  height: 38px;
}

.upload-file-name {
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #e9ecef;
  border: 1px solid #ebe9e1;
  border-radius: 4px;
  padding: 5px 10px;
  color: #021214;
  font-size: 14px;
  font-weight: 500;
  flex-grow: 0.5;
  height: 38px;
}
.is-invalid {
  border-color: #dd3d05;
}
.text-danger {
  color: #dd3d05;
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loading-content {
  text-align: center;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 40%;
}

.loading-animation {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.progress {
  width: 100%;
  margin-bottom: 10px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.p-progressbar {
  height: 15px;
}
</style>
