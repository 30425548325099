<template>
  <div id="no-auth-container">
    <common-loading/>
  </div>
</template>

<script>
import {JWTService} from "../storage";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";

export default {
  methods: {
    generateRandomString() {
      let characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      let charactersLength = characters.length;

      for (let i = 0; i < 44; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    },
  },
  mounted() {
    JWTService.removeCodeChallenge();
    JWTService.removeCodeVerifier();
    JWTService.removeIdToken();
    const code_verifier = this.generateRandomString();
    const code_challenge = encodeURI(Base64.stringify(sha256(code_verifier)))
        .replaceAll(/\+/g, "-")
        .replaceAll(/\//g, "_")
        .replaceAll(/=+$/g, "");

    if (code_verifier && code_challenge) {
      JWTService.setCodeVerifier(code_verifier);
      JWTService.setCodeChallenge(code_challenge);
      let link = `${process.env.VUE_APP_KEYCLOAK_URL}/auth?redirect_uri=${window.location.origin}/callback&client_id=frontend-client&grant_type=authorization_code&response_type=code&scope=openid&code_challenge=${code_challenge}&code_challenge_method=S256`
      if (this.$route.query.is_registration) {
        link = `${process.env.VUE_APP_KEYCLOAK_URL}/registrations?redirect_uri=${window.location.origin}/callback&client_id=frontend-client&grant_type=authorization_code&response_type=code&scope=openid&code_challenge=${code_challenge}&code_challenge_method=S256`
      }
      window.open(
          link,
          "_self"
      );
    }
  },
};
</script>

<style scoped>
#no-auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
}
</style>
