<template>
  <div id="profile-page">
    <b-row class="content">
      <b-col md="6" style="padding-left: 0px">
        <base-input
          label="First & last name"
          value="Mahmoud Abdullah"
          :disabled="true"
        ></base-input>
        <base-input
          label="Company name"
          value="Sitech"
          :disabled="true"
        ></base-input>
        <base-select
          label="Role"
          :options="options"
          field="role"
          :value="role"
          @change-value="setValue"
          cus_style="background-color:transparent;"
        ></base-select>
      </b-col>
      <b-col
        md="6"
        class="d-flex justify-content-end"
        style="padding-right: 0px"
      >
        <div>
          <div class="form-label labelText">Your photo</div>
          <div
            class="avtar-container d-flex align-items-center justify-content-center"
          >
            <div class="avtar d-flex align-items-center justify-content-center">
              <div class="avtar-label">MA</div>
            </div>
          </div>
          <div class="mt-2 d-flex justify-content-between align-items-center">
            <base-button
              cus_style="margin-right:0.25rem;"
              style_variant="secondary"
            >
              Upload
            </base-button>
            <base-button
              cus_style="margin-left:0.25rem;"
              style_variant="secondary"
            >
              Remove
            </base-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="content" style="padding: 1.5rem 0rem">
      <b-col md="6" style="padding-left: 0px" class="d-flex align-items-center">
        <div class="form-label labelText" style="margin-bottom: 0px">
          Password
        </div>
      </b-col>
      <b-col
        md="6"
        class="d-flex justify-content-end"
        style="padding-right: 0px"
      >
        <div>
          <base-button style_variant="teritary"> Change Password </base-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="content" style="padding: 1.5rem 0rem; border-bottom: none">
      <b-col md="9" style="padding-left: 0px; font-size: 13px; color: #7e8282">
        <div class="form-label labelText">Delete my profile</div>
        <div class="mb-1">
          You will receive an email to confirm your decision. This will not end
          your subscription or payments and you will continue to be charged. You
          can cancel your subscription, or switch payment methods to keep the
          team active.
        </div>
        <div>
          This can’t be reversed. All boards you’ve created will be permanently
          erased. You can <span class="link">save backups</span> or
          <span class="link">export</span> them.
        </div>
      </b-col>
      <b-col
        md="3"
        class="d-flex justify-content-end align-items-center"
        style="padding-right: 0px"
      >
        <div>
          <base-button style_variant="teritary"> Delete Profile </base-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "profile-page",
  data() {
    return {
      options: [
        { id: null, label: "Select any" },
        { id: 1, label: "menu item" },
        { id: 2, label: "menu item" },
        { id: 3, label: "menu item" },
      ],
      role: null,
    };
  },
  methods: {
    setValue(val, field) {
      this[field] = val;
    },
  },
};
</script>

<style scoped>
.content {
  margin: 0px;
  border-bottom: 1px solid #eceae2;
}
.avtar-container {
  height: 150px;
  background-color: #F9F9F9;
}
.avtar {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #7968d3;
}
.avtar-label {
  color: #F9F9F9;
  font-size: 30px;

}
.link {
  cursor: pointer;
  color: #2f60dc;
}
</style>
