var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"content-container"}},[(_vm.loading)?_c('common-loading'):(_vm.error && _vm.error.length)?_c('div',[_c('b-alert',{attrs:{"show":_vm.error && _vm.error.length ? true : false,"variant":"danger"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.error || '&nbsp;')}})])],1):(_vm.data)?_c('div',[_c('div',{staticStyle:{"margin-top":"1rem"}},[_c('div',{staticClass:"Model-title"},[_vm._v("Model Details")]),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"2"}},[_c('div',{staticClass:"bill-queue"},_vm._l((_vm.modelDetails),function(each,index){return _c('div',{key:index,staticStyle:{"position":"relative"}},[_c('div',{staticClass:"bill-queue-tab d-flex align-items-center",class:[
                  {
                    active: _vm.selected_tab_index === index,
                  },
                ],on:{"click":function($event){$event.stopPropagation();return _vm.selectTab(index)}}},[_c('div',{staticClass:"bill-queue-label"},[_vm._v(" "+_vm._s(each.service)+" ")])])])}),0)]),_c('b-col',{attrs:{"md":"8","xl":"10"}},[_c('div',{staticClass:"bill-detail"},[_c('div',{staticClass:"service-container"},_vm._l((_vm.modelDetails.find((e, i) => {
                  if (i === _vm.selected_tab_index) return e;
                }).model_detail),function(tab,index){return _c('div',{key:index},[_c('div',[(tab.model_type === 'Inputs')?_c('div',{staticClass:"service py-2"},[_c('div',{staticClass:"usage-type pb-2"},[_vm._v(_vm._s(tab.model_type))]),_c('div',{staticClass:"d-flex"},[(tab.inputSize)?_c('div',_vm._l((tab.inputSize),function(value,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('span',{staticClass:"input-name"},[_vm._v(_vm._s(value.name))]),_vm._l((_vm.convertInputSizeToArray(
                              value.inputSize
                            )),function(cell,index){return _c('div',{key:index,staticClass:"input-cells m-2"},[_vm._v(" "+_vm._s(cell)+" ")])})],2)}),0):_c('span',[_vm._v(" no inputs available ")])])]):(tab.model_type === 'outputs')?_c('div',{staticClass:"service"},[_c('div',{staticClass:"usage-type pb-2"},[_vm._v(_vm._s(tab.model_type))]),_c('div',{staticClass:"d-flex"},[(tab.outputs)?_c('div',_vm._l((tab.outputs),function(value,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('span',{staticClass:"input-name"},[_vm._v(_vm._s(value.name))]),_vm._l((_vm.convertInputSizeToArray(
                              value.outputSize
                            )),function(cell,index){return _c('div',{key:index,staticClass:"input-cells m-2"},[_vm._v(" "+_vm._s(cell)+" ")])})],2)}),0):_c('span',[_vm._v(" no outputs available ")])])]):(tab.model_type === 'status')?_c('div',{staticClass:"service"},[_c('div',{staticClass:"usage-type pb-2"},[_vm._v(_vm._s(tab.model_type))]),_c('div',{staticClass:"d-flex"},[(tab.statuses)?_c('div',_vm._l((tab.statuses),function(value,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('span',{staticClass:"input-name"},[_vm._v(_vm._s(value.description))]),_c('div',{staticClass:"m-2"},[_vm._v(" "+_vm._s(value.createdAt)+" ")])])}),0):_c('span',[_vm._v(" no status available ")])])]):(tab.model_type === 'general')?_c('div',{staticClass:"d-flex flex-column gap-2"},[_c('div',{staticClass:"text-capitalize"},[_vm._v("backend")]),_c('div',{staticClass:"text-capitalize bold-label"},[_vm._v(" "+_vm._s(_vm.data.backend ? _vm.data.backend : "---")+" ")]),_c('div',{staticClass:"text-capitalize"},[_vm._v("dynamic batching")]),_c('div',{staticClass:"text-capitalize bold-label"},[_vm._v(" "+_vm._s(_vm.data.dynamicPatch ? "On" : "Off")+" ")])]):_vm._e()])])}),0)])])],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }