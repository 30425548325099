<template>
  <div id="profile-setting-page">
    <div class="tabs d-flex align-items-center">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab"
        :class="[
          {
            active:
              hover_index === index || active_index === index ? true : false,
          },
        ]"
        @mouseenter="mouseenter(index)"
        @mouseleave="mouseleave"
        @click.stop="setActive(index)"
      >
        {{ tab.label }}
      </div>
    </div>
    <div style="padding: 1rem 1rem">
      <Profile v-if="active_index === 0" />
    </div>
  </div>
</template>

<script>
import Profile from "./Profile.vue";
export default {
  name: "profile-setting-page",
  components: { Profile },
  data() {
    return {
      tabs: [
        { label: "Profile" },
        { label: "Email Settings" },
        { label: "Security" },
      ],
      hover_index: null,
      active_index: 0,
    };
  },
  methods: {
    mouseenter(index) {
      this.hover_index = index;
    },
    mouseleave() {
      this.hover_index = null;
    },
    setActive(index) {
      this.active_index = index;
    },
  },
};
</script>

<style scoped>
.tabs {
  margin: 1rem 0rem;
  border-bottom: 1px solid #eceae2;
}
.tab {
  cursor: pointer;
  font-size: 16px;
  padding-bottom: 0.5rem;
  margin-left: 1rem;
  border-bottom: 2px solid transparent;
  color: #7e8282;
}
.tab.active {
  border-bottom: 2px solid #124bd8;
  color: #124bd8;
}
</style>
