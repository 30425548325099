<template>
  <div class="dashboard-cards mt-3 ms-2 me-4">
    <b-row>
      <b-col cols="12" xl="6" v-for="model in models" :key="model.name">
        <b-card
          style="width: 100%; border: none; margin-bottom: 20px"
          header-tag="header"
          footer-tag="footer"
        >
          <div class="p-3">
            <div class="d-flex align-items-center gap-2">
              <ModelStatus
                :status="model.currentStatus"
                :modelId="model.uuid"
              />
              <div class="model-name flex-grow-1 fs-6">
                {{ getModelStatus(model.modelName) }}
              </div>
              <div class="flex-grow-2">
                <router-link :to="`${$route.path}/${model.uuid}`">
                  <PrimeButton class="model-button" icon="pi pi-arrow-right" />
                </router-link>
              </div>
            </div>
            <div class="d-flex">
              <div class="fw-light d-flex gap-2 flex-grow-1 text-capitalize">
                version<span class="model-name">{{ model.version }}</span>
              </div>
              <div
                class="fw-light d-flex gap-2 flex-grow-1 border-start border-2 ps-2"
              >
                Backend<span class="model-name">{{
                  model.backend ? model.backend : "---"
                }}</span>
              </div>
            </div>

            <PrimeButton
              label="Copy Model Link"
              class="copy-button mt-3"
              icon="pi pi-copy"
              @click="copyModelName(model.modelName)"
            />
          </div>

          <div
            style="margin-top: 10px; background: #e7fbf5 !important"
            v-if="model.modelName"
          >
            <div class="footer-div py-3">
              <div class="row text-center footer-labels">
                <div class="col-3">
                  <img src="@/assets/images/count-icon.svg" />
                  <span class="ms-2" style="text-transform: capitalize"
                    >Count</span
                  >
                </div>
                <div class="col-3 left-thin-border">
                  <img src="@/assets/images/queue-icon.svg" />
                  <span class="ms-2" style="text-transform: capitalize"
                    >Queue Duration</span
                  >
                </div>
                <div class="col-3 left-thin-border">
                  <img src="@/assets/images/instances-icon.svg" />
                  <span class="ms-2" style="text-transform: capitalize"
                    >Instances</span
                  >
                </div>
                <div class="col-3 left-thin-border">
                  <img src="@/assets/images/requests-icon.svg" />
                  <span class="ms-2" style="text-transform: capitalize"
                    >Failed Requests</span
                  >
                </div>
              </div>
              <div class="row text-center">
                <div class="col-3">{{ model.executionCount }}</div>
                <div class="col-3 left-thin-border">
                  {{ model.duration }}
                </div>
                <div class="col-3 left-thin-border">
                  {{ model.instances }}
                </div>
                <div class="col-3 left-thin-border">
                  {{ model.failedCount }}
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ModelStatus from "@/components/common/ModelStatus.vue";

const baseURL = process.env.VUE_APP_TRITON_URL;
export default {
  name: "GridModel",
  components: {
    ModelStatus,
  },
  props: {
    models: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getModelStatus(model) {
      return model.toLowerCase();
    },
    getModelNameVersion(modelName) {
      return modelName.split("__");
    },
    getModelNameOnly(modelName) {
      return this.getModelNameVersion(modelName)[0];
    },
    copyModelName(modelName) {
      let formattedModelName = modelName.replace(/\s+/g, "_");
      let url = `${baseURL}/${this.getModelNameOnly(formattedModelName)}`;
      navigator.clipboard.writeText(url);

      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "copied to clipboard",
        life: 3000,
      });
    },
  },
};
</script>

<style scoped>
.card-body {
  padding: 0;
}

.model-name {
  color: #083e47;
  font-size: 16px;
  font-weight: 500;
}

.model-button {
  background-color: #13d39e !important;
  color: #021214 !important;
  border: none !important;
  width: 64px !important;
}

.copy-button {
  background-color: transparent !important;
  color: #13d39e !important;
  border: none !important;
}

.copy-button:hover {
  background-color: #e7fbf5 !important;
}

.footer-div img {
  width: 16px;
  height: 16px;
}
</style>
