<template>
  <div id="base-tags-input">
    <div class="form-label labelText" v-if="label">
      {{ label }}
    </div>
    <div
      class="input-container form-control d-flex align-items-center flex-wrap"
      style="padding: 5px"
    >
      <div
        v-for="(tag, index) in tags"
        :key="index"
        class="tag d-flex align-items-center"
      >
        <div class="tag-label">
          {{ tag }}
        </div>

        <img
          class="close-icon"
          src="../../assets/images/tag-close.png"
          alt="close"
          @click.stop="removeTag(index)"
        />
      </div>
      <input
        :placeholder="tags.length ? '' : placeholder"
        type="email"
        class="naked-input"
        :value="tag_text"
        @input="changeTagText($event.target.value)"
        @keydown.188.prevent="addTags"
        @keydown.13.prevent="addTags"
        @keydown.9.prevent="addTags"
      />
    </div>
    <div class="helper_text" :style="helper_text_style">
      {{ helper_text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseTagsInput",
  emits: ["add-tag", "remove-tag"],
  props: ["label", "helper_text_style", "helper_text", "tags", "placeholder"],
  data() {
    return { tag_text: "" };
  },
  methods: {
    changeTagText(val) {
      if (val) {
        this.tag_text = val;
      }
    },
    addTags() {
      if (this.tag_text.length) {
        this.$emit("add-tag", this.tag_text);
        this.tag_text = "";
      }
    },
    removeTag(index) {
      this.$emit("remove-tag", index);
    },
  },
};
</script>

<style scoped>
#base-tags-input .labelText {
  font-size: 16px;

  color: #112849;
  margin-bottom: 5px;
}
#base-tags-input .helper_text {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  font-size: 13px;
  font-family: Poppins-Medium;
}
#base-tags-input .input-container {
  max-height: 100px;
  overflow: auto;
}
#base-tags-input .tag {
  background-color: #e7edfb;
  padding: 1px 5px;
  margin: 5px;
}
#base-tags-input .tag-label {
  color: #112849;
  margin-right: 0.5rem;
}
#base-tags-input .close-icon {
  cursor: pointer;
  height: 18px;
  width: 18px;
}
#base-tags-input .naked-input {
  border: none;
  box-shadow: none;
  background-color: transparent;
  font-size: 14px;

}
#base-tags-input .naked-input:focus {
  outline: none;
  box-shadow: none;
  background-color: transparent;
}
</style>
