import Vue from "vue";
import {JWTService} from "@/storage";
import VueJwtDecode from "vue-jwt-decode";


const baseURL = process.env.VUE_APP_KEYCLOAK_URL;

class AuthService {
    static APIFortTokenExchange(payload) {
        const url = baseURL + `/token`;

        return Vue.axios.post(url, payload);
    }

    static APIFortTokenRefresh(payload) {
        const url = baseURL + `/token`;

        return Vue.axios.post(url, payload);
    }

    static GetUserInfo() {
        return VueJwtDecode.decode(JWTService.getToken("access_token"));
    }
    static GetUserRoles() {
        return this.GetUserInfo().realm_access?.roles
    }
}

export default AuthService;
