<template>
  <div id="tickets-container">
    <common-loading v-if="loading" />
    <div v-else>
      <div class="main-title">Tickets</div>
      <div style="padding: 0px 25px 15px 25px" v-if="error && error.length">
        <b-alert :show="error && error.length ? true : false" variant="danger">
          <div v-html="error || '&nbsp;'"></div>
        </b-alert>
      </div>
      <Topbar />
      <filters
        :lookups="lookups"
        :filters="filters"
        @change-filter="changeFilter"
        @reset-filter="resetFilter"
      />
      <common-loading v-if="ticket_loading" />
      <div
        style="padding: 15px 25px 15px 25px"
        v-else-if="ticket_error && ticket_error.length"
      >
        <b-alert
          :show="ticket_error && ticket_error.length ? true : false"
          variant="danger"
        >
          <div v-html="ticket_error || '&nbsp;'"></div>
        </b-alert>
      </div>
      <Tickets v-else :cards="cards" />
    </div>
  </div>
</template>

<script>
import Filters from "./components/filters.vue";
import Topbar from "./components/topbar.vue";
import Tickets from "./components/tickets.vue";
import CardService from "../../services/card.service";

export default {
  name: "Tickets-Page",
  components: { Topbar, Filters, Tickets },
  data() {
    return {
      loading: false,
      ticket_loading: false,
      error: null,
      ticket_error: null,
      cards: null,
      lookups: null,
      filters: {},
    };
  },
  methods: {
    changeFilter(key, value) {
      let temp = { ...this.filters };
      temp[key] = value;
      this.filters = temp;
    },
    resetFilter() {
      this.filters = {};
    },
    query(params = {}) {
      this.ticket_loading = true;
      this.cards = [];
      this.ticket_error = null;

      if (this.filters) {
        if (this.filters["search"]) params["term"] = this.filters["search"];

        if (this.filters["create_date"]) {
          let date_from = new Date();
          let date_to = new Date();

          if (this.filters["create_date"] === "Yesterday") {
            date_from.setDate(new Date().getDate() - 1);
            date_to.setDate(new Date().getDate() - 1);
          }

          if (this.filters["create_date"] === "This week") {
            const curr = new Date();
            const first = curr.getDate() - curr.getDay();
            const last = first + 6;

            date_from = new Date(curr.setDate(first));
            date_to = new Date(curr.setDate(last));
          }

          if (this.filters["create_date"] === "Last week") {
            const curr = new Date();
            const first = curr.getDate() - curr.getDay() - 7;
            const last = first + 6;

            date_from = new Date(curr.setDate(first));
            date_to = new Date(curr.setDate(last));
          }

          if (this.filters["create_date"] === "This month") {
            const m = new Date().getMonth();
            const y = new Date().getFullYear();

            date_from = new Date(y, m, 2);
            date_to = new Date(y, m + 1, 1);
          }

          if (this.filters["create_date"] === "Last month") {
            const m = new Date().getMonth();
            const y = new Date().getFullYear();

            date_from = new Date(y, m - 1, 2);
            date_to = new Date(y, m, 1);
          }

          if (this.filters["create_date"] === "This year") {
            const y = new Date().getFullYear();

            date_from = new Date(y, 0, 2);
            date_to = new Date(y, 12, 1);
          }

          if (this.filters["create_date"] === "Last year") {
            const y = new Date().getFullYear() - 1;

            date_from = new Date(y, 0, 2);
            date_to = new Date(y, 12, 1);
          }

          date_from.setUTCHours(0, 0, 0, 0);
          date_to.setUTCHours(23, 59, 59, 999);

          params["create_date_from"] = new Date(date_from).toISOString();
          params["create_date_to"] = new Date(date_to).toISOString();
        }

        if (
          this.filters["issue_priority"] &&
          this.filters["issue_priority"].length
        )
          params["issue_priority"] = this.filters["issue_priority"].join(",");

        if (this.filters["issue_type"] && this.filters["issue_type"].length)
          params["issue_type"] = this.filters["issue_type"].join(",");

        if (this.filters["issue_status"] && this.filters["issue_status"].length)
          params["issue_status"] = this.filters["issue_status"].join(",");

        if (this.filters["reporter"] && this.filters["reporter"].length)
          params["reporter"] = this.filters["reporter"]
            .map((each) => each.id)
            .join(",");

        if (this.filters["assignee"] && this.filters["assignee"].length)
          params["assignee"] = this.filters["assignee"]
            .map((each) => each.id)
            .join(",");

        if (this.filters["initial_response"])
          params["ttfr"] = this.filters["initial_response"]
            .map((each) => each.value)
            .join(",");
      }

      CardService.Cards(params)
        .then(({ data }) => {
          const { cards, cards_count } = data;
          if (cards_count) {
            this.cards = cards;
          } else {
            this.ticket_error = "No cards found.";
          }
        })
        .catch(({ message }) => {
          this.ticket_error = message
            ? message
            : "There was an error in loading cards.";
        })
        .finally(() => {
          this.ticket_loading = false;
        });
    },
  },
  watch: {
    filters() {
      this.query();
    },
  },
  mounted() {
    this.loading = true;
    this.error = null;

    CardService.Lookups(null)
      .then(({ data }) => {
        this.lookups = data;
      })
      .catch(({ message }) => {
        this.error = message
          ? message
          : "There was an error in loading lookups.";
      })
      .finally(() => {
        this.query();
        this.loading = false;
      });
  },
};
</script>

<style scoped>
#tickets-container {
  height: 100vh;
  overflow: auto;
}
#tickets-container .main-title {
  padding: 25px;
  font-size: 22px;
  color: #a3a4a2;
}
</style>
