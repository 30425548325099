<template>
  <div class="w-100">
    <PageHeader
      title="Inference Dashboard"
      buttonLabel="New Model"
      buttonRouterTo="inference-create-model"
    />

    <div class="margin-under-header">
      <common-loading v-if="loading" />
      <div id="insights-container">
        <div class="tabs grid-auto-fir-minmax">
          <div
            style="padding: 0rem 0.25rem"
            v-for="insight in insights"
            :key="insight.id"
          >
            <div class="tab">
              <div class="left-border">
                <div
                  class="label"
                  style="
                    text-transform: capitalize;
                    font-weight: bold;
                    padding-left: 10px;
                  "
                >
                  {{ insight.name }}
                </div>
                <div class="value" style="padding-left: 10px" v-if="insights">
                  <span class="pe-2" style="font-weight: bold">{{
                    insight.value
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="models" class="d-flex align-items-end">
        <div class="fs-4 fw-bold mx-5 pe-5 mt-4 flex-grow-1">Models</div>
        <div class="d-flex flex-grow-2 gap-2">
          <SelectButton
            v-model="display"
            :options="justifyOptions"
            optionValue="value"
            dataKey="value"
            class="d-flex flex-nowrap"
          >
            <template #option="slotProps">
              <i :class="slotProps.option.icon"></i>
            </template>
          </SelectButton>
          <div class="me-5">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                type="text"
                v-model="searchInput"
                placeholder="Search models..."
              />
            </span>
          </div>
        </div>
      </div>
      <!--  Cards -->
      <div
        class="text-center pt-5"
        id="no-projects"
        v-if="!models.length && !loading"
      >
        <h1>
          No projects
          <b-icon-archive></b-icon-archive>
        </h1>
      </div>
      <b-card-group deck v-else>
        <GridModel
          v-if="display === 'grid'"
          :models="models"
          :loading="loading"
        />
        <ListModel v-else :models="models" :loading="loading" />
      </b-card-group>
    </div>

    <PrimePaginator
      v-if="models.length"
      :first.sync="offset"
      :rows.sync="rows"
      :totalRecords="totalRecords"
      @page="onPage($event)"
    ></PrimePaginator>
  </div>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import GridModel from "@/components/layout/GridModel.vue";
import ListModel from "@/components/layout/ListModel.vue";

export default {
  name: "Triton-inference-dashboard",

  components: { PageHeader, GridModel, ListModel },
  data() {
    return {
      offset: 0,
      rows: 10,
      page: 0,
      totalRecords: 10,
      searchInput: "",
      error: null,
      loading: false,
      dashboardData: {},
      insights: null,
      showDialog: false,
      display: "list",
      justifyOptions: [
        { icon: "pi pi-th-large", value: "grid" },
        { icon: "pi pi-align-justify", value: "list" },
      ],
      models: [],
    };
  },
  computed: {
    apiParams() {
      return {
        page: this.page,
        pageSize: this.rows,
        search: this.searchInput,
      };
    },
  },
  watch: {
    apiParams: {
      handler(newParams) {
        this.fetchData(newParams);
      },
    },
    searchInput: {
      handler() {
        this.page = 0;
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.error = null;
      this.$store
        .dispatch("tritonModels/fetchModels", this.apiParams)
        .then(({ data }) => {
          this.models = data.models;
          this.insights = [
            {
              name: "GPU Memory Used",
              value: data.gpuMemoryUsage,
            },
            {
              name: "GPU Power usage",
              value: data.gpuPowerUsage,
            },
            {
              name: "GPU Power Limit",
              value: data.gpuPowerLimits,
            },
          ];
          this.totalRecords = data.totalRecords < 10 ? 10 : data.totalRecords;
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error in loading lookups.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onPage(event) {
      this.page = event.page;
    },
    getModelNameVersion(modelName) {
      return modelName.split("__");
    },
    getModelNameOnly(modelName) {
      return this.getModelNameVersion(modelName)[0];
    },
    copyModelName(modelName) {
      let formattedModelName = modelName.replace(/\s+/g, "_");
      let url =
        "http://triton.omniops.sa/v2/models/" +
        this.getModelNameOnly(formattedModelName);
      navigator.clipboard.writeText(url);

      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "copied to clipboard",
        life: 3000,
      });
    },
  },
};
</script>

<style>
.status-tag {
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px;
  border-radius: 16px;
  text-transform: capitalize;
}

.deploying {
  background-color: #f0f6ff;
  border: 1px solid #bfdbfe;
  color: #2463eb;
}

.deployed {
  background-color: #e7fbf5;
  border: 1px solid #5ae0bb;
  color: #0fa97e;
}

.conflict {
  background-color: #fff8f0;
  border: 1px solid #fee1bf;
  color: #c27113;
}

.model-name {
  color: #083e47;
  font-size: 16px;
  font-weight: 500;
}

.model-button {
  background-color: #13d39e !important;
  color: #021214 !important;
  border: none !important;
  width: 64px !important;
  height: 38px !important;
}

.copy-button {
  background-color: transparent !important;
  color: #13d39e !important;
  border: none !important;
}

.copy-button:hover {
  background-color: #e7fbf5 !important;
}

.p-inputtext {
  padding: 0.5rem 0.75rem !important;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem !important;
}

#insights-container {
  padding: 0px 30px;
}

#insights-container .tab {
  background-color: #e7fbf5;
  border-radius: 4px;
  border: 1px solid #89e9cf;
  color: #112849;
  text-align: left;
  padding: 15px;
  cursor: pointer;
}

#insights-container .tab .label {
  font-size: 14px;
}

#insights-container .tab .value {
  font-size: 26px;
}

#insights-container .tab .value .red-label {
  font-size: 14px;
  color: #ce1a36;
  margin-left: 0.25rem;
}

#insights-container .tab .value .red-label img {
  margin-right: 0.15rem;
  margin-top: -1px;
}

.left-border {
  border-left: #89e9cf 3px solid;
}

.left-thin-border {
  border-left: #89e9cf 1px solid;
}

.search-input-container {
  border: solid 2px #13d39e;
  border-radius: 8px;
  padding: 2px 10px;
  width: 200px;
}

.clearButton {
  display: inline;
  font-weight: 700;
  font-family: cursive;
  float: right;
  cursor: pointer;
}

.search-input-container > input,
.search-input-container > input:focus-visible {
  background-color: transparent;
  border: 0px solid;
  outline: none;
  width: 150px;
}

.grid-auto-fir-minmax {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1rem;
}

.p-paginator {
  background-color: #f9f9f9 !important;
}
</style>
