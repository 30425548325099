<template>
  <div
    :class="[
      'status-tag d-flex align-items-center gap-2',
      getModelStatus(status),
    ]"
  >
    {{ getModelStatus(status) }}
    <span v-if="getModelStatus(status) === 'optimized'">ready</span>
    <OptimizationButton
      v-if="getModelStatus(status) === 'optimized'"
      :isDetailsPage="false"
      :modelId="modelId"
    />
  </div>
</template>

<script>
import OptimizationButton from "@/components/common/OptimizationButton.vue";

export default {
  name: "ModelStatus",
  components: {
    OptimizationButton,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    modelId: {
      type: String,
      required: true,
    },
  },
  methods: {
    getModelStatus(status) {
      return status.toLowerCase();
    },
  },
};
</script>

<style scoped>
.status-tag {
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px;
  border-radius: 16px;
  text-transform: capitalize;
}

.deploying {
  background-color: #f0f6ff;
  border: 1px solid #bfdbfe;
  color: #2463eb;
}

.deployed {
  background-color: #e7fbf5;
  border: 1px solid #5ae0bb;
  color: #0fa97e;
}

.conflict {
  background-color: #fff8f0;
  border: 1px solid #fee1bf;
  color: #c27113;
}

.pending {
  background-color: #fff8f0;
  border: 1px solid #fee1bf;
  color: #c27113;
}

.deactivated {
  background-color: #fff0f0;
  border: 1px solid #febfbf;
  color: #eb2424;
}

.failed {
  background-color: #fff0f0;
  border: 1px solid #febfbf;
  color: #eb2424;
}

.optimized {
  background-color: #f5f0ff;
  border: 1px solid #d6bffe;
  color: #470fa9;
}

.optimizing {
  background-color: #f0f6ff;
  border: 1px solid #bfdbfe;
  color: #2463eb;
}
</style>
