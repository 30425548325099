var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"tickets-filter-container"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center flex-wrap"},[_c('div',{staticStyle:{"margin-right":"1rem"}},[_c('base-input',{attrs:{"placeholder":"Search tickets…","cus_style":"padding:10px 10px 10px 35px;background-color:#0B3133","remove_default_margin":true,"field":"term","value":_vm.term},on:{"change-value":_vm.setValue},scopedSlots:_vm._u([{key:"attachment",fn:function(){return [_c('img',{staticClass:"search-icon",attrs:{"src":require("../../../assets/images/search-icon.png"),"alt":"search-icon"}})]},proxy:true}])})],1),_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,staticClass:"d-flex align-items-center"},[_c('div',{staticStyle:{"margin-right":"1rem","position":"relative"}},[_c('div',{class:[
              'tab',
              { active: _vm.open_menu_index == index ? true : false },
            ],on:{"click":function($event){$event.stopPropagation();return _vm.toggle_filter_menu(index)}}},[(
                _vm.filters &&
                _vm.filters.issue_priority &&
                _vm.filters.issue_priority.length &&
                index === 1
              )?_c('span',[_vm._v(" "+_vm._s(_vm.filters.issue_priority.length)+" ")]):_vm._e(),(
                _vm.filters &&
                _vm.filters.issue_type &&
                _vm.filters.issue_type.length &&
                index === 2
              )?_c('span',[_vm._v(" "+_vm._s(_vm.filters.issue_type.length)+" ")]):_vm._e(),(
                _vm.filters &&
                _vm.filters.issue_status &&
                _vm.filters.issue_status.length &&
                index === 3
              )?_c('span',[_vm._v(" "+_vm._s(_vm.filters.issue_status.length)+" ")]):_vm._e(),(
                _vm.filters &&
                _vm.filters.initial_response &&
                _vm.filters.initial_response.length &&
                index === 4
              )?_c('span',[_vm._v(" "+_vm._s(_vm.filters.initial_response.length)+" ")]):_vm._e(),(
                _vm.filters &&
                _vm.filters.reporter &&
                _vm.filters.reporter.length &&
                index === 5
              )?_c('span',[_vm._v(" "+_vm._s(_vm.filters.reporter.length)+" ")]):_vm._e(),(
                _vm.filters &&
                _vm.filters.assignee &&
                _vm.filters.assignee.length &&
                index === 6
              )?_c('span',[_vm._v(" "+_vm._s(_vm.filters.assignee.length)+" ")]):_vm._e(),_c('span',[(_vm.filters && _vm.filters.create_date && index === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.filters.create_date)+" ")]):_c('span',[_vm._v(" "+_vm._s(tab)+" ")])])]),(_vm.lookups && index === 0)?_c('Menu1',{attrs:{"open_menu":_vm.open_menu_index == index},on:{"change-filter":_vm.changeFilter,"close-menu":_vm.closeMenu}}):_vm._e(),(_vm.lookups && _vm.lookups.priorities.length && index === 1)?_c('Menu2',{attrs:{"open_menu":_vm.open_menu_index == index,"menu_items":_vm.lookups.priorities},on:{"change-filter":_vm.changeFilter,"close-menu":_vm.closeMenu}}):_vm._e(),(_vm.lookups && _vm.lookups.issue_types.length && index === 2)?_c('Menu3',{attrs:{"open_menu":_vm.open_menu_index == index,"menu_items":_vm.lookups.issue_types},on:{"change-filter":_vm.changeFilter,"close-menu":_vm.closeMenu}}):_vm._e(),(_vm.lookups && _vm.lookups.statuses.length && index === 3)?_c('Menu4',{attrs:{"open_menu":_vm.open_menu_index == index,"menu_items":_vm.lookups.statuses},on:{"change-filter":_vm.changeFilter,"close-menu":_vm.closeMenu}}):_vm._e(),(_vm.lookups && index === 4)?_c('Menu5',{attrs:{"open_menu":_vm.open_menu_index == index},on:{"change-filter":_vm.changeFilter,"close-menu":_vm.closeMenu}}):_vm._e(),(_vm.lookups && _vm.lookups.reporters.length && index === 5)?_c('Menu6',{attrs:{"open_menu":_vm.open_menu_index == index,"menu_items":_vm.lookups.reporters},on:{"change-filter":_vm.changeFilter,"close-menu":_vm.closeMenu}}):_vm._e(),(_vm.lookups && _vm.lookups.assingees.length && index === 6)?_c('Menu7',{attrs:{"open_menu":_vm.open_menu_index == index,"menu_items":_vm.lookups.assingees},on:{"change-filter":_vm.changeFilter,"close-menu":_vm.closeMenu}}):_vm._e()],1)])})],2),_c('div',[_c('base-button',{attrs:{"type":"submit","style_variant":"teritary"},on:{"onClick":_vm.resetFilter}},[_vm._v(" Reset Filters ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }