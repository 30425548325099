<template>
  <div id="omni-footer">
    <span> Copyrights &copy; 2024 Omniops </span>
    <span class="footer-border px-3"> Legal </span>
    <span> Privacy Policy </span>
  </div>
</template>
<script>
export default {
  name: "OmniOpsFooter",
  props: {},
};
</script>
<style>
#omni-footer {
  position: relative;
  bottom: 0;
  right: 0;
  background-color: #f3f4f4;
  border-top: 1px solid #c2d3d5;
  width: 100%;
  height: 80px;
  display: flex;
  gap: 15px;
  justify-content: end;
  align-items: center;
  padding-right: 20px;
}

#omni-footer span {
  font-size: 12px;
  font-weight: 400;
  color: #062d33;
}

.footer-border {
  border-right: 1px solid #0fa97e;
  border-left: 1px solid #0fa97e;
}
</style>
