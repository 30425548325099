<template>
  <div
    id="menu-container"
    class="menu-list"
    style="right: 25px; top: 80px; width: 175px"
    v-if="open_menu"
    v-click-outside="onClickOutside"
  >
    <div
      v-for="(menu_item, index) in menu_items"
      :class="[
        'menu-item',
        { active: selected_item === menu_item ? true : false },
      ]"
      :key="index"
      @click.stop="select_item(menu_item)"
    >
      {{ menu_item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-1",
  props: ["open_menu"],
  emits: ["close-menu"],
  data() {
    return {
      menu_items: ["Download CSV", "Download PDF"],
      selected_item: "",
    };
  },
  methods: {
    select_item(menu_item) {
      console.log(menu_item);
      this.$parent.toggle_download_menu();
    },
    onClickOutside() {
      this.$emit("close-menu");
    },
  },
};
</script>
