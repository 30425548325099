<template>
  <div id="setting-sidebar-container">
    <div
        v-for="(link, index) in links"
        :key="index"
        class="d-flex align-items-center link"
        @mouseenter="mouseenter(index)"
        @mouseleave="mouseleave"
        @click.stop="changeRoute(index)"
    >
      <div>
        <img
            :src="
            hover_index == index || active_index == index
              ? link.active_icon
              : link.icon
          "
            :alt="link.icon"
        />
      </div>
      <div
          class="link-label"
          :class="[
          {
            active:
              hover_index == index || active_index == index ? true : false,
          },
        ]"
      >
        {{ link.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "setting-sidebar",
  data() {
    return {
      links: [
        {
          label: "Profile settings",
          icon: "/images/profile_setting.png",
          active_icon: "/images/profile_setting_active.png",
        },
        {
          label: "Cloud Settings",
          icon: "/images/cloud_setting.png",
          active_icon: "/images/cloud_setting_active.png",
        },
        {
          label: "Billing",
          icon: "/images/billing.png",
          active_icon: "/images/billing_active.png",
        },
        {
          label: "Integrations",
          icon: "/images/integration.png",
          active_icon: "/images/integration_active.png",
        },
        {
          label: "Permissions",
          icon: "/images/permissions.png",
          active_icon: "/images/permissions_active.png",
        },
      ],
      hover_index: null,
      active_index: 0,
    };
  },
  methods: {
    mouseenter(index) {
      this.hover_index = index;
    },
    mouseleave() {
      this.hover_index = null;
    },
    setActive(index) {
      this.active_index = index;
    },
    changeRoute(index) {
      const targetPath = `/dashboard/settings/${index}`;
      if (this.$route.path !== targetPath) {
        this.$router.push(targetPath);
        this.setActive(index);
      } else {
        // Handle the case where the route is the same. You might want to do nothing or provide some feedback.
        console.log("You are already on this page.");
      }
    },
  },
  mounted() {
    if (this.$route.params.index) {
      this.setActive(this.$route.params.index);
    }
  },
};
</script>

<style scoped>
#setting-sidebar-container {
  background-color: white;
  border: 1px solid #ebe9e1;
  height: 100vh;
  border-radius: 4px;
}

.link {
  padding: 0.5rem;
  cursor: pointer;
  background-color: white;
}

.link-label {
  color: #112849;

  margin-left: 0.5rem;
}

.link-label.active {
  color: #124bd8;
}
</style>
