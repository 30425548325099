var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
      'logo-container',
      'd-flex',
      'align-items-center',
      'justify-content-between'
    ]},[_c('img',{staticStyle:{"margin-left":"2px"},attrs:{"src":require("../../assets/images/omniLogo.svg"),"alt":"Omniops-Logo-small"}})]),_c('div',{staticClass:"links-list"},_vm._l((_vm.links),function(link,index){return _c('div',{key:index},[_c('div',{class:[
          'link',
          'd-flex',
          'align-items-center',
          { active: link.is_active ? true : false },
        ],on:{"click":function($event){$event.stopPropagation();return _vm.selectLink(link.route_link)}}},[(link.icon)?_c('div',{staticClass:"link-icon"},[_c('i',{staticClass:"icon-color d-block",style:('mask-image: url('+link.icon+');-webkit-mask-size: cover;mask-size: cover;-webkit-mask-image: url('+ link.icon+');')})]):_vm._e(),_c('div',{staticClass:"link-label",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(link.label))])]),(
          link && link.sub_links && link.sub_links.length
        )?_c('div',_vm._l((link.sub_links),function(sub_link,index){return _c('div',{key:index},[_c('div',{class:[
              'sub-link-label',
              { active: link.is_active && sub_link.is_active ? true : false },
            ],on:{"click":function($event){$event.stopPropagation();return _vm.selectLink(sub_link.route_link)}}},[_vm._v(" "+_vm._s(sub_link.label)+" ")])])}),0):_vm._e()])}),0),_c('div',{staticClass:"notification-link"},[_c('div',{class:[
        'link',
        'd-flex',
        'align-items-center',

      ]},[_vm._m(0),_c('div',[_c('div',{class:[
            'notification-count',
            'd-flex',
            'align-items-center',
            'justify-content-center',
          ],style:(_vm.getNotificationCountStyle)},[_c('div',[_vm._v("2")])])]),_c('div',{staticClass:"link-label",staticStyle:{"overflow":"hidden","margin-left":"10px"}},[_vm._v(" Notifications ")])])]),_c('div',{class:['user-info', { active: _vm.open_menu ? true : false }],style:(`padding:5px 10px;${
      _vm.is_collapsed ? '' : 'width: 250px;'
    }`),on:{"click":function($event){$event.stopPropagation();return _vm.toggle_menu.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"collapse-content",staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.user.given_name)+" "+_vm._s(_vm.user.family_name))]),_c('div',{staticClass:"user-email"},[_vm._v(_vm._s(_vm.user.email))])]),_c('div',[_c('div',{staticClass:"user-avtar d-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.user.given_name.toString().charAt(0).toUpperCase())+" "+_vm._s(_vm.user.family_name.toString().charAt(0).toUpperCase())+" ")])]),(_vm.open_menu)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.toggle_menu),expression:"toggle_menu"}],staticClass:"menu-list",attrs:{"id":"menu-container"}},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"border-bottom":"1px solid #0C5966","padding":"5px 0px 0.5rem"}},[_c('div',[_c('div',{staticClass:"user-avtar d-flex align-items-center justify-content-center",staticStyle:{"margin-left":"10px"},style:('margin-right: 0.5rem;')},[_vm._v(" "+_vm._s(_vm.user.given_name.toString().charAt(0).toUpperCase())+" "+_vm._s(_vm.user.family_name.toString().charAt(0).toUpperCase())+" ")])]),_c('div',[_c('div',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.user.given_name)+" "+_vm._s(_vm.user.family_name))]),_c('div',{staticClass:"user-email",attrs:{"id":"menu-list-user-email"}},[_vm._v(_vm._s(_vm.user.email))])])]),_vm._l((_vm.menu_items),function(menu_item,index){return _c('div',{key:index,class:[
            'menu-item',
            'd-flex',
            'align-items-center',
            { active: _vm.selected_item === menu_item.label ? true : false },
          ],on:{"click":[function($event){$event.stopPropagation();return _vm.select_item(menu_item.label)},function($event){return _vm.toggleParentCollapse()}]}},[_c('img',{staticStyle:{"height":"20px","width":"20px","margin-right":"0.5rem","margin-left":"5px"},attrs:{"src":menu_item.icon,"alt":"icon"}}),_c('div',[_vm._v(" "+_vm._s(menu_item.label)+" ")])])})],2):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/images/bell.png"),"alt":"bell"}})])
}]

export { render, staticRenderFns }